import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// styles
import useStyles from './index.styles';
import theme from '../../../theme';

import { Button } from '@mui/material';

const statusButtonArray = [
    {
        name: 'All',
        key: 'all',
    },
    {
        name: 'Not Started',
        key: 'notStarted',
    },
    {
        name: 'In Progress',
        key: 'inProgress',
    },
    {
        name: 'Completed',
        key: 'completed',
    },
];

const ProgressStatusMenu = (props) => {

    const { handleStatusFilterClick, selectedStatusFilter } = props;
    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });

    return (
        <div className={`${classes.progressStatusMenuContainer} scrollbar-hidden`}>
            {statusButtonArray?.map((item, index) =>
            (
                <Button
                    key={index}
                    variant="contained"
                    fullWidth
                    size="large"
                    className={`${classes.statusMenuBtn} ${selectedStatusFilter === item.key ? classes.selectedBtn : ""}`}
                    onClick={() => { handleStatusFilterClick(item?.key) }}
                    sx={theme => ({
                        minWidth: 'fit-content',
                        borderRadius: '2rem', color: `${theme.palette.fontColor}`, backgroundColor: `${theme.palette.lightVioletToGrey} !important`, whiteSpace: 'nowrap', boxShadow: 'none !important',
                        "&:hover": {
                            backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
                            boxShadow: 'none !important',
                        },
                    })}
                >
                    {item?.name}
                </Button>)
            )
            }
        </div>

    )
}

export default ProgressStatusMenu;

// backgroundColor: theme.palette.lightVoilet1,