import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";
const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const useStyle = makeStyles((theme) => ({

    balloonPopRoot: {
        height: '100%',
        color: "#fff",
        boxSizing: "border-box",
        //background: "linear-gradient(#f7591f, #fcb340)",
        position: "relative",
        transition: "0.7s",
        userSelect: 'none',
        transition: 'background 1s'
    },

    startPage: {
        height: "100%",
        // backgroundImage: "url(/popcornMatch/popcorn_match_overlay.png)"
    },
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgImgBox: {
        textAlign: "center",
        height: "40%"
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "85%"
    },
    btnBox: {
        textAlign: "center",
        height: "60%"
    },
    btnContainer: {
        width: "100%",
    },
    howToPlay: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "1.8em",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        background: "none",
        marginBottom: "30px",
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5em',
        },
    },
    startBtn: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "2em",
        background: "#ffe66e",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: '0 2px 7px -1px #000',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3em',
        },
    },

    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(3deg)" },
        "10%": { transform: "rotate(-3deg)" },
        "20%": { transform: "rotate(3deg)" },
        "30%": { transform: "rotate(-3deg)" },
        "40%": { transform: "rotate(3deg)" },
        "50%": { transform: "rotate(-3deg)" },
        "60%": { transform: "rotate(3deg)" },
        "70%": { transform: "rotate(-3deg)" },
        "80%": { transform: "rotate(3deg)" },
        "90%": { transform: "rotate(-3deg)" },
        "95%": { transform: "rotate(3deg)" },
        "100%": { transform: "rotate(0deg)" },
    },
    "@keyframes slideFromBottom": {
        "0%": { transform: "translateY(250%)" },
        "100%": { transform: "translateY(0)" },
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },


    // questionBox container
    qstnContainer: {
        height: "100%",
        // padding: "0.5rem 0.5rem 0 0.5rem",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative',
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        backgroundColor: "#000000",
        // borderRadius: "10px",
        height: "100%",
        fontSize: "1.2em",
        // boxShadow: "0px 10px 20px #0000004D",
        display: 'grid',
        gridTemplateRows: '35% 65%'
        // padding: '0 0.8rem'
    },
    qstnBoxBoxShadow: {
        animation: '$wrongAnsAnimation 1.5s ease 0s 1 normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "100%": { boxShadow: "0px 0px 20px -1px #F63B34E8" },
        // "100%": { boxShadow: "0px 10px 20px #0000004D"}
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        padding: isHDScreen ? '0' : "0"
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        //justifyContent: "right",
        justifySelf: "end"
    },
    icon: {
        width: '1em',
        height: '1em',
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: isHDScreen ? '0.6em' : '1em',
    },
    timerIcon: {
        width: "13px",
        height: "12px",
        margin: "0 6px"
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: isHDScreen ? '0.6em' : '1em',
        opacity: '1',
        fontSize: '1em',
        padding: '2%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: isHDScreen ? '0.75em' : '1em',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },

    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        gridTemplateRows: "1.25% 25% 73.75%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '0',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running',
        backgroundImage: "url(/images/gba/space_rocks_bg.png)",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    questionBox: {
        height: "60%",
        padding: "0.7rem"
    },
    qBox: {
        backgroundColor: "#000",
        height: "100%",
        padding: "1rem",
        borderRadius: "5px",
    },
    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s'
    },
    answerContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
    },
    answer: {
        flex: "1 1 33%",
        display: "flex",
        //overflow: 'hidden',
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '.4s'
    },
    anwserImgBox: {
        position: 'relative',
        height: '100px',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.4s'
    },
    // answerText: {
    //     position: 'absolute',
    //     top: '100%',
    //     left: '50%',
    //     transform: 'translateX(-50%)',
    //     fontSize: '0.7rem',
    //     textAlign: 'center',
    //     width: '14ch',
    //     // whiteSpace: 'normal',
    //     // wordBreak: 'break-word',
    //     fontFamily: 'Montserrat',
    //     [theme.breakpoints.down("sm")]: {
    //         // mobile
    //         fontSize: '1rem',
    //     },
    // },
    answerImg: {
        height: '60px',
    },

    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '90px',
        width: '90px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    qstnText: {
        position: 'absolute',
        top: '108%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.7em',
        textAlign: 'center',
        width: '14ch',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1em',
        },
    },
    qstnImg: {
        height: '60px',
    },
    optionContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
        justifyContent: "center",
        transition: "transform 1s",
    },
    optionCard: {
        flex: "0 1 33%",
        display: "flex",
        justifyContent: "center",
    },
    progressBarContainer: {
        position: "relative",
        height: "100%",
        zIndex: "2",
        width: '100%',
        borderRadius: '0px',
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
        // marginTop: '4px',
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    boxShadowBreathingAnimation: {
        transition: '2s',
        animation: '$boxShadowBreathingAnimation 1s ease 0s infinite normal forwards running '
    },
    dragItemAction: {
        fontFamily: 'MotionControl',
        fontSize: '1.6em',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.8em',
        },
    },
    '@keyframes boxShadowBreathingAnimation': {
        '0%': { boxShadow: '0 0 15px -2px red' },
        '10%': { boxShadow: '0 0 16px -2px red' },
        '20%': { boxShadow: '0 0 17px -1px red' },
        '30%': { boxShadow: '0 0 18px -1px red' },
        '40%': { boxShadow: '0 0 19px 0px red' },
        '50%': { boxShadow: '0 0 20px 0px red' },
        '60%': { boxShadow: '0 0 19px -1px red' },
        '70%': { boxShadow: '0 0 18px -1px red' },
        '80%': { boxShadow: '0 0 17px -2px red' },
        '90%': { boxShadow: '0 0 16px -2px red' },
        '100%': { boxShadow: '0 0 15px -2px red' },
    },


    gameplayContainer: {
        width: '100%',
        display: 'grid',
        gridAutoRows: '87% 13%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollBox: {
        height: '100%',
        width: '100%',
        position: 'relative'
        // overflow: 'auto'
    },
    gameplayBox: {
        // overflow: 'auto',
        transform: 'translateY(-100px)',
        // transition: 'transform 330ms ease-in-out',
        // transition: '.2s',
        // position: 'absolute',
        width: '100%',
        left: '0',
        right: '0',
        top: '100%',
        height: '100%',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },
        '& div:nth-child(odd)': {
            justifyContent: 'flex-end',
        },
        '& div:nth-child(even)': {
            justifyContent: 'left',
        }
    },
    answerTxt: {
        position: 'absolute',
        cursor: 'pointer',
        zIndex: '3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        width: '90%',
        padding: '0.6rem',
        height: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center !important',
        textAlign: 'center',
        fontSize: isHDScreen ? '0.6em' : '0.9em',
        fontWeight: isHDScreen ? '700' : 'bold',
        [theme.breakpoints.up("sm")]: {
            // mobile
            fontSize: isHDScreen ? '0.6em' : '0.9em',
        }
    },
    rockImageBox: {

    },
    rockBox: {
        position: 'relative',
        width: '50%',
        margin: '0 1rem'
    },
    rockImage: {
        position: 'relative',
        zIndex: '2',
        width: '100%'
    },
    correctLottie: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
    },
    wrongLottie: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -100%)',
        height: '30%',
        aspectRatio: '1 / 1',
        zIndex: '2'
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#800E31, #F63B34)',
        opacity: '0',
        // transition: '.5s ease'
    },
    speedBarBox: {
        height: '100%',
        background: '#370674',
        zIndex: '2',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        boxShadow: '0 0 15px 2px #000'
    },
    speedBarRectBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        '& > *': {
            width: "5px",
            height: '40%',
            margin: isHDScreen ? '0 .20rem' : '0 .25rem',
            background: '#fff'
        }
    },
    speedDecrement: {
        fontSize: '2em',
        marginRight: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        '& > img': {
            width: isHDScreen ? '12px' : '18%',
            width: '20%',
        }
    },
    speedIncrement: {
        fontSize: '2em',
        marginLeft: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        '& > img': {
            width: isHDScreen ? '12px' : '18%',
        }
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { opacity: "0" },
        "20%": { opacity: "1" },
        "50%": { opacity: "1" },
        "75%": { opacity: "1" },
        "100%": { opacity: '0' }
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    muteSwitch: {
        right: '3%',
        bottom: '0px',
        transform: 'translateY(50%)',
        cursor: 'pointer',
        zIndex: '2',
        position: 'absolute',
    },
    playButton: {
        width: '40px',
        height: '40px',
        background: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        border: 'none',
        cursor: 'pointer',
    },
    playIcon: {
        width: '0',
        height: '0',
        borderLeft: '15px solid #5c4a45',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        position: 'relative',
        zIndex: '2',
    }
}));

export default useStyle;
