import React, { useEffect, useMemo, useState } from 'react'

import useStyles from './index.styles'
import { useTranslation } from 'react-i18next'
import isEmpty from '../../../utils/isEmpty';
import ImageContent from '../content/image-content';
import VideoContent from '../content/video-content';

const Bulletinbanner = ({ handleBulletinBannerClick, cardData }) => {
    const { i18n } = useTranslation();
    const styles = useStyles(i18n);

    const [cardContent, setCardContent] = useState({})

    const contentMapping = () => {
        const result = { media: null, title: null, body: null };
        cardData.contents.forEach(item => {
            if (item.name === findMediaTypeFromCardType(cardData.cardType) || item.name === "YOUTUBE") {
                result.media = item;
                return
            }
            switch (item.name) {
                case 'TITLE':
                    result.title = item;
                    break;
                case 'BODY':
                    result.body = item;
                    break;
                default:
                    break;
            }
        });

        setCardContent(result)
        // return result;
    };

    useEffect(() => {
        if (isEmpty(cardData)) return
        contentMapping();
    }, [cardData])

    function findMediaTypeFromCardType(cardType) {
        const mediaTypeMapping = {
            VTB: "VIDEO",
            ITB: "IMAGE",
            ATB: "AUDIO"
        };

        return mediaTypeMapping[cardType] || "";
    }

    return (
        <>
            <div className={`${styles.cardContainer}`} onClick={handleBulletinBannerClick}>
                <div className={`${styles.cardGradient} ${styles.asessmentModel}`}
                >
                    {
                        (cardData?.cardType === 'ITB' && !isEmpty(cardContent.media)) && (
                            <ImageContent imageUrl={cardContent?.media?.content} />
                        )
                    }
                    {
                        (cardData?.cardType === 'VTB' && !isEmpty(cardContent.media)) && (
                            <VideoContent
                                videoUrl={cardContent?.media?.content}
                                autoPlay={true}
                                muted={true}
                                controls={false}
                                type={cardContent?.media?.name}
                                loop={true}
                                height="100%"
                                showYtInfo={false}
                                styling={{ objectFit: "cover" }}
                            />
                        )
                    }
                    {
                        (cardData?.cardType === 'ATB' && !isEmpty(cardContent.media)) && (
                            <img src="https://www.clipartbest.com/cliparts/9ip/XLz/9ipXLzAiE.gif" width="100%" height="100%" alt="audio" />
                        )
                    }
                </div>
                <div className={`${styles.centerFlex} ${styles.nameContainer}`}>
                    <div className={`${styles.centerFlex} ${styles.headingConatiner}`}>
                        {
                            !isEmpty(cardContent?.title) && (
                                <h2 className={styles.microSkillName}>
                                    <div dangerouslySetInnerHTML={{ __html: cardContent.title.content }}></div>
                                </h2>
                            )
                        }
                        {
                            !isEmpty(cardContent?.body) && (
                                <h4 className={styles.aboutText}>
                                    <div dangerouslySetInnerHTML={{ __html: cardContent.body.content }}></div>
                                </h4>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bulletinbanner;