// styles 
import useStyles from "./index.styles";

// components
import ImageContent from "./image-content/index.js";
import VideoContent from "./video-content/index.js";
import { useSelector } from "react-redux";
// import AudioContent from "./audio-content/index.js";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router";

const Content = (props) => {

    const { selectedBulletinData, handlePrevClick, handleNextClick, handleViewFullScreen, viewFullScreen } = props;

    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);
    const { isMobile, isTablet } = useSelector(state => state?.common);

    const classes = useStyles();
    const { bulletinId } = useParams();

    return (
        <>
            {/* For Mobile & Tablet View  */}
            {(isMobile || isTablet) && <div className={classes.contentWrapper}>

                {(selectedBulletinData?.cardType === 'I' || selectedBulletinData?.cardType === 'V' || selectedBulletinData?.cardType === 'Y') ?
                    <>
                        <div className={classes.mediaWrapper} style={{
                            alignItems: selectedBulletinData?.cardType === 'I' ? 'center' : ''
                        }}>

                            {selectedBulletinData?.cardType === 'I' && <ImageContent imageUrl={selectedBulletinData?.url} />}

                            {(selectedBulletinData?.cardType === 'V' || selectedBulletinData?.cardType === 'Y') && <VideoContent
                                videoUrl={selectedBulletinData?.url}
                                type={selectedBulletinData?.cardType === 'Y' ? "YOUTUBE" : "VIDEO"}
                                height={selectedBulletinData?.cardType === 'Y' ? (isMobile ? "20rem" : (isTablet ? "35rem" : "100%")) : "100%"}
                            />
                            }

                            {/* <AudioContent /> */}
                        </div>

                        <div className={classes.contentTextWrapper}>
                            <h1 className={classes.title}
                                dangerouslySetInnerHTML={{ __html: selectedBulletinData?.title }}
                            ></h1>

                            <p className={classes.description}
                                dangerouslySetInnerHTML={{ __html: selectedBulletinData?.description }}
                            ></p>
                        </div>
                    </> : <div className={classes.centerFlex}></div>
                }

            </div>}


            {/* For Desktop View */}
            {(!isMobile && !isTablet) && (
                <div className={!viewFullScreen ? classes.contentWrapper : classes.contentWrapperFullScreen}>

                    {(selectedBulletinData?.cardType === 'I' || selectedBulletinData?.cardType === 'V' || selectedBulletinData?.cardType === 'Y') ?
                        <div className={classes.mediaContentWrapper}>

                            <h1 className={`${classes.title}`}
                                dangerouslySetInnerHTML={{ __html: selectedBulletinData?.title }}
                            ></h1>
                            <div className={classes.mediaBodyWrapper}>
                                <div className={classes.mediaWrapper}>


                                    <div className={classes.mediaContent}>

                                        {selectedBulletinData?.cardType === 'I' && <ImageContent imageUrl={selectedBulletinData?.url} />}

                                        {(selectedBulletinData?.cardType === 'V' || selectedBulletinData?.cardType === 'Y') && <VideoContent
                                            videoUrl={selectedBulletinData?.url}
                                            type={selectedBulletinData?.cardType === 'Y' ? "YOUTUBE" : "VIDEO"}
                                            height={selectedBulletinData?.cardType === 'Y' ? (isMobile ? "20rem" : (isTablet ? "35rem" : "100%")) : "100%"}
                                        />
                                        }

                                        {/* <AudioContent /> */}
                                    </div>
                                </div>

                                <div className={classes.contentTextWrapper}>
                                    <p className={classes.description}
                                        dangerouslySetInnerHTML={{ __html: selectedBulletinData?.description }}
                                    ></p>
                                </div>
                            </div>
                        </div> : <div className={classes.centerFlex}></div>
                    }

                    <div className={classes.bottomNavigationBar}>

                        <div className={classes.navigationContent}>

                            <div className={`${classes.bottomNavigationBox} `} id="bottomNavigationBox">

                                <div onClick={handlePrevClick} className={classes.cursorPointer}>
                                    <Button className={`${classes.navBtnBox} ${classes.centerFlex}`}>
                                        <KeyboardArrowLeftIcon style={{ color: '#f4511e', borderRadius: '50%' }} />
                                    </Button>
                                </div>

                                <div onClick={handleNextClick} className={classes.cursorPointer}>
                                    <Button className={`${classes.navBtnBox} ${classes.centerFlex}`}>
                                        <KeyboardArrowRightIcon style={{ color: '#f4511e', borderRadius: '50%' }} />
                                    </Button>
                                </div>
                            </div>

                            <div className={classes.fullScrrenIcon} onClick={handleViewFullScreen}>
                                <Button>
                                    {!viewFullScreen ? (
                                        <img
                                            src={`/images/icons/maximize${isDarkMode ? "_white" : "_black"}.png`}
                                            alt="icon"
                                            height={22}
                                        />
                                    ) : (
                                        <img
                                            src="/images/icons/minimize_white.png"
                                            alt="icon"
                                            height={22}
                                            style={{ filter: isDarkMode ? 'none' : 'invert(1)' }}
                                        />
                                    )}
                                </Button>
                            </div>

                            {/* <div className={classes.monthSelector}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Month</InputLabel>
                                    <Select
                                        style={{ border: '1px solid #f4511e' }}
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedMonth}
                                        label="Month"
                                        onChange={handleMonthChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {months.map((month, i) => (
                                            <MenuItem key={i} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div> */}
                        </div>

                    </div>

                </div>
            )}
        </>
    );
};

export default Content;




