import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

// styles 
import useStyles from "./index.styles";
import CustomReactPlayer from "../../../custom-react-player";
import isEmpty from "../../../../utils/isEmpty";


const VideoPlayerHoc = ({
    videoUrl,
    class_name = "",
    dpip = false,
    ctrlList = "nodownload",
    styling = {},
    autoPlay = false,
    muted = false,
    controls = true,
    loop = false,
}) => {
    const videoRef = useRef()
    useEffect(() => {
        // This is to handle glitch between consecutive VTB cards that is restricting to update video of current card
        if(!isEmpty(videoRef.current)) {
            videoRef.current.src = videoUrl
        }
    }, [videoUrl, videoRef])
    return (
        <video
            className={class_name}
            preload="metadata"
            width="100%"
            controls={controls}
            playsInline
            disablePictureInPicture={dpip}
            controlsList={ctrlList}
            style={styling}
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            ref={videoRef}
        >
            <source src={videoUrl} type="video/mp4" />
            <source src={videoUrl} type="video/ogg" />
            Your browser does not support the video tag.
        </video>
    )
}


const VideoContent = (props) => {

    const {
        videoUrl,
        type = "VIDEO",
        autoPlay = false,
        muted = false,
        controls = true,
        loop = false,
        height = "",
        showYtInfo = true,
        styling = {}
    } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });

    return (
        <div className={classes.videoContentWrapper} style={{ height: height }} >
            {
                type === 'YOUTUBE' ? (
                    <CustomReactPlayer
                        mediaUrl={videoUrl}
                        controls={controls}
                        isPlaying={true}
                        showYtInfo={showYtInfo}
                        muted={muted}
                        loop={loop}
                        styling={styling}
                    />
                ) : (
                    <VideoPlayerHoc
                        videoUrl={videoUrl}
                        styling={{ ...styling, maxHeight: "100%" }}
                        class_name={classes.resetRotation}
                        dpip={true}
                        ctrlList={"nofullscreen nodownload noremoteplayback noplaybackrate"}
                        autoPlay={autoPlay}
                        muted={muted}
                        controls={controls}
                        loop={loop}
                    />
                )
            }
        </div>
    );
};

export default VideoContent;