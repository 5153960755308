import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// mui
import CompletionProgress from '../completion-progress';
import VisibilityIcon from '@mui/icons-material/Visibility';

// utils
import isEmpty from '../../utils/isEmpty';

// styles
import useStyles from "./index.styles";
import { useTranslation } from 'react-i18next';

const MicroskillCard = (props) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const { cardData, handleMicroskillCardClick, isRecentlyLaunched } = props;

    const {
        Microskill: {
            // width = '220px',
            name = !isEmpty(name) ? name : 'Name',
            brandImage,
            OrgCoverImage,
            likes = 0,
            views = 0,
        },
        message,
        status,
        totalChallenges,
        totalActivechallenges,
        completedChallenges,
        disabledChallenges,
        microskillId,
        completion
    } = cardData;


    const classes = useStyles();
    const { t } = useTranslation();

    // let finalTotalChallenges;

    // if (totalChallenges > disabledChallenges) {
    //     finalTotalChallenges = parseInt(totalChallenges) - parseInt(disabledChallenges);
    // } else if (totalChallenges === disabledChallenges) {
    //     finalTotalChallenges = 0;
    // } else {
    //     finalTotalChallenges = totalChallenges;
    // }

    const completionPerTemp = Math.round((completedChallenges * 100) / totalActivechallenges);
    const completionPer = totalActivechallenges === 0 ? 0 : completionPerTemp;
    const marqueeId = isRecentlyLaunched ? `marquee-${microskillId}` : `marquee-recently-${microskillId}`

    useEffect(() => {
        // let marqueeEl = document.getElementById(marqueeId)
        // if (isEmpty(marqueeEl)) return

        // var overflowX = marqueeEl.offsetWidth < marqueeEl.scrollWidth,
        //     overflowY = marqueeEl.offsetHeight < marqueeEl.scrollHeight;
        // if (overflowX) marqueeEl.classList.add(classes.marqueeAnimation);
        // console.log("overflowX:", [overflowX, overflowY])
    }, [])

    const cardContainerStyles = {
        backgroundImage: `url(${isEmpty(OrgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : OrgCoverImage})`,
        display: 'inline-block',
        filter: status?.toUpperCase().includes('ACTIVE') ? null : 'blur(4px)', // if microskill is not active then blur the card (schedule launch)
    }

    const getBannerColor = (type) => {
        if (type.toLowerCase().includes('locked')) {
            return {
                backgroundColorMain: "#ffdd00",
                backgroundColorFold: "#ffb300",
                icon: '/images/icons/locked-icon.svg'
            }
        } else if (type.toLowerCase().includes('available') || type.toLowerCase().includes('unlocked')) {
            return {
                backgroundColorMain: "#00cc2e",
                backgroundColorFold: "#3d9341",
                icon: type.toLowerCase().includes('available') ? null : '/images/icons/locked-icon.svg'
            }
        } else if (type.toLowerCase().includes('new launch')) {
            return {
                backgroundColorMain: "#1e88e5",
                backgroundColorFold: "#1772c1",
                icon: '/images/icons/startup.svg'
            }
        } else if (type.toLowerCase().includes('expire')) {
            return {
                backgroundColorMain: "#f4511f",
                backgroundColorFold: "#c73f16",
                icon: type.toLowerCase() === 'expired' ? '/images/icons/expire-clock.svg' : null
            }
        } else {
            return {
                backgroundColorMain: "#00cc2e",
                backgroundColorFold: "#3d9341",
            }
        }
    }

    return (
        <div className={classes.microskillCardRoot} style={{}}>
            {
                !isEmpty(message) && (
                    completion && message.toUpperCase().includes('NEW LAUNCH') ? ( // if new launch and no completion mark then show banner
                        <></>
                    ) : (
                        <div className={classes.bannerBox}>
                            <div className={classes.bannerPart1} style={{ backgroundColor: getBannerColor(message)?.backgroundColorFold }} />
                            <div className={`${classes.centerFlex} ${classes.bannerPart2}`} style={{ backgroundColor: getBannerColor(message)?.backgroundColorMain }}>
                                {
                                    !isEmpty(getBannerColor(message)?.icon) && (
                                        <img src={getBannerColor(message)?.icon} alt='' width={12} />
                                    )
                                }
                                {/* <span className={classes.launchTypeText}>{message}</span> */}
                                <span className={classes.launchTypeText}>{t(message)}</span>
                            </div>
                        </div>
                    )
                )
            }

            <div className={classes.cardContainer} style={cardContainerStyles} onClick={handleMicroskillCardClick}>
                <div className={classes.cardGradient}>
                    <div className={classes.cardContent}>
                        <div className={`${classes.centerFlex} ${classes.completion}`}>
                            <CompletionProgress completionPercentage={`${completionPer}%`} fontSize={isMobile ? '0.8rem' : '0.75rem'} />
                        </div>
                        <div className={`${classes.centerFlex} ${classes.nameContainer}`}>
                            <h2 className={classes.microSkillName} title={name} >
                                {name}
                            </h2>
                            {/* </marquee> */}

                            {/* <h2 className={classes.microSkillName} title={name}>{name}</h2> */}
                            {/* <h2 className={classes.microSkillName}>{microSkillName}</h2> */}
                            <div className={classes.imgContainer}>
                                <img src={isEmpty(brandImage) ? '/images/sample-image/master_o_brand_icon.png' : brandImage} alt="company logo" />
                            </div>
                        </div>
                        <div className={`${classes.centerFlex} ${classes.icons}`}>
                            <div className={`${classes.centerFlex} ${classes.watchIcon}`}>
                                <VisibilityIcon sx={{ fontSize: '0.6rem' }} />
                                <span className={classes.iconText}>{views}</span>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.heartIcon}`}>
                                {/* <FavoriteIcon sx={{ fontSize: '0.6rem' }} />
                                <span className={classes.iconText}>{likes}</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MicroskillCard