import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => {
    // console.log({theme});
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        progressStatusMenuContainer: {
            width: '100%',
            display: 'flex',
            gap: '0.5rem',
            maxWidth: 'max-content',
            overflowX: 'auto'
        },
        statusMenuBtn: {
            fontWeight: 'bold !important',
            alignSelf: 'center',
            fontSize: '0.8rem !important',
            padding: '0.5rem 1rem !important',
            width: 'fit-content !important',
            border: `2px solid transparent !important`,

        },
        selectedBtn: {
            borderColor: `${theme.palette.orange} !important`,
            color: `${theme.palette.orange} !important`,

        }

    })
})

export default useStyles;