import { makeStyles } from "@mui/styles";
import constants from "../../../../constants";
import palette from "../../../../theme/palette";

const useStyles = makeStyles((theme) => {
    // console.log({theme});
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileDrawer: {
            zIndex: '9999 !important',
            '& .MuiDrawer-paper': {
                width: '100% !important',
                backgroundColor: `${theme.palette.voilet} !important`,
                backgroundImage: 'none !important'
            },
        },
        closeIcon: {
            right: '1.5rem',
            top: '50%',
        },
        searchPageContainer: {
            padding: '0 0.8rem 0.8rem',
            // backgroundColor: theme.palette.lightVoilet1,
            // background: theme.palette.mobileMainContainerBg,

            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            color: theme.palette.fontColor
        },
        searchPageHeader: {
            fontSize: '1.2rem',
            fontWeight: '600',
            height: constants?.HEADER_HEIGHT_MOBILE,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
    })
})

export default useStyles