import { createContext, useState } from "react";

// components

// utils

const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {

    const [mobileOpen, setMobileOpen] = useState(false)
    const [LanguageChanging, setLanguageChanging] = useState(false)
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorLangElUser, setAnchorLangElUser] = useState(null);

    const values = {
        mobileOpen, setMobileOpen,
        LanguageChanging, setLanguageChanging,
        anchorElUser, setAnchorElUser,
        anchorLangElUser, setAnchorLangElUser
    }

    return (
        <CommonContext.Provider value={values}>
            {children}
        </CommonContext.Provider>
    )
}

export default CommonContext