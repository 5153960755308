import { makeStyles } from "@mui/styles";
import constants from "../../../../constants";
import palette from "../../../../theme/palette";

const useStyles = makeStyles((theme) => {
    // console.log({theme});
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileDrawer: {
            zIndex: '9999 !important',
            '& .MuiDrawer-paper': {
                width: '100% !important',
                backgroundColor: `${theme.palette.voilet} !important`,
                backgroundImage: 'none !important'
            },
        },
        closeIcon: {
            right: '1.5rem',
            top: '50%',
        },
        searchPageContainer: {
            height: '100%',
            // backgroundColor: theme.palette.lightVoilet1,
            // background: theme.palette.mobileMainContainerBg,
            background: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            color: theme.palette.fontColor,
            [theme.breakpoints.down('md')]: {
                height: '100% !important',
            }
        },
        searchesHeaderText: {
            fontSize: '1rem',
            fontWeight: '700',
            [theme.breakpoints.up('md')]: {
                padding: '1rem',
            }
        },
        listWrapper: {
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            // padding: '1rem',
            fontSize: '1.2rem',
            fontWeight: '400',
            flexDirection: 'column',

            [theme.breakpoints.up('md')]: {
                gap: '0.8rem',
            },
        },
        navigationText: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: '500',
        },
        searchesContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1rem',
        },
        navigationBtn: {
            width: '35px',
            transform: 'rotate(180deg)',
            background: '#f4511e29',
            aspectRatio: '1/1',
            borderRadius: '50%',
        },
        searchedText: {
            fontSize: '1rem',
            fontWeight: '600',
            width: '100%',
            textAlign: 'left',
            color: theme.palette.fontColor,
        },
        noDataFound: {
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        statusWiseSearchesContainer: {
            padding: '0.5rem 0',
            [theme.breakpoints.up('md')]: {
                padding: '0',
            },

        }



    })
})

export default useStyles