import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie-player';

// mui
import CircularProgress from '@mui/material/CircularProgress';

// components
import StartScreen from '../common-screens/start-screen';
// import HowToPlay from '../how-to-play/crazy_cars-how-to-play';
import GbaGameOverScreen from '../common-screens/game-over-screen';
import GbaWinScreen from '../common-screens/win-screen';

// redux 
import { startChallengeAttempt } from '../../../redux/microskill/microskill.api';
import { submitChallengeAttemptData, submitEndChallengeAttempt } from '../../../redux/gba/gba.api';
import { handleGbaGameState } from '../../../redux/gba/gba-slice';

// utils
import isEmpty from '../../../utils/isEmpty';
import commonUtil from '../../../utils/commonUtil';

// constants    
import constants from '../../../constants';

// styles
import useStyle from './index.styles';

// animation
import monsterAnimation from "../../../lottie-assets/monster-lottie-animation.json";
import monsterMissedAnimation from "../../../lottie-assets/monster-missed-lottie-animation.json";
import { useTranslation } from 'react-i18next';

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const SIDES = {
    'RIGHT': 'RIGHT',
    'LEFT': 'LEFT'
}

const TastyTruth = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const gbaGameData = gameData.questions;
    const { microskillId, challengeId } = useParams();
    const contextData = gameData.contexts;
    const dispatch = useDispatch();

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let totalQuestionLength = gameData.questions.length;
    let questionsInGba = totalQuestionLength;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    let mxmScore = 120;
    let marksPerQuestion = mxmScore / totalQuestionLength;

    let roadSpeed1 = 0.1;
    let roadSpeed2 = 0.13;
    let roadSpeed3 = 0.16;
    // let roadSpeed1 = 0.05 * 2;
    // let roadSpeed2 = (roadSpeed1 * 1.5) + 0.1;
    // let roadSpeed3 = (roadSpeed1 * 1.5) + 0.15;

    let carFlowSpeed1 = 15;
    let carFlowSpeed2 = carFlowSpeed1 / 2;
    let carFlowSpeed3 = carFlowSpeed1 / 4;
    let lottieMovedPercentagePerSec = .25; //25% -> provided by dinesh (1800px total lenth of lottie asset and moves 450px in 1sec)
    const speedBarLength = 16;

    const [isGamePage, setIsGamePage] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [life, setLife] = useState(maxLife);
    const [speedLevel, setSpeedLevel] = useState(roadSpeed2);
    const [roadSpeedLevel, setRoadSpeedLevel] = useState(roadSpeed2);
    const [attemptData, setAttemptData] = useState({})
    const [incorrectData, setIncorrectData] = useState();
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [fSize, setFSize] = useState(1);
    const CrazyCarsRef = useRef();
    const totalScoreRef = useRef(0);
    const incorrectGradientOverlayRef = useRef();
    const progressBarRef = useRef();
    const lifeTextRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const questionRef = useRef();
    const gamePlayContainerRef = useRef();
    const leftSideImgRef = useRef();
    const rightSideImgRef = useRef();
    const roadStripsRef = useRef();
    // const missedRef = useRef();
    const carImgRef = useRef();
    const leftSideTFRef = useRef();
    const rightSideTFRef = useRef();
    const speedBoxRef = useRef();
    const speedBarRectBoxRef = useRef();
    const leftKeyboardMovementRef = useRef();
    const rightKeyboardMovementRef = useRef();
    const leftPartRoadRef = useRef();
    const rightPartRoadRef = useRef();
    const questionContainerRef = useRef();
    const collisionRef = useRef(false);
    const questionVisible = useRef(false);
    const gameEndTypeRef = useRef('');

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);

    const tfScrollIntervalRef = useRef();
    const checkOverlapIntervalRef = useRef();

    const activeIndexRef = useRef(0);
    const speedLevelRef = useRef(2);
    const finalChallengeDataRef = useRef(null);
    const attemptRef = useRef();
    const contextRef = useRef();
    const currentTimeRef = useRef();
    const translatedTFRef = useRef();
    const rightRoadImgRef = useRef();
    const leftRoadImgRef = useRef();
    const sideImgHeight = useRef();
    const roadStripImgRef = useRef();
    const assestsIntervalRef = useRef();
    const assestsScrollValueRef = useRef(0);
    const questionNoRef = useRef();
    const roadRef = useRef();
    const isGamePausedRef = useRef(isPauseOverlay);
    const scrollBoxRef = useRef();
    const pausedTimeRef = useRef(0);
    const isMonsterInRangeOfTrueFalseRef = useRef(false)
    const isMonsterOutOfRangeOfTFRef = useRef(false);
    const trackAnimationRef = useRef();
    const yellowDotsContainerRef = useRef()
    const roadSpeedRef = useRef(roadSpeed2)
    const selectedOptionRef = useRef('')
    const moveMonsterIntervalRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('')
    const monsterLottieRef = useRef(null);
    const dotEatingShieldRef = useRef(null);
    const roadToRotate = useRef(0);
    const horzYellowDotsContainerRef = useRef();
    const inRangeRef = useRef(false);
    const rightWallRef = useRef();
    const leftWallRef = useRef();
    const questionAttempt = useRef(0);
    const resultImgRef = useRef();
    const horiZontalRoadRef = useRef();
    const monsterMissedLottieRef = useRef();
    const wrapBoxShadowRef = useRef();
    const audioRef = useRef();

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const classes = useStyle();

    const defaultBackground = `/images/gba/tasty-truth/bg.png`;
    const speedBarGradient = '#00FFEB';
    // const speedBarGradient = '#F1F112';
    const speedBarGradientFade = '#FFFFFF';

    const YELLOW_DOTS_COUNT = Math.floor(parseInt(window.innerHeight) / (2 * 10));
    const trueBlock = `/images/gba/tasty-truth/tasty-truth_true.png`;
    const falseBlock = `/images/gba/tasty-truth/tasty-truth_false.png`;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const { t } = useTranslation();
    useEffect(() => {
        if (isGameComplete) {
            collisionRef.current = true;
            clearAllIntervals();
        }
    }, [isGameComplete]);


    useEffect(() => {
        if (isPauseOverlay) {
            clearInterval(tfScrollIntervalRef.current);
            clearInterval(assestsIntervalRef.current);
        }
    }, [questionVisible.current, questionVisible]);

    const clearAllIntervals = () => {
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(tfScrollIntervalRef.current);
        clearInterval(assestsIntervalRef.current);
        clearInterval(rightKeyboardMovementRef.current);
        clearInterval(leftKeyboardMovementRef.current);
        clearInterval(moveMonsterIntervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
    }

    useEffect(() => {
        return () => {
            if (!isEmpty(speedBoxRef.current)) {
                speedBoxRef.current.removeEventListener('wheel', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('click', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('touchstart', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('touchend', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('touchmove', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('mousedown', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('mousemove', handleSpeedBarAction);
                // speedBoxRef.current.removeEventListener('mouseup', handleSpeedBarAction);
            }

            // gameContainer
            if (!isEmpty(leftPartRoadRef.current)) {
                leftPartRoadRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.LEFT), { passive: false });
                rightPartRoadRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.RIGHT), { passive: false });
                leftSideImgRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.LEFT), { passive: false });
                rightSideImgRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.RIGHT), { passive: false });
            }
            clearAllIntervals();
        }
    }, []);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        if (isEmpty(currentTimeRef.current)) currentTimeRef.current = Date.now();
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (roadRef.current && horiZontalRoadRef.current && wrapBoxShadowRef.current) {
                horiZontalRoadRef.current.style.height = roadRef.current.getBoundingClientRect().width + "px";;
                wrapBoxShadowRef.current.style.width = roadRef.current.getBoundingClientRect().width - 4 + "px";
            }
        };

        if (horiZontalRoadRef.current) {
            window.addEventListener('resize', handleResize);
            // Call the resize function initially to set initial sizes
            handleResize();
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [roadRef.current, horiZontalRoadRef.current, wrapBoxShadowRef.current]);


    const getTransformValueFromStyle = (styleValue) => {
        // example 'translate(-100px, 100px)'
        return styleValue ? parseInt(styleValue.split('(')[1]?.split(')')[0]?.split(',')[1]?.split('px')[0]) : undefined
    }

    const handleChangeQuestion = async (i) => {
        if (i === totalQuestionLength || i === totalQuestionLength) {
            clearInterval(checkOverlapIntervalRef.current);
            let currentTime = Date.now();
            submitEndChallengeAttempt({
                ...finalChallengeDataRef.current,
                attemptId: attemptRef.current.id,
                microskillId: attemptRef.current.microskillId,
                challengeId: attemptRef.current.challengeId,
                scored: Math.ceil(totalScoreRef.current),
                timespent: totalGBATimespent.current,
                status: constants.GBA_ILA_STATUS.completed,
                completion: true,
            })
            await sleep(1000)
            // trackAnimationRef.current.style.display = 'flex';

            // trackAnimationRef.current.children[0].style.display = 'none';
            // trackAnimationRef.current.children[1].style.display = 'block';

            // setTimeout(() => {
            handleGbaState(GBA_SCREEN_STATE.WIN);
            setIsGameComplete(true);
            setGameEndType('win');
            gameEndTypeRef.current = 'win';
            // }, 3500)
        }
        else {
            questionAttempt.current === 0 && await sleep(1000);
            if (questionNoRef.current) {
                questionNoRef.current.innerHTML = activeIndexRef.current + 1;
            }
            if (questionRef.current) {
                questionRef.current.textContent = gameData.questions[i].question;
            }
            let context = gameData.questions[i].contextId !== null ? contextData.filter(element => element.id === gameData.questions[i].contextId) : [''];
            if (contextRef.current) {
                contextRef.current.textContent = context[0].context;
            }
            setTimeout(() => {
                if (i < totalQuestionLength) {
                    if (contextRef.current) {
                        contextRef.current.style.transform = 'scale(1)';
                    }
                    if (questionRef.current) {
                        questionRef.current.style.transform = 'scale(1)';
                    }
                }
            }, 1000);
            if (leftSideTFRef.current) {
                leftSideTFRef.current.isCorrect = gbaGameData[i].answers[0].isCorrect;
                if (leftSideTFRef.current.children[0]) {
                    leftSideTFRef.current.children[0].src = gbaGameData[i].answers[0].answer === "TRUE" ? trueBlock : falseBlock;
                }
            }
            if (rightSideTFRef.current) {
                rightSideTFRef.current.isCorrect = gbaGameData[i].answers[1].isCorrect;
                if (rightSideTFRef.current.children[0]) {
                    rightSideTFRef.current.children[0].src = gbaGameData[i].answers[1].answer === "TRUE" ? trueBlock : falseBlock;
                }
            }

            questionAttempt.current !== 0 ? resetCollision() :
                setTimeout(() => {
                    resetCollision();
                }, [200]);
        }
    }

    const checkRightOverlap = () => {
        if (isEmpty(gameEndTypeRef.current) && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
            let carBound = carImgRef.current?.getBoundingClientRect();
            let rightClient = rightWallRef.current?.getBoundingClientRect();

            if (carBound && rightClient) {
                let overlap = (carBound.top < rightClient.top)
                return overlap;
            }
        }
    };
    const checkLeftOverlap = () => {
        if (isEmpty(gameEndTypeRef.current) && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
            let carBound = carImgRef.current?.getBoundingClientRect();
            let leftClient = leftWallRef.current?.getBoundingClientRect();

            if (carBound && leftClient) {
                let overlap = carBound.top < leftClient.top

                return overlap;
            }
        }
    };

    // const checkMissedOverlap = () => {
    //     if (activeIndexRef.current < totalQuestionLength && gameEndType === '' && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
    //         let carBound = carImgRef.current?.getBoundingClientRect();
    //         let leftClient = leftSideTFRef.current?.children[0].getBoundingClientRect();
    //         let rightClient = rightSideTFRef.current?.children[0].getBoundingClientRect();

    //         return carBound && leftClient && carBound.left > leftClient.right && carBound.right < rightClient.left && carBound.top + 1 <= rightClient.top
    //     }
    // }

    const checkMonsterInRangeOfTrueFalse = () => {
        if (isEmpty(gameEndTypeRef.current) && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
            let carBound = carImgRef.current?.getBoundingClientRect();
            let leftClient = leftSideTFRef.current?.children[0]?.getBoundingClientRect();
            let rotateDistance;
            if (roadSpeedRef.current === roadSpeed1) {
                rotateDistance = 100;
            } else if (roadSpeedRef.current === roadSpeed2) {
                rotateDistance = 110;
            } else {
                rotateDistance = 130;
            }

            if (carBound && leftClient) {
                let centerOfCar = carBound.y + (carBound.height / 2);
                let centerOfLeftClient = leftClient.y + (leftClient.height / 2);

                // Adjust the range to check if the car is slightly below the center of the left client
                let inRange = (centerOfCar > centerOfLeftClient && centerOfCar <= centerOfLeftClient + 100); // x pixels below the center of the left client
                inRange && (inRangeRef.current = true);
                return inRange;
            }
        }
        return false;

    }

    const monsterMovesOutOfRange = () => {
        if (isEmpty(gameEndTypeRef.current)) {

            let carBound = carImgRef.current?.getBoundingClientRect();
            let leftClient = leftSideTFRef.current?.children[0].getBoundingClientRect();
            let outOfRange;
            if (leftClient && carBound) {
                outOfRange = leftClient.y > (carBound.y + (carBound.height) * 1);
            }
            return outOfRange
        }
    }

    const resetCollision = () => {
        clearInterval(tfScrollIntervalRef.current);
        startTFScrolling(0);

        checkOverlapIntervalRef.current = setInterval(() => {
            collisionRef.current = false;
            isOverlap();
        }, [200]);
        // setTimeout(() => {
        if (leftSideTFRef.current) {
            leftSideTFRef.current.style.display = 'block';
        }
        if (rightSideTFRef.current) {
            rightSideTFRef.current.style.display = 'block';
        }
    }

    // const missedOption = () => {
    //     clearInterval(checkOverlapIntervalRef.current);
    //     clearInterval(tfScrollIntervalRef.current);
    //     clearInterval(assestsIntervalRef.current);

    //     leftSideTFRef.current.style.display = 'none';
    //     rightSideTFRef.current.style.display = 'none';
    //     isMonsterOutOfRangeOfTFRef.current = true;


    //     handleIncorrectAnswer();
    //     // missedRef.current.style.opacity = '1';
    //     // missedRef.current.classList.add(`${classes.optionAnimation}`);

    //     setTimeout(() => {
    //         // missedRef.current.style.opacity = '0';
    //         carImgRef.current.classList.add(`${classes.blinkAnimationEffect}`);
    //         isMonsterOutOfRangeOfTFRef.current = false;
    //         // missedRef.current.classList.remove(`${classes.optionAnimation}`);
    //     }, [1000]);
    // }


    const handleLifeLoss = async () => {
        // let lifeValue = parseInt(lifeRef.current.innerHTML) - 1;
        let lifeValue = Math.max(parseInt(lifeRef.current.innerHTML) - 1, 0); // to prevent negative value

        setLife(lifeValue);

        if (lifeValue > 1) {
            scrollBoxRef.current.classList.add(`${classes.wrongAnsAnimation}`);
            setTimeout(() => {
                scrollBoxRef.current.classList.remove(`${classes.wrongAnsAnimation}`);
            }, [3000])
        }
        else if (lifeValue === 1) {
            lifeTextRef.current.style.color = '#FF5757';
            heartRef.current.src = '/images/icons/heart-red.png';
            lifeTextRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            questionContainerRef.current.classList.add(`${classes.questionAnimation}`);
            questionContainerRef.current.classList.add(`${classes.qstnBoxBoxShadow}`)

            scrollBoxRef.current.classList.remove(`${classes.wrongAnsAnimation}`);

            scrollBoxRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`);

        }

        if (lifeValue > 0) {
            activeIndexRef.current += 1;
            var progressBarWidth = ((activeIndexRef.current) / totalQuestionLength) * 100;
            progressBarRef.current.style.width = progressBarWidth + '%';

            lifeRef.current.innerHTML = lifeValue + ' x';
            resetMonsterAndRoad();
            handleChangeQuestion(activeIndexRef.current);
        }
        if (lifeValue <= 0) {
            lifeRef.current.innerHTML = lifeValue + ' x';
            clearInterval(checkOverlapIntervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
            let currentTime = Date.now();
            const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                ...finalChallengeDataRef.current,
                attemptId: attemptRef.current.id,
                microskillId: attemptRef.current.microskillId,
                challengeId: attemptRef.current.challengeId, scored: Math.ceil(totalScoreRef.current),
                timespent: totalGBATimespent.current,
                status: constants.GBA_ILA_STATUS.lifeLose,
                completion: false
            });

            setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

            await sleep(2000)
            setIsGameComplete(true);
            setGameEndType('loose');
            gameEndTypeRef.current = 'loose';
            handleGbaState(GBA_SCREEN_STATE.LOOSE);
            // carImgRef.current.style.left = '50%';
        }
    }

    const handleCorrectAnswer = () => {
        activeIndexRef.current += 1;
        var progressBarWidth = ((activeIndexRef.current) / totalQuestionLength) * 100;
        progressBarRef.current.style.width = progressBarWidth + '%';
        totalScoreRef.current += marksPerQuestion;
        resetMonsterAndRoad();
        handleChangeQuestion(activeIndexRef.current);
    }

    const handleIncorrectAnswer = () => {
        if (activeIndexRef.current < totalQuestionLength) {
            handleLifeLoss();
        }
    }

    const setInitialParameters = () => {
        let scrollValue = 0;
        // let scrollValue = -100;
        clearInterval(tfScrollIntervalRef.current);
        startTFScrolling(scrollValue);
        setTimeout(() => {
            questionVisible.current = true;
        }, [2000]);
    }

    const resetMonsterAndRoad = async () => {
        roadRef.current.style.transition = 'transform 0s linear';
        roadRef.current.style.transform = 'translateY(-58%)';
        yellowDotsContainerRef.current.style.opacity = '1';
        roadToRotate.current = 0

        resultImgRef.current.children[0].style = '';
        resultImgRef.current.children[0].src = '';
        resultImgRef.current.children[0].style.opacity = '0';
        resultImgRef.current.children[0].style.transform = 'scale(0)';
        carImgRef.current.children[1].style.display = 'none';
        carImgRef.current.children[0].style.display = 'block';
        monsterMissedLottieRef.current.stop();
        carImgRef.current.style = '';
        dotEatingShieldRef.current.style = '';

        // carImgRef.current.style.left = `50%`;
        // carImgRef.current.style.visibility = 'visible';
        // carImgRef.current.style.opacity = '1';


        leftSideTFRef.current.style.transform = 'translate(-50%, -50%)';
        leftSideTFRef.current.style.opacity = '1';

        rightSideTFRef.current.style.transform = 'translate(-50%, -50%)';
        rightSideTFRef.current.style.opacity = '1';

        roadStripsRef.current.style.height = `calc(100%)`;
        // missedRef.current.style.opacity = '0';
        yellowDotsContainerRef.current.style.height = '96.5%';

        setSelectedOption('');
        selectedOptionRef.current = '';

        collisionRef.current = false;
        inRangeRef.current = false;

        checkOverlapIntervalRef.current = setInterval(() => {
            collisionRef.current = false;
            isOverlap();
        }, [200]);

        setTimeout(() => {
            if (!isEmpty(carImgRef.current)) carImgRef.current.classList.remove(`${classes.blinkAnimationEffect}`);
        }, 1500)
    }

    const isOverlap = async () => {
        return
    }

    const restartGame = () => {
        setIsGamePage(true);
        setIsGameComplete(false);
        handleGbaState(GBA_SCREEN_STATE.GAME);
        questionVisible.current = false;
        setGameEndType('');
        gameEndTypeRef.current = '';
        getChallengeDataAsync()

        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespent.current = 0;

        setTimeout(() => {
            resetMonsterAndRoad();
            startGame();
        }, [1100]);
        totalScoreRef.current = 0;
        setSpeedLevel(roadSpeed2);
        setRoadSpeedLevel(roadSpeed2);
        speedLevelRef.current = 2;
        setLife(maxLife);
        activeIndexRef.current = 0;
        currentTimeRef.current = Date.now() //

        clearInterval(tfScrollIntervalRef.current);
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(rightKeyboardMovementRef.current);
        clearInterval(leftKeyboardMovementRef.current);
        clearInterval(assestsIntervalRef.current);
    }

    const handleBackButton = () => {
        clearInterval(tfScrollIntervalRef.current);
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        let currentTime = Date.now();
        let timeSpent = totalGBATimespent.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            timespent: timeSpent
        }))
    }

    const handleResumeButton = () => {
        if (isGamePage) {
            setAddPause(true);
            startTFScrolling(translatedTFRef.current);
            checkOverlapIntervalRef.current = setInterval(() => {
                isOverlap();
            }, [200]);
        }
    }

    const setSpeedBar = (elArray, upto, gradient) => {
        for (let i = 0; i < elArray.length; i++) {
            if (i < upto) {
                elArray[i].style.background = gradient;
            } else {
                elArray[i].style.background = "#fff";
            }
        }
    }

    const setSpeed = (interval, barLengthRatio) => {

        let speedBarChildren = speedBarRectBoxRef.current.children;
        let ratio = 1;

        if (barLengthRatio === 'one') {

            speedLevelRef.current = 1;
            ratio = 1;
        } else if (barLengthRatio === 'half') {
            speedLevelRef.current = 2;
            ratio = speedBarChildren.length / 2;
        } else if (barLengthRatio === 'all') {
            speedLevelRef.current = 3;
            ratio = speedBarChildren.length;
        }

        let currentTFY = parseInt(getTransformValueFromStyle(leftSideTFRef.current.style.transform));
        clearInterval(tfScrollIntervalRef.current);
        startTFScrolling(currentTFY);
        setSpeedBar(speedBarChildren, ratio, speedBarGradient);
    }


    const handleSpeedBarAction = (e, type) => {

        e.preventDefault();
        e.stopPropagation();

        if ((e.key === 'ArrowDown' || (e?.target?.id === 'speedDecrement' || e?.target?.parentElement?.id === 'speedDecrement')) && roadToRotate.current === 0) {
            setSpeedLevel(prevState => {
                if (prevState === roadSpeed1) {
                    roadSpeedRef.current = roadSpeed1;
                    return (prevState)
                }
                if (prevState === roadSpeed2) {
                    roadSpeedRef.current = roadSpeed1;
                    setSpeed(carFlowSpeed1, 'one');
                    setRoadSpeedLevel(roadSpeed1);
                    return (roadSpeed1);
                } else {
                    roadSpeedRef.current = roadSpeed2;
                    setSpeed(carFlowSpeed2, 'half');
                    setRoadSpeedLevel(roadSpeed2);
                    return (roadSpeed2);
                }
            })
        } else if ((e.key === 'ArrowUp' || (e?.target?.id === 'speedIncrement' || e?.target?.parentElement?.id === 'speedIncrement')) && roadToRotate.current === 0) {
            setSpeedLevel(prevState => {
                if (prevState === roadSpeed3) {
                    roadSpeedRef.current = roadSpeed3;
                    return (prevState)
                }
                if (prevState === roadSpeed1) {
                    roadSpeedRef.current = roadSpeed2;
                    setSpeed(carFlowSpeed2, 'half');
                    setRoadSpeedLevel(roadSpeed2);
                    return (roadSpeed2);
                } else {
                    roadSpeedRef.current = roadSpeed3;
                    setSpeed(carFlowSpeed3, 'all');
                    setRoadSpeedLevel(roadSpeed3);
                    return (roadSpeed3);
                }
            })
        }
    }

    const getIntervalFromRoadSpeed = () => {
        let lottieAssestHeight = leftSideImgRef.current?.getBoundingClientRect().height;

        switch (speedLevelRef.current) {
            case 1:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed1)));

            case 2:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed2)));

            default:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed3)));
        }
    }

    const getTranslateYfromElem = (elem) => {
        if (!isEmpty(elem)) {
            if (!isEmpty(elem.style)) {
                if (!isEmpty(elem.style.transform)) {
                    let translatedValue = elem.style.transform.split("(")[1].split(")")[0].split("%")[0];
                    return parseFloat(translatedValue);
                }
            }
        }
    }
    const startTFScrolling = (scrollValue) => {

        if (isGamePausedRef.current) {
            translatedTFRef.current = scrollValue;
            handleBackButton();
            return;
        }

        function createEl(type) {
            const newElement = document.createElement('img');
            newElement.src = "/images/gba/tasty-truth/tasty_truth_grid_long.png";
            newElement.className = classes.roadSideImg;
            return newElement
        }

        clearInterval(tfScrollIntervalRef.current);
        clearInterval(assestsIntervalRef.current);

        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            totalGBATimespent.current += 1;
            setAddPause(true);
        }, 1000);
        // if (questionVisible.current) {
        tfScrollIntervalRef.current = setInterval(() => {
            moveRoad("F") // move road forward

        }, getIntervalFromRoadSpeed());
        // }
        assestsIntervalRef.current = setInterval(() => {
            let movedPixel = 0;
            if (!isEmpty(scrollBoxRef?.current?.offsetHeight)) {
                movedPixel = scrollBoxRef.current.offsetHeight + getTransformValueFromStyle(leftRoadImgRef.current.style.transform);
            }

            if (!isEmpty(leftRoadImgRef.current) && !isEmpty(rightRoadImgRef.current) && !isEmpty(roadStripImgRef.current)) {
                leftRoadImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                rightRoadImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                roadStripImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                if (movedPixel === sideImgHeight.current && leftRoadImgRef.current.children.length < 3) {
                    leftRoadImgRef.current.insertBefore(createEl('left'), leftRoadImgRef.current.firstChild);
                    rightRoadImgRef.current.insertBefore(createEl('right'), rightRoadImgRef.current.firstChild);
                    roadStripImgRef.current.insertBefore(createEl('road'), roadStripImgRef.current.firstChild);
                }
            }

        }, getIntervalFromRoadSpeed());
    }

    const stopCarMovement = () => {
        if (leftKeyboardMovementRef.current) {
            clearInterval(leftKeyboardMovementRef.current);
            leftKeyboardMovementRef.current = false;
        }
        if (rightKeyboardMovementRef.current) {
            clearInterval(rightKeyboardMovementRef.current);
            rightKeyboardMovementRef.current = false;
        }
    }

    const leftKeyboardMovementStop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        stopCarMovement();
    }

    const rightKeyboardMovementStop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        stopCarMovement();
    }

    const handleAttemptedChallenge = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptRef.current.id,
            questions: [questionObj]
        }

        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let currentTime = Date.now();
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };
        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;

        // Check if finalChallengeDataRef.current is empty
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            };
        } else {
            // Clone the questions array to ensure mutability
            const updatedQuestions = [...finalChallengeDataRef.current.questions, tempQuestionObj];

            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                questions: updatedQuestions,
                timespent: totalTimeSpent,
            };
        }
    };


    const startGame = () => {
        // if (!isEmpty(carImgRef.current)) carImgRef.current.style.opacity = 0;
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            totalGBATimespent.current += 1;
            setAddPause(true);
        }, 1000);

        handleGbaState(GBA_SCREEN_STATE.GAME);
        totalScoreRef.current = 0;
        assestsScrollValueRef.current = 0;

        //for speedBar
        let scrollElement2 = speedBoxRef.current;
        scrollElement2.addEventListener('wheel', handleSpeedBarAction, { passive: false });
        scrollElement2.addEventListener('click', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('touchstart', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('touchend', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('mousedown', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('mouseup', handleSpeedBarAction, { passive: false });

        // gameContainer
        leftPartRoadRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.LEFT), { passive: false });
        rightPartRoadRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.RIGHT), { passive: false });
        leftSideImgRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.LEFT), { passive: false });
        rightSideImgRef.current.addEventListener('click', () => handleTFContainerClick(SIDES.RIGHT), { passive: false });

        collisionRef.current = false;
        questionAttempt.current = 0;
        setInitialParameters();

        setTimeout(() => {
            if (carImgRef && carImgRef.current) {
                carImgRef.current.style.opacity = 1;
                carImgRef.current.style.left = '50%';
            }
            if (questionContainerRef && questionContainerRef.current) {
                questionContainerRef.current.style.transform = 'translate(0px)';
            }
            setInitialParameters();
            handleChangeQuestion(0);
        }, [1500])

        checkOverlapIntervalRef.current = setInterval(() => {
            if (activeIndexRef.current < totalQuestionLength) {
                isOverlap();
            }
        }, 200);
    }

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    document.onkeydown = (e) => {
        if (isGamePage && !isGameComplete) {
            switch (e.key) {
                case 'ArrowLeft':
                    handleTFContainerClick(SIDES.LEFT)
                    break;
                case 'ArrowRight':
                    handleTFContainerClick(SIDES.RIGHT)
                    break;
                case 'ArrowUp':
                    handleSpeedBarAction(e, 'one');
                    break;
                case 'ArrowDown':
                    handleSpeedBarAction(e, 'two');
                    break;
            }
        }
    }

    document.onkeyup = (e) => {
        if (isGamePage && !isGameComplete) {
            switch (e.key) {
                case 'ArrowLeft':
                    leftKeyboardMovementStop(e);
                    break;
                case 'ArrowRight':
                    rightKeyboardMovementStop(e);
                    break;
            }
        }
    }

    useEffect(() => {
        isGamePausedRef.current = isPauseOverlay;
        if (isPauseOverlay) {
            handleBackButton();
            pausedTimeRef.current = Date.now();
        } else if (isGamePage) {
            pausedTimeRef.current = Date.now() - pausedTimeRef.current;
            currentTimeRef.current += pausedTimeRef.current;
            handleResumeButton();
        }
    }, [isPauseOverlay]);

    useEffect(() => {
        if (isGamePage) {
            const leftRoadImg = document.getElementById('leftRoadImg');

            // Wait for the image to load
            if (leftRoadImg) {
                leftRoadImg.onload = async function () {
                    sideImgHeight.current = leftRoadImg.height;
                    startGame();
                };
            }
        }
    }, [isGamePage])

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, [])

    window.addEventListener("resize", resFont);


    const moveRoad = async (dir) => {
        let roadToBeTranslate = getTranslateYfromElem(roadRef.current) - (dir === 'F' ? -roadSpeedRef.current : roadSpeedRef.current)
        translatedTFRef.current = roadToBeTranslate;

        if (!isEmpty(roadRef.current)) roadRef.current.style.transform = `translateY(${roadToBeTranslate}%)`;


        let isMonsterAlignedWithTF = checkMonsterInRangeOfTrueFalse();
        isMonsterInRangeOfTrueFalseRef.current = isMonsterAlignedWithTF;


        if ((isMonsterAlignedWithTF && !isEmpty(selectedOptionRef.current)) || roadToRotate.current !== 0) {
            roadToRotate.current = (selectedOptionRef.current === SIDES.LEFT ?
                Math.min(roadToRotate.current + 2, 90) :
                Math.max(roadToRotate.current - 2, -90));

            let roadTranslateRate = 0.1 - 0.01;  // Adjust the multiplier for desired smoothness

            roadToBeTranslate = getTranslateYfromElem(roadRef.current) - (dir === 'F' ? -roadTranslateRate : roadTranslateRate);

            // Apply the transformations
            roadRef.current.style.transform = `translateY(${roadToBeTranslate}%) rotate(${roadToRotate.current}deg)`;
            yellowDotsContainerRef.current.style.opacity = '0';
            // dotEatingShieldRef.current.style.opacity = '0';

            // Call the turnRoad function with the selected option
            turnRoad(selectedOptionRef.current);
        }

        let isOutOfRange = monsterMovesOutOfRange();
        if (isOutOfRange && isEmpty(selectedOptionRef.current)) {

            dotEatingShieldRef.current.style.opacity = '0';
            questionAttempt.current = questionAttempt.current + 1;
            clearInterval(tfScrollIntervalRef.current);
            clearInterval(assestsIntervalRef.current);
            tfScrollIntervalRef.current = null;
            assestsIntervalRef.current = null;
            var timeToGetSlow;
            switch (roadSpeedRef.current) {
                case roadSpeed1:
                    timeToGetSlow = 3500;
                    break;
                case roadSpeed2:
                    timeToGetSlow = 2000;
                    break;
                case roadSpeed3:
                    timeToGetSlow = 1500;
                    break;
            }

            carImgRef.current.children[0].style.display = 'none';
            carImgRef.current.children[1].style.display = 'block';
            monsterMissedLottieRef.current.play();

            scrollBoxRef.current.classList.add(`${classes.wrongAnsAnimation}`);
            setTimeout(() => {
                handleLifeLoss();
            }, timeToGetSlow)
            // }
        }
    }
    const turnRoad = async (dir) => {
        let leftCollidTop = checkLeftOverlap(); let rightCollidTop = checkRightOverlap();

        function animateTFandBG(ref, isCorrect, direction) {
            setSelectedOption("")
            ref.style.transition = 'transform 1s linear, opacity 1s linear';
            ref.style.transform = 'translate(-50%, -50%) scale(0)';
            ref.style.opacity = '0';
        }
        async function animateAnswer(ref, isCorrect) {
            resultImgRef.current.children[0].src = `/images/gba/tasty-truth/${isCorrect ? "green-tick" : "red-cross"}.png`;
            resultImgRef.current.children[0].style.transition = 'transform 1s linear, opacity 1s linear';
            resultImgRef.current.children[0].style.transform = 'scale(1)';
            resultImgRef.current.children[0].style.opacity = '1';
        }
        if ((dir === SIDES.LEFT && roadToRotate.current > 0)) {
            // leftSideTFRef.current.style.transition = 'transform 2s linear';
            // leftSideTFRef.current.style.transform = 'scale(0)';
            animateTFandBG(leftSideTFRef.current, leftSideTFRef.current.isCorrect ? true : false, SIDES.LEFT);
            if (leftCollidTop && roadToRotate.current === 90) {
                animateAnswer(leftSideTFRef.current, leftSideTFRef.current.isCorrect ? true : false, SIDES.LEFT);
                collisionRef.current = true;
                questionRef.current.style.transform = 'scale(0)';
                contextRef.current.style.transform = 'scale(0)';
                clearInterval(tfScrollIntervalRef.current);
                let timeToGetSlow;
                switch (roadSpeedRef.current) {
                    case roadSpeed1:
                        timeToGetSlow = 6000;
                        break;
                    case roadSpeed2:
                        timeToGetSlow = 5500;
                        break;
                    case roadSpeed3:
                        timeToGetSlow = 4500;
                        break;
                    default:
                        timeToGetSlow = 2000;
                        break;
                }

                roadRef.current.style.transition = `transform ${timeToGetSlow}ms linear`;
                roadRef.current.style.transform = `translateY(${translatedTFRef.current + 40}%) rotate(90deg)`;
                // roadToRotate.current = 0;
                clearInterval(checkOverlapIntervalRef.current);
                if (activeIndexRef.current < totalQuestionLength) {
                    let currentTime = Date.now();
                    handleAttemptedChallenge({
                        qId: gameData.questions[activeIndexRef.current].id,
                        aId: leftSideTFRef.current.isCorrect ? gameData.questions[activeIndexRef.current].answers[0].id : gameData.questions[activeIndexRef.current].answers[1].id,
                        isCorrect: leftSideTFRef.current.isCorrect ? gameData.questions[activeIndexRef.current].answers[0].isCorrect : gameData.questions[activeIndexRef.current].answers[1].isCorrect,
                        t: Math.round((currentTime - currentTimeRef.current) / 1000),
                        skillId: gameData.questions[activeIndexRef.current].skillId,
                        contextId: gameData.questions[activeIndexRef.current].contextId
                    });
                    currentTimeRef.current = currentTime;
                }
                questionAttempt.current = questionAttempt.current + 1;
                await sleep(2500);
                leftSideTFRef.current.isCorrect ? handleCorrectAnswer() : handleIncorrectAnswer();
            }
        } else if ((dir === SIDES.RIGHT && roadToRotate.current < 0)) {
            // rightSideTFRef.current.style.transition = 'transform 2s linear';
            // rightSideTFRef.current.style.transform = 'scale(0)';
            animateTFandBG(rightSideTFRef.current, rightSideTFRef.current.isCorrect ? true : false, SIDES.RIGHT);
            if (rightCollidTop && roadToRotate.current === -90) {
                animateAnswer(rightSideTFRef.current, rightSideTFRef.current.isCorrect ? true : false, SIDES.LEFT);
                collisionRef.current = true;
                questionRef.current.style.transform = 'scale(0)';
                contextRef.current.style.transform = 'scale(0)';

                clearInterval(tfScrollIntervalRef.current);
                // roadToRotate.current = 0;
                clearInterval(checkOverlapIntervalRef.current);
                let timeToGetSlow;
                switch (roadSpeedRef.current) {
                    case roadSpeed1:
                        timeToGetSlow = 6000;
                        break;
                    case roadSpeed2:
                        timeToGetSlow = 5500;
                        break;
                    case roadSpeed3:
                        timeToGetSlow = 4500;
                        break;
                    default:
                        timeToGetSlow = 2000;
                        break;
                }

                roadRef.current.style.transition = `transform ${timeToGetSlow}ms linear`;
                roadRef.current.style.transform = `translateY(${translatedTFRef.current + 40}%) rotate(-90deg)`;
                if (activeIndexRef.current < totalQuestionLength) {
                    let currentTime = Date.now();
                    handleAttemptedChallenge({
                        qId: gameData.questions[activeIndexRef.current].id,
                        aId: rightSideTFRef.current.isCorrect ? gameData.questions[activeIndexRef.current].answers[0].id : gameData.questions[activeIndexRef.current].answers[1].id,
                        isCorrect: rightSideTFRef.current.isCorrect ? gameData.questions[activeIndexRef.current].answers[0].isCorrect : gameData.questions[activeIndexRef.current].answers[1].isCorrect,
                        t: Math.round((currentTime - currentTimeRef.current) / 1000),
                        skillId: gameData.questions[activeIndexRef.current].skillId,
                        contextId: gameData.questions[activeIndexRef.current].contextId
                    })
                    currentTimeRef.current = currentTime;
                }
                questionAttempt.current = questionAttempt.current + 1;
                await sleep(2500);
                rightSideTFRef.current.isCorrect ? handleCorrectAnswer() : handleIncorrectAnswer();
            }
        }
    }

    const handleTFContainerClick = (dir) => {
        if (isMonsterOutOfRangeOfTFRef.current) return;
        if (!isEmpty(moveMonsterIntervalRef.current)) return;
        !inRangeRef.current && setSelectedOption(dir);
        !inRangeRef.current && (selectedOptionRef.current = dir);
    }

    async function typeWriter(id, txt, speed) {
        let i = 0, element = document.getElementById(id);
        element.innerHTML = '';
        if (!isEmpty(element)) {
            while (i < txt.length) {
                if (i === txt.length - 1) {
                    setIsAudioPlaying(false);
                }
                element.innerHTML += txt.charAt(i);
                i++;
                await sleep(speed);
            }
        }
    }

    const audioClicked = async () => {
        const audio = audioRef.current;
        try {
            audio.pause();
            audio.currentTime = 0;
            audio.src = gameData.questions[activeIndexRef.current].speechUrl;
            audio.load();
            await audio.play();
            setIsAudioPlaying(true);
            typeWriter('contextBox', gameData.questions[activeIndexRef.current].question, 50);
        } catch (error) {
            console.error("Audio playback interrupted:", error);
        }
    };

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? {
            // backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, backgroundSize: 'contain',
            fontSize: `${fSize}rem`, height: '100%'
        } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={classes.CrazyCarsRoot} ref={CrazyCarsRef}>
            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    bg={'/images/gba/tasty-truth/bg.png'}
                                    logoWidth={'85%'}
                                    gameLogo={"/images/gba/tasty-truth/tasty-truth_game_logo.png"}
                                    handleGbaState={handleGbaState}

                                    startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                    setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                    startScreenGameBgLoaded={startScreenGameBgLoaded}
                                    setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                                />
                            </>
                        ) : (
                            <div> {t("How to play of crazy cars")}</div>
                        )
                    ) : (
                        !isGameComplete ? (
                            <>
                                {/* <div className={`${classes.initialGameAnimation} ${classes.centerFlex}`} ref={trackAnimationRef}>
                                    <img src="/images/gba/tasty-truth/start.jpg" alt="track" className={`${classes.animeTrackImg}`} />
                                    <img src="/images/gba/tasty-truth/end.jpg" alt="track" className={`${classes.finalGameAnimation}`} style={{ display: 'none' }} />
                                </div> */}
                                <div className={classes.incorrectGradientOverlay} ref={incorrectGradientOverlayRef} />

                                <div className={classes.gameContainer} id="gameContainer">
                                    <div className={classes.qstnWrapper}>
                                        <div className={classes.progressBarContainer}>
                                            <div className={classes.progressBar} ref={progressBarRef} />
                                        </div>

                                        {/* Question Box */}
                                        <div className={classes.qstnContainer} ref={questionContainerRef}>
                                            <div className={classes.qstnBox}>
                                                <div className={classes.assetBox}>
                                                    <div className={classes.lifeText} ref={lifeTextRef}>
                                                        <div className={classes.life} ref={lifeRef}>{life} x</div>
                                                        <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} ref={heartRef} alt="heart" />
                                                    </div>
                                                    <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndexRef.current + 1}</span>/{totalQuestionLength}</div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
                                                    <div className={classes.context} ref={contextRef}></div>
                                                    <div className={classes.qstn} ref={questionRef} id="contextBox"></div>
                                                </div>
                                                <div className={classes.muteSwitch} onClick={audioClicked} style={{ display: isAudioPlaying ? "none" : "flex" }}>
                                                    {((!isAudioPlaying && !isEmpty(gameData?.questions[activeIndexRef.current]?.speechUrl))) ? (
                                                        <button className={classes.playButton}>
                                                            <div className={classes.playIcon}></div>
                                                        </button>
                                                    ) : null
                                                    }
                                                </div>
                                                <div className={classes.invisibleDiv}></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Game Play Container */}
                                    <div className={classes.gameplayContainer} ref={gamePlayContainerRef} style={{ backgroundImage: `url(/images/gba/tasty-truth/bg.png)` }}>
                                        {/* <div className={classes.dotEating}></div> */}
                                        <div className={classes.scrollBox} ref={scrollBoxRef}>

                                            <div className={`disableSelection ${classes.leftSideImg}`} ref={leftSideImgRef}>
                                                <div className={classes.imgBox} ref={leftRoadImgRef}>
                                                    <img src="/images/gba/tasty-truth/tasty_truth_grid_long.png" alt="left-side-road" className={`disableSelection disablePointerEvents ${classes.roadSideImg}`} id="leftRoadImg" style={{ opacity: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.road} ref={roadRef} style={{ transform: `translateY(-58%)` }}>
                                                <div className={classes.horizontalRoad} ref={horiZontalRoadRef}>
                                                    <div className={`${classes.wrapBoxShadow}`} ref={wrapBoxShadowRef}></div>
                                                    <div className={`${classes.leftWall}`} ref={leftWallRef}></div>

                                                    <div className={`${classes.optionContainer} ${classes.leftOptionContainer} `}
                                                        ref={leftSideTFRef}
                                                        onClick={(e) => {
                                                            handleTFContainerClick(SIDES.LEFT)
                                                        }}

                                                    >
                                                        <img className={`disableSelection ${classes.options} ${classes.optionLeft} ${selectedOption === SIDES.LEFT ? classes.selectedOption : ''}`} src='' alt="" />
                                                    </div>
                                                    <div className={`${classes.rightWall}`} ref={rightWallRef}></div>
                                                    <div className={`${classes.optionContainer} ${classes.rightOptionContainer}`}
                                                        ref={rightSideTFRef}
                                                        onClick={(e) => {
                                                            handleTFContainerClick(SIDES.RIGHT)
                                                        }}
                                                    >
                                                        <img className={`disableSelection ${classes.options} ${classes.optionLeft} ${selectedOption === SIDES.RIGHT ? classes.selectedOption : ''}`} src='' alt="" />
                                                    </div>
                                                    <div className={classes.horzYellowDotsContainer} ref={horzYellowDotsContainerRef} style={{ height: '10px' }}> {/* 95% */}
                                                        {
                                                            Array.from(Array(YELLOW_DOTS_COUNT * 2)).map((e, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className={classes.horzYellowDot}
                                                                        id={`yellow_dot_${i}`}
                                                                        style={{
                                                                            left: `${i * (Math.floor((2 * window.innerHeight) / YELLOW_DOTS_COUNT))}px`
                                                                        }}

                                                                    ></div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className={classes.missedContainer} ref={missedRef}>MISSED!</div> */}

                                                <div className={classes.optionContainerBox} ref={leftPartRoadRef}>
                                                </div>

                                                <div className={classes.roadStrips} ref={roadStripsRef}>
                                                    <div className={classes.yellowDotsContainer} ref={yellowDotsContainerRef} style={{ height: '95%' }}> {/* 100% */}
                                                        {
                                                            Array.from(Array(YELLOW_DOTS_COUNT)).map((e, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className={classes.yellowDot}
                                                                        id={`yellow_dot_${i}`}
                                                                        style={{
                                                                            top: `${i * (Math.floor((2 * window.innerHeight) / YELLOW_DOTS_COUNT))}px`
                                                                        }}
                                                                    ></div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className={classes.optionContainerBox} ref={rightPartRoadRef}></div>

                                            </div>
                                            <div className={`disableSelection ${classes.rightSideImg}`} ref={rightSideImgRef}>
                                                <div className={classes.imgBox} ref={rightRoadImgRef}>
                                                    <img src="/images/gba/tasty-truth/tasty_truth_grid_long.png" alt="right-road" className={`disableSelection disablePointerEvents ${classes.roadSideImg} ${classes.rightRoadSideImg}`} id="rightRoadImg" style={{ opacity: 0 }} />
                                                </div>

                                            </div>
                                            <div className={classes.carImg} ref={carImgRef}>
                                                <Lottie
                                                    loop={true}
                                                    animationData={monsterAnimation}
                                                    play
                                                    ref={monsterLottieRef}
                                                    style={{ width: '100%', height: '100%' }}
                                                />
                                                <Lottie
                                                    loop={false}
                                                    ref={monsterMissedLottieRef}
                                                    animationData={monsterMissedAnimation}
                                                    style={{ display: 'none', width: '100%', height: '100%' }}
                                                />
                                            </div>
                                            <div className={classes.resultImg} ref={resultImgRef}>
                                                <img src="" className={`disableSelection ${classes.optionsResult}`} alt="result"></img>
                                            </div>
                                            <div className={`${classes.DotEatingShield}`} ref={dotEatingShieldRef}></div>

                                        </div>

                                        <div className={classes.speedBarBox} ref={speedBoxRef}>

                                            <div className={classes.speedDecrement} id='speedDecrement'>
                                                <img src="/images/gba/tasty-truth/decrement.png" alt="minus" className={`disableSelection`} />
                                            </div>
                                            <div className={classes.speedBarRectBox} ref={speedBarRectBoxRef}>
                                                {
                                                    Array.from(Array(speedBarLength), (e, i) => (
                                                        <div
                                                            key={i}
                                                            style={{
                                                                background: i < speedBarLength / 2 ? speedBarGradient : speedBarGradientFade
                                                            }}
                                                            className={classes.speedBar}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            <div className={classes.speedIncrement} id='speedIncrement'>
                                                <img src="/images/gba/tasty-truth/increment.png" alt="plus" className={`disableSelection`} />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <audio ref={audioRef}></audio>
                            </>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={defaultBackground}
                                            obtainedScore={Math.ceil(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameData?.gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={defaultBackground}
                                            // bgGradient={bgGradientLoss}
                                            restartGame={restartGame} />
                                    )
                                }
                            </>
                        )
                    )
                )}
        </div>
    )
}

export default TastyTruth;