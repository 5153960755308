import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Lottie from 'react-lottie-player';

// mui
import CircularProgress from '@mui/material/CircularProgress';

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/mrr-how-to-play";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// Style
import useStyle from "./index.styles";

// lottie

import ROCKET_CORRECT_2 from "../../../lottie-assets/rocketcorrect_2.json";
import ROCKET_WRONG_2 from "../../../lottie-assets/rocketwrong_2.json";

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
const IMG_BUCKET_EMPTY = '/images/gba/Popcorn_Match_Bucket_Empty.png';
const IMG_BUCKET_FULL = '/images/gba/Popcorn_Match_Bucket_Full.png';

const CrackerMania = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, totalQuestions, setAddPause } = props;
    const { questionData, designType, gameType } = gameData;
    const [activeIndex, setActiveIndex] = useState(0);
    const activeIndexRef = useRef(0)
    const isTrueFalse = questionData[activeIndex].options.length === 2;
    let topCardCount = isTrueFalse ? 2 : 3;
    let bottomCardCount = isTrueFalse ? 2 : 2;

    questionData[activeIndex].totalOptions = isTrueFalse ? questionData[activeIndex].totalOptions : questionData[activeIndex].totalOptions;
    const questionsLength = isEmpty(questionData[0]) ? 0 : questionData[0].length;
    const { microskillId, challengeId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);

    const [isGameComplete, setIsGameComplete] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [attemptData, setAttemptData] = useState('');
    const [incorrectData, setIncorrectData] = useState();
    const [topCardArrayRef, setTopCardArrayRef] = useState([]);
    const [bottomCardArrayRef, setBottomCardArrayRef] = useState([]);
    const [isLottieSet, setIsLottieSet] = useState(false);

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const progressBarRef = useRef();
    const popcornMatchRootRef = useRef();
    const questionRef = useRef();
    const qstnBoxRef = useRef();
    const containerRef = useRef();
    const totalScoreRef = useRef(0);
    const isDragActive = useRef(true);
    const dragItemElementRef = useRef(null);
    const dragItemRef = useRef(null);
    const topCardRef = useRef(null);
    const attemptCountRef = useRef(0);
    // const topCardArrayRef = useRef();
    // const bottomCardArrayRef = useRef();
    const remainingTopCardArrayRef = useRef();
    const remainingBottomCardArrayRef = useRef();
    const timeRefText = useRef();
    const lifeRef = useRef();

    const lifeRefImg = useRef();
    const intervalRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);

    const finalChallengeDataRef = useRef();
    const allAttempCountRef = useRef(0);
    const attemptDataRef = useRef();
    const gradientRef = useRef();
    const lifeBoxRef = useRef();
    const currentIndex = useRef(0);
    const questionNoRef = useRef();
    const correctLottieRefsArray = useRef([]);
    const wrongLottieRefsArray = useRef([]);
    const lottiePlayCompleted = useRef(null);
    const topCardLottieRef = useRef();
    const bottomCardRef = useRef();

    const [correctAttemptCountPerContext, setCorrectAttemptCountPerContext] = useState(0)
    // const correctAttemptCountPerContext = useRef(0)
    // const lottieAnimeDataRef = useRef(true)
    const [lottieAnimeData, setLottieAnimeData] = useState(true)

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: designType,
        questionsInGba: totalQuestions,
        maxQuestion: maxQuestion,
        // maxLife: maxLifeGba,
        maxLife: maxLifeGba,
    });

    const maxTime = 180;
    const mxmScore = 120;

    let marksPerAnswer = mxmScore / totalQuestions;
    let bgGradientDefault = defaultGradient;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let transitionTimeForCorrectAnswer = 500;
    let bufferTime = 300;
    // let bufferTime = 1000;
    const [fSize, setFSize] = useState(1);
    const lifeRefText = useRef(maxLife);
    const prevAnsTimeRef = useRef(maxTime);

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);
    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
    }, [])

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    // // effect to set ref for rocket lotties at top cards
    useEffect(() => {
        if (!isEmpty(topCardArrayRef) && !isLottieSet) {
            correctLottieRefsArray.current = !isEmpty(topCardArrayRef) && commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].options).map(() => React.createRef());
            wrongLottieRefsArray.current = !isEmpty(topCardArrayRef) && commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].options).map(() => React.createRef());

            // correctLottieRefsArray.current = !isEmpty(topCardArrayRef.current) && topCardArrayRef.current.map(() => React.createRef());
            // wrongLottieRefsArray.current = !isEmpty(topCardArrayRef.current) && topCardArrayRef.current.map(() => React.createRef());
            setIsLottieSet(true);
        }

    }, [topCardArrayRef, currentIndex])

    useEffect(() => {
        if (!isEmpty(dragItemElementRef.current) && correctAttemptCountPerContext === 4) {
            dragItemElementRef.current.style.visibility = "hidden";
        }

        if (correctAttemptCountPerContext >= 5) {
            setCorrectAttemptCountPerContext(0)
        }
        return setCorrectAttemptCountPerContext(0)

    }, [correctAttemptCountPerContext, dragItemElementRef.current])


    const handleBackButtonClick = () => {
        let timeSpent = totalGBATimespent.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            timespent: timeSpent
        }))
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer();
    }

    useEffect(() => {
        (async function () {
            if (isGamePage) {
                handleGbaState(GBA_SCREEN_STATE.GAME);
                await sleep(2500) // wait for initial animations to be done
                startTimer();
            }
        })();
    }, [isGamePage]);

    useEffect(() => {

        (async () => {
            if (isEmpty(questionData)) return
            setShowCard(false);
            // topCardArrayRef.current = commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions);
            // setTopCardArrayRef(commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions));
            // bottomCardArrayRef.current = commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(topCardArrayRef));
            // setBottomCardArrayRef(commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(topCardArrayRef)));
            setTopCardArrayRef(prev => {
                let newState = commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions);
                setBottomCardArrayRef(commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(newState)))
                return newState
            });
            remainingTopCardArrayRef.current = commonUtil.getSlicedArray(questionData[activeIndex].totalOptions.length - topCardCount, topCardCount, questionData[activeIndex].totalOptions);
            // totalOptionRefForTF.current = questionData[activeIndex].totalOptions;
            attemptCountRef.current = 0;
            await sleep(100);
            setShowCard(true);

            if (activeIndex > 0 && !isEmpty(questionRef.current)) {
                questionRef.current.style.scale = '1';
            }
        })();
    }, [activeIndex, questionData]);

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptDataRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attemptData])

    const startTimer = async () => {
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            if (isEmpty(timeRefText.current)) {
                clearInterval(totalGBATimespentIntervalRef.current);
                return
            }
            setAddPause(true);
            totalGBATimespent.current += 1;
        }, 1000);
        intervalRef.current = setInterval(async () => {
            if (isEmpty(timeRefText.current)) {
                clearInterval(intervalRef.current);
                return
            }
            let time = parseInt(timeRefText.current.innerHTML) - 1;
            timeRefText.current.innerHTML = time;
            if (time === 0) {
                isDragActive.current = false;
                clearInterval(intervalRef.current);
                clearInterval(totalGBATimespentIntervalRef.current);

                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: attemptDataRef.current.microskillId,
                    attemptId: attemptDataRef.current.id,
                    challengeId: attemptDataRef.current.challengeId,
                    scored: Math.ceil(totalScoreRef.current),
                    completion: false,
                    status: "TIMERLOSE",
                    timespent: totalGBATimespent.current
                });
                await sleep(2 * bufferTime);
                setShowCard(false);
                await sleep(bufferTime);
                setIsGameComplete(true);
                setGameEndType('timeup');
                handleGbaState(GBA_SCREEN_STATE.LOOSE);
                if (!isEmpty(gradientRef.current)) {
                    gradientRef.current.style.zIndex = -1;
                    gradientRef.current.classList.remove(classes.opacityAnimation);
                }
                // }, 1000);
                return
            }
        }, 1000);
    }

    const getIdFromElement = (el) => {
        return el.getAttribute('id').split('-')[1];
    }

    const dragStart = (e) => {

        if (parseInt(timeRefText.current.innerHTML) === 0 || e.target.getAttribute('drag') !== 'true' || !isDragActive.current) return;
        dragItemElementRef.current = e.target;
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
            document.addEventListener("touchmove", drag)
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
            document.addEventListener("mousemove", drag)
        }
    }

    const drag = (e) => {

        if (isEmpty(dragItemElementRef.current) || !isDragActive.current || parseInt(timeRefText.current.innerHTML) === 0) return;
        // e.preventDefault();
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragItemElementRef.current, false, '0.8');
        let dragItemCardId = getIdFromElement(dragItemElementRef.current);

        let dragItemAnswerObj = bottomCardArrayRef.find(d => d.questionId === dragItemCardId);
        dragItemRef.current = { dragItemAnswerObj, dragItemEl: dragItemElementRef.current }
        dragItemElementRef.current.querySelector(`#bottom-text-${dragItemCardId}`).style.opacity = 0;

        topCardArrayRef.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.questionId}-${i}`);
            let topCardChildEl = document.getElementById(`top-text-${item.questionId}`);

            if (isEmpty(dragItemElementRef.current) || isEmpty(topCardEl) || isEmpty(topCardChildEl)) return;

            if (isOverlapping(dragItemElementRef.current, topCardEl)) {
                let topCardId = getIdFromElement(topCardEl);
                let topCardObj = topCardArrayRef.find(d => d.questionId === topCardId);
                topCardEl.style.scale = '1.2';
                // topCardChildEl.style.opacity = '0'
                topCardRef.current = { topCardObj, topCardEl }
            } else {
                // topCardChildEl.style.opacity = '1'
                topCardEl.style.scale = '1';
            }
        })
    }

    const setDrag = async (time = 0) => {
        await sleep(time)
        isDragActive.current = true;
    }

    const dragEnd = async (e) => {
        if (parseInt(timeRefText.current.innerHTML) === 0 || !isDragActive.current) return;
        isDragActive.current = false;

        xOffset = 0;
        yOffset = 0;

        // bottomCardRef.current.style.zIndex = '1';
        // topCardLottieRef.current.style.zIndex = '999999';

        // change the opacity to 1 for the dragged card
        let dragItemCardId = null;
        let dragItemChildEl = null;
        if (!isEmpty(dragItemElementRef.current)) {

            dragItemCardId = dragItemElementRef.current.getAttribute('id').split('-')[1];
            dragItemChildEl = dragItemElementRef.current.querySelector(`#bottom-text-${dragItemCardId}`);
        }

        if (isEmpty(dragItemRef.current) || isEmpty(topCardRef.current)) {

            setTranslate(0, 0, dragItemElementRef.current, true);

            // dragItemElementRef.current.style.transform = ""
            // dragItemElementRef.current.style.transition = ""
            // if (!isEmpty(dragItemElementRef.current)) dragItemElementRef.current.style = "";

            dragItemElementRef.current = null;
            if (!isEmpty(dragItemChildEl)) dragItemChildEl.style.opacity = '1'

            setDrag(500);
            return;
        }

        let dragElement = dragItemElementRef.current;
        let topCardElement = topCardRef.current.topCardEl;

        // hide draggingItem text while animation
        if (!isEmpty(dragItemChildEl)) dragItemChildEl.style.opacity = '0'

        // testing purpose
        // dragElement.style.opacity = '0'

        // CHECK FOR CORRECT ANSWER
        if (isOverlapping(dragElement, topCardElement)) {
            currentIndex.current += 1;
            dragItemElementRef.current.style.opacity = '0';

            // attempts counter (it will help in hiding DIYA at 4th attepmt)
            setCorrectAttemptCountPerContext(prev => prev += 1)

            let topCardId = getIdFromElement(topCardRef.current.topCardEl);
            let topCardChildEl = topCardRef.current.topCardEl.querySelector(`#top-text-${topCardId}`);

            topCardChildEl.style.opacity = '0';

            let bottomCardId = getIdFromElement(dragItemElementRef.current);

            let topCardIndex = topCardArrayRef.findIndex(d => d.questionId === topCardId);
            let bottomCardIndex = bottomCardArrayRef.findIndex(d => d.questionId === bottomCardId);

            attemptCountRef.current = attemptCountRef.current + 1;
            allAttempCountRef.current = allAttempCountRef.current + 1;

            handleProgressBar();

            // // get index to target current lottie
            let indexForCurrentLottie = topCardElement.getAttribute("id").split("-").pop()

            let currentTime = parseInt(timeRefText.current.innerHTML);

            if (dragItemRef.current?.dragItemAnswerObj?.answer.trim().toUpperCase() === topCardRef.current.topCardObj.answer.trim().toUpperCase()) {

                // add to the score
                totalScoreRef.current += marksPerAnswer;

                // set analytics data
                handleChallengeAttemptData({
                    qId: topCardId,
                    aId: topCardRef.current.topCardObj.answerId,
                    isCorrect: true,
                    t: prevAnsTimeRef.current - currentTime,
                    skillId: topCardRef.current.topCardObj.skillId,
                    contextId: topCardRef.current.topCardObj.contextId,
                });
                prevAnsTimeRef.current = currentTime;

                // lottieCorrectOrNot.current = false
                await sleep(bufferTime - bufferTime / 3)

                let currentLottie = correctLottieRefsArray.current[parseInt(indexForCurrentLottie)].current;
                currentLottie.play();

                // add transition to bottom card
                let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
                let oldText = bottomCardElementChildEl.innerHTML;

                // wait for correct animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + (bufferTime * 5));

                // wait for buffer time
                // await sleep(bufferTime);

                // hide dragged card
                dragElement.style.opacity = '0'
                setTranslate(0, 0, dragItemElementRef.current, true);

                // wait for buffer time and then show new cards
                await sleep(4 * bufferTime);

                // reset lottie player
                currentLottie.stop();
                currentLottie = null;

                if (isTrueFalse) {
                    bottomCardElementChildEl.innerHTML = oldText;
                    // dragElement.style.opacity = 1;

                    // NEW
                    topCardElement.style.opacity = 1;

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                    topCardElement.style.scale = 1;
                } else {
                    // update top card NEW
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, true);

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                }
            } else {
                qstnBoxRef.current.style.boxShadow = '0px 0px 50px #FF0A00'
                gradientRef.current.style.zIndex = 0;
                gradientRef.current.classList.add(classes.opacityAnimation);

                // set analytics data
                handleChallengeAttemptData({
                    qId: topCardId,
                    aId: topCardRef.current.topCardObj.answerId,
                    isCorrect: false,
                    t: prevAnsTimeRef.current - currentTime,
                    skillId: topCardRef.current.topCardObj.skillId,
                    contextId: topCardRef.current.topCardObj.contextId,
                });
                prevAnsTimeRef.current = currentTime;

                // // change lottie for wrong attempt
                // lottieAnimeDataRef.current = false
                setLottieAnimeData(false)

                //target and play lottie for incorrect attempt
                await sleep(bufferTime - bufferTime / 3)
                let currentLottie = wrongLottieRefsArray.current[parseInt(indexForCurrentLottie)].current;
                currentLottie.play();

                await sleep(bufferTime * 2)

                // // lifes decrement at wrong attempt
                // let life = parseInt(lifeRefText.current.innerHTML) - 1;
                // if (life === 1) {
                //     lifeBoxRef.current.classList.add(classes.mainText);
                // }
                // handleLifeLoss(life);

                await sleep(100)
                // add transition to bottom card
                let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
                let oldText = bottomCardElementChildEl.innerHTML;

                // wait for incorrect animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + (bufferTime * 5));

                // hide element at move it to initial position
                dragElement.style.opacity = '0'
                setTranslate(0, 0, dragItemElementRef.current, true);

                // wait for buffer time
                await sleep(2 * bufferTime);

                // reset lottie player
                currentLottie.stop();
                currentLottie = null;
                // setLottieAnimeData(true)

                // lifes decrement at wrong attempt
                let life = parseInt(lifeRefText.current.innerHTML) - 1;
                if (life === 1) {
                    lifeBoxRef.current.classList.add(classes.mainText);
                }
                handleLifeLoss(life);
                if (life === 0) return // if life becomes zero stop further code execution

                if (isTrueFalse) {
                    bottomCardElementChildEl.innerHTML = oldText;
                    // bottomCardElementChildEl.style.fontFamily = 'inherit';
                    // dragElement.style.backgroundImage = IMG_BUCKET_EMPTY;
                    // bottomCardElementChildEl.style.fontSize = null;
                    // dragElement.style.opacity = 1;

                    // NEW
                    topCardElement.style.opacity = 1;

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                    topCardElement.style.scale = 1;
                } else {
                    // update top card NEW
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, false);

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                }

                if (life !== 1) {
                    gradientRef.current.style.zIndex = -1;
                    gradientRef.current.classList.remove(classes.opacityAnimation);
                    qstnBoxRef.current.style.boxShadow = 'none'
                }
            }

            // setLottieAnimeData(null)
            // if (!isEmpty(dragItemElementRef.current) && correctAttemptCountPerContext < 4) dragItemElementRef.current.style = "";
            dragItemElementRef.current = null;

            await sleep(100)
            setLottieAnimeData(true)
            // lottieAnimeDataRef.current = true;
            // setLottieAnimeData(ROCKET_CORRECT_2)
            setDrag(100);


            if (attemptCountRef.current === 5) {
                questionRef.current.style.scale = '0';
                await sleep(2 * bufferTime);
                setShowCard(false);
                await sleep(bufferTime);

                // check if all questions are displayed
                let indexValue = activeIndex + 1;
                if (indexValue === questionData.length) {

                    submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: Math.ceil(totalScoreRef.current), completion: true, status: "COMPLETED", timespent: totalGBATimespent.current });
                    handleGbaState(GBA_SCREEN_STATE.WIN);
                    clearInterval(intervalRef.current);
                    clearInterval(totalGBATimespentIntervalRef.current);
                    setIsGameComplete(true);
                    setGameEndType('win');
                    return
                }
                setActiveIndex(indexValue);
                activeIndexRef.current = indexValue;
            }

            topCardChildEl.style.opacity = '1';
            topCardnDragReset(isDragActive, topCardArrayRef)
            return;
        }

        // when drag ended and not overlapping
        else {

            // // if card released without overlapping the top card
            setTranslate(0, 0, dragElement, true);

            await sleep(bufferTime)
            // if (!isEmpty(dragItemElementRef.current)) dragItemElementRef.current.style = ""; // remove style to make it draggable again

            // dragElement.style.transform = "";
            // dragElement.style.transition = "";

            dragItemElementRef.current = null;
            if (!isEmpty(dragItemChildEl)) dragItemChildEl.style.opacity = '1'

            setDrag(500);
            topCardnDragReset(isDragActive, topCardArrayRef)
            return;
        }
    }

    const topCardnDragReset = (isDragActive, topCardArrayRef) => {
        isDragActive.current = true;
        topCardArrayRef.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.questionId}-${i}`);
            if (isEmpty(topCardEl)) return
            topCardEl.style.scale = '1'
        });
    }

    const getNewCardDataFromRemianingArrayRef = (cardIndex, randomNoForTopCard, changeTopCard) => {
        let newCardData = null;
        newCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
        newCardData.isViewed = false;
        // if (changeTopCard) topCardArrayRef[cardIndex] = newCardData;
        // if (changeTopCard) setTopCardArrayRef([...topCardArrayRef,topCardArrayRef[cardIndex]= newCardData]);
        if (changeTopCard) setTopCardArrayRef(prev => {
            let previous = [...prev];
            previous[cardIndex] = newCardData;
            return previous
        })
        return newCardData
    }

    const handleTopCardUpdateArray = (topCardIndex, topCardElement, topCardId, forCorrectAnswer) => {
        // update the flag
        // topCardArrayRef.current[topCardIndex].isViewed = true;
        setTopCardArrayRef((prev) => {
            let previous = [...prev];
            previous[topCardIndex] = { ...previous[topCardIndex], isViewed: true }
            // [...topCardArrayRef, topCardArrayRef[topCardIndex] = {...topCardArrayRef[topCardIndex], isViewed: true}])
            return previous
        }
        )
        let newTopCardData = null;

        if (remainingTopCardArrayRef.current.length > 0) {
            // update top card if there is data in remaining array
            let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
            newTopCardData = getNewCardDataFromRemianingArrayRef(topCardIndex, randomNoForTopCard, true)

            // newTopCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
            // newTopCardData.isViewed = false;
            // topCardArrayRef.current[topCardIndex] = newTopCardData;

            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);

            // update the remainingTopCardArray
            if (forCorrectAnswer) remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
            else remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
        } else {
            // if the remaining array is empty just throw random card data

            // check if bottom card has only card then it should have options above to match
            if (attemptCountRef.current === questionData[activeIndex].totalOptions.length - 1) {

                let isCardMatch = false;
                let bottomCardShowing = bottomCardArrayRef.filter(d => !d.isViewed);
                topCardArrayRef.filter(d => !d.isViewed).forEach((top) => {
                    let i = bottomCardShowing.findIndex(d => d.questionId === top.questionId);
                    if (i != -1) {
                        isCardMatch = true;
                    }
                });
                if (!isCardMatch && !isEmpty(newTopCardData)) {
                    // show the top card that matches the last bottom card;
                    newTopCardData = bottomCardShowing[bottomCardShowing.length - 1];
                    newTopCardData['isViewed'] = false;
                    // topCardArrayRef[topCardIndex] = newTopCardData;
                    setTopCardArrayRef((prev) => {
                        let previous = [...prev];
                        previous[topCardIndex] = newTopCardData
                        return previous;
                    });
                    handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
                    return
                }
            }

            let splicedTopCardArray = [...topCardArrayRef];
            splicedTopCardArray.splice(topCardIndex, 1)
            let excludedTopIndices = [];

            splicedTopCardArray.forEach((top) => {
                let i = questionData[activeIndex].totalOptions.findIndex(d => d.questionId === top.questionId);
                if (i != -1) {
                    excludedTopIndices.push(i);
                }
            });
            let randomNoForTopCard = commonUtil.randomNo(0, questionData[activeIndex].totalOptions.length - 1, excludedTopIndices);
            newTopCardData = questionData[activeIndex].totalOptions[randomNoForTopCard];
            newTopCardData.isViewed = false;
            // topCardArrayRef.current[topCardIndex] = newTopCardData;
            // setTopCardArrayRef([...topCardArrayRef, topCardArrayRef[topCardIndex] = newTopCardData])
            setTopCardArrayRef((prev) => {
                let previous = [...prev];
                previous[topCardIndex] = newTopCardData;
                return previous
            });
            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
        }
    }

    const handleTopCardDomUpdate = (topCardEl, newDataToUpdate, topCardId, topCardIndex) => {
        let topCardElementChildEl = topCardEl.querySelector(`#top-text-${topCardId}`);
        topCardElementChildEl.innerHTML = newDataToUpdate.answer;
        // topCardElementChildEl.innerHTML = newDataToUpdate.question;
        topCardEl.setAttribute('id', `top-${newDataToUpdate.questionId}-${topCardIndex}`);
        topCardElementChildEl.setAttribute('id', `top-text-${newDataToUpdate.questionId}`);
        topCardEl.style.scale = 1;
        topCardEl.style.opacity = 1;
        topCardElementChildEl.style.opacity = 1;
    }

    const handleBottomCardUpdateArray = (dragElement, bottomCardId, bottomCardIndex, forCorrectAnswer) => {

        if (attemptCountRef.current <= questionData[activeIndex].totalOptions.length - bottomCardCount && !isTrueFalse) {
            // update the flag
            // bottomCardArrayRef[bottomCardIndex < 0 ? 0 : bottomCardIndex].isViewed = true;
            setBottomCardArrayRef(prev => {
                const newState = [...prev];
                let index = bottomCardIndex < 0 ? 0 : bottomCardIndex;
                newState[index] = { ...newState[index], isViewed: true };
                return newState;
            }
            )

            let topCardArrayCopy = [...topCardArrayRef];
            let bottomCardArraySplicedCopy = [...bottomCardArrayRef];
            bottomCardArraySplicedCopy.splice(bottomCardIndex, 1);

            // find the index from top card which matches bottom card
            let excludedBottomIndices = [];
            topCardArrayCopy.forEach((top, index) => {
                let i = bottomCardArraySplicedCopy.findIndex(d => d.questionId === top.questionId);
                if (i != -1) {
                    excludedBottomIndices.push(index);
                }
            });
            let randomNoForBottomCard = commonUtil.randomNo(0, topCardCount - 1, excludedBottomIndices);
            let newBottomCardData = topCardArrayCopy[randomNoForBottomCard];
            newBottomCardData.isViewed = false;
            // bottomCardArrayRef[bottomCardIndex] = newBottomCardData;
            setBottomCardArrayRef((prev) => {
                let previous = [...prev];
                previous[bottomCardIndex] = newBottomCardData;
                return previous;
            })
            handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);
        } else {
            if (remainingTopCardArrayRef.current.length > 0) {

                let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
                let newBottomCardData = getNewCardDataFromRemianingArrayRef(bottomCardIndex, randomNoForTopCard, false);
                newBottomCardData.isViewed = false;
                // bottomCardArrayRef.current[bottomCardIndex] = newBottomCardData;
                setBottomCardArrayRef((prev) => {
                    let previous = [...prev];
                    previous[bottomCardIndex] = newBottomCardData;
                    return previous;
                })
                handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);

                // update the remainingTopCardArray
                if (forCorrectAnswer) {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                }
                else {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                    // remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
                }
            }
        }
    }

    const handleBottomCardDomUpdate = async (dragElement, newBottomCardData, bottomCardId, bottomCardIndex) => {
        let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
        bottomCardElementChildEl.innerHTML = newBottomCardData.question;
        // bottomCardElementChildEl.innerHTML = newBottomCardData.answer;
        dragElement.setAttribute('id', `bottom-${newBottomCardData.questionId}-${bottomCardIndex}`);
        bottomCardElementChildEl.setAttribute('id', `bottom-text-${newBottomCardData.questionId}`);

        dragElement.style.opacity = 1;
        bottomCardElementChildEl.style.opacity = '1';

        await sleep(100)
        // dragElement.style.transition = ""
        // dragElement.style.transform = ""

    }

    const setTranslate = (xPos, yPos, el, isTransition = false, scale = '1') => {
        if (el === null) return
        el.style.transition = isTransition ? '0.8s' : '0s'
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale(" + scale + ")";
        return;
    }

    const isOverlapping = (e1, e2) => {
        if (isEmpty(e1) || isEmpty(e2)) return;
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        // Calculate the area of the rectangles
        const area1 = rect1.width * rect1.height;
        const area2 = rect2.width * rect2.height;

        // Calculate the overlapping rectangle
        const overlapLeft = Math.max(rect1.left, rect2.left);
        const overlapTop = Math.max(rect1.top, rect2.top);
        const overlapRight = Math.min(rect1.right, rect2.right);
        const overlapBottom = Math.min(rect1.bottom, rect2.bottom);

        // Calculate the area of the overlapping rectangle
        const overlapArea = Math.max(0, overlapRight - overlapLeft) * Math.max(0, overlapBottom - overlapTop);
        // const overlapArea = Math.min(0, overlapRight - overlapLeft) * Math.min(0, overlapBottom - overlapTop);

        // Calculate the percentage of overlap

        const overlapPercentage = (overlapArea / Math.min(area1, area2)) * 100;

        // Check if overlap is at least 45%
        const isOverlap = overlapPercentage >= 45;

        // Return true if there is overlap, false otherwise
        return isOverlap;
    }

    const restartGame = async () => {
        isDragActive.current = true;
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespent.current = 0;
        attemptCountRef.current = 0;
        allAttempCountRef.current = 0;
        totalScoreRef.current = 0;
        currentIndex.current = 0;
        dragItemElementRef.current = null;
        prevAnsTimeRef.current = maxTime; // to avoid negative time sending in payload
        getChallengeDataAsync()
        startTimer();
        setShowCard(true);
        setIsGameComplete(false);
        setActiveIndex(0);
        activeIndexRef.current = 0;
        setIsGamePage(false);
        await sleep(10);
        setIsGamePage(true);
        // lottieAnimeDataRef.current = true;
        setLottieAnimeData(true)
        finalChallengeDataRef.current = null;
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    // const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
    //     let tempQuestionObj = {
    //         ...questionObj,
    //         skillId: skillId,
    //         contextId: contextId,
    //     }
    //     let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
    //     if (isEmpty(finalChallengeDataRef.current)) {
    //         finalChallengeDataRef.current = {
    //             ...req,
    //             questions: [tempQuestionObj],
    //             completion: true,
    //             status: "COMPLETED",
    //             timespent: totalTimeSpent,
    //             scored: Math.ceil(totalScoreRef.current),
    //             fallbackSave: true,
    //         }
    //     } else {
    //         finalChallengeDataRef.current.questions.push(tempQuestionObj)
    //         finalChallengeDataRef.current = {
    //             ...finalChallengeDataRef.current,
    //             timespent: totalTimeSpent,
    //         }
    //     }
    // }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        const tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        // Ensure current challenlege data is initialized
        const currentChallengeData = finalChallengeDataRef.current || {};

        // Calculate total time spent
        const totalTimeSpent = (isEmpty(currentChallengeData) ? 0 : (currentChallengeData.timespent || 0)) + tempQuestionObj.timespent;

        if (isEmpty(currentChallengeData)) {
            // Initialize finalChallengeDataRef.current
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: Math.round(totalScoreRef.current),
                fallbackSave: true,
            };
        } else {
            // Use a new array for questions to avoid mutation
            const updatedQuestions = [...(currentChallengeData.questions || []), tempQuestionObj];
            finalChallengeDataRef.current = {
                ...currentChallengeData,
                questions: updatedQuestions,
                timespent: totalTimeSpent,
            };
        }
    }


    const handleProgressBar = () => {
        let width = (parseInt(allAttempCountRef.current) / questionsLength) * 100;
        progressBarRef.current.style.width = `${width}%`;
        // if (questionsLength >= currentIndex.current) {
        //     questionNoRef.current.innerHTML = currentIndex.current;
        // }
    }

    const handleLifeLoss = async (life) => {
        if (!isEmpty(lifeRefText.current)) lifeRefText.current.innerHTML = life;
        if (!isEmpty(containerRef.current)) containerRef.current.style.opacity = 0.5;

        if (life === 0) {
            await sleep(500)
            const submitEndChallengeAttemptData = await submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: Math.ceil(totalScoreRef.current), completion: false, status: "LIFELOSE", timespent: totalGBATimespent.current });
            setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

            handleGbaState(GBA_SCREEN_STATE.LOOSE);
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
            await sleep(2 * bufferTime);
            setShowCard(false);
            await sleep(bufferTime);
            setIsGameComplete(true);
            setGameEndType('loose');
            if (!isEmpty(gradientRef.current)) {
                gradientRef.current.style.zIndex = -1;
                gradientRef.current.classList.remove(classes.opacityAnimation);
            }
            // return;
        }
        if (life === 1) {
            lifeRef.current.style.color = palette.errorColor;
            lifeRefImg.current.src = "/images/icons/heart-red.png"
        }
    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={`${!isGamePage ? classes.popcornMatchRoot : `${classes.popcornMatchRoot} ${classes.gradientBg}`}`} ref={popcornMatchRootRef}>

            <div className={classes.gradientBox} ref={gradientRef} />
            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <div className={classes.startPage}>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    gradientBg={bgGradientDefault}
                                    isComingSoon={false}
                                    bg="/images/gba/cracker-mania-bg.png"
                                    gameLogo="/images/gba/cracker-mania-logo.png"
                                    limitedEdition={false}
                                    isColorSplash={false}
                                    handleGbaState={handleGbaState}
                                    startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                    setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                    startScreenGameBgLoaded={startScreenGameBgLoaded}
                                    setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                                />
                            </div>
                        ) : (
                            <HowToPlay
                                // headerBg={headerBg}
                                qstnBoxBg={'#000000'}
                                qstnImg={'/images/gba/popcorn_perfo_rd.png'}
                                answerImg={IMG_BUCKET_EMPTY}
                                animationImg={IMG_BUCKET_FULL}
                                headerColor='#a34735'
                                setIsGamePage={setIsGamePage}
                                gameType='diwaliDash' />
                        )
                    ) : (
                        !isGameComplete ? (
                            <div className={classes.gameContainer} ref={containerRef}>
                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} />
                                </div>

                                {/* QUESTION BOX */}

                                <div className={classes.qstnContainer}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeBoxRef}>
                                                <div className={classes.life} ref={lifeRef}>
                                                    <span ref={lifeRefText}>{maxLife}</span> x
                                                </div>
                                                <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} alt="heart" ref={lifeRefImg} />
                                            </div>
                                            <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{questionData.length}</div>
                                            <div className={classes.timerText}>
                                                <img src='/images/icons/stopwatch.png' className={`disableSelection disablePointerEvents ${classes.timerIcon}`} alt="timer" />
                                                <div className={classes.time}><span ref={timeRefText}>{maxTime}</span>s</div>
                                            </div>
                                        </div>
                                        <div className={classes.qstn} ref={questionRef}>{questionData[activeIndex].context}</div>
                                    </div>
                                </div>

                                <div className={classes.dragCardContainer} >
                                    {
                                        !isEmpty(topCardArrayRef) && !isEmpty(bottomCardArrayRef) && showCard && (
                                            <>
                                                <div className={`${classes.answerContainer} ${classes.heightTransition}`}>
                                                    {
                                                        topCardArrayRef.map((item, i) => (
                                                            <div className={`${classes.answer} `} key={item.questionId} >


                                                                <div className={`${classes.topCard}`}
                                                                    ref={topCardLottieRef}
                                                                    id={`top-${item.questionId}-${i}`}
                                                                    drag={'false'}
                                                                >

                                                                    {/* INITIAL + CORRECT ATTEMPT LOTTIE */}
                                                                    <div
                                                                        className={classes.topCardOverlay}
                                                                        // style={{ visibility: lottieAnimeDataRef.current ? 'visible' : 'hidden' }}
                                                                        style={{ visibility: lottieAnimeData ? 'visible' : 'hidden' }}
                                                                    // style={{ backgroundImage: `url(${IMG_BUCKET_EMPTY})` }}
                                                                    >
                                                                        <Lottie
                                                                            animationData={ROCKET_CORRECT_2}
                                                                            ref={correctLottieRefsArray.current[i]}
                                                                            loop={false}
                                                                            play={false}
                                                                        />
                                                                    </div>

                                                                    {/* WRONG ATTEMPT LOTTIE */}
                                                                    <div
                                                                        className={classes.topCardOverlay}
                                                                        // style={{ visibility: lottieAnimeDataRef.current ? 'hidden' : 'visible' }}
                                                                        style={{ visibility: lottieAnimeData ? 'hidden' : 'visible' }}
                                                                    >
                                                                        <Lottie
                                                                            animationData={ROCKET_WRONG_2}
                                                                            ref={wrongLottieRefsArray.current[i]}
                                                                            loop={false}
                                                                            play={false}
                                                                        />
                                                                    </div>
                                                                    <div className={classes.answerText}
                                                                        id={`top-text-${item.questionId}`}
                                                                    >
                                                                        {/* {item.question} */}
                                                                        {item.answer}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <div className={`${classes.questionContainer} ${classes.heightTransition}`}>
                                                    {
                                                        bottomCardArrayRef.map((item, i) => (
                                                            <div className={classes.question} key={item.questionId} >
                                                                <div className={classes.bottomCard}
                                                                    ref={bottomCardRef}
                                                                    id={`bottom-${item.questionId}-${i}`}
                                                                    drag={'true'}
                                                                    onMouseDown={dragStart}
                                                                    onMouseMove={drag}
                                                                    onMouseUp={dragEnd}
                                                                    onTouchStart={dragStart}
                                                                    onTouchMove={drag}
                                                                    onTouchEnd={dragEnd}
                                                                >
                                                                    <div className={`${classes.answerText} ${classes.questionText}`}
                                                                        id={`bottom-text-${item.questionId}`}
                                                                    >
                                                                        {/* {item.answer} */}
                                                                        {item.question}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={""}
                                            obtainedScore={Math.ceil(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                                {
                                    gameEndType === 'timeup' && (
                                        <GbaGameOverScreen
                                            type={"time"}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                            </>
                        )
                    )
                )
            }
        </div>
    )
};

export default CrackerMania;
