import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FreshChat from "react-freshchat";
import { isIOS, useDeviceSelectors } from "react-device-detect";

// import { MuiOtpInput } from 'mui-one-time-password-input'

// mui
import { LoadingButton } from "@mui/lab";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import theme from "../../theme";

// components
import DialogAlert from "../dialog-alert";
import OtpInput from "../otp-input";

// redux
import { requestOtp, verifyOtp } from "../../redux/auth/auth.api";
import { openToast, openDialogue } from "../../redux/common/common-slice";
import { ScreenChange } from "../../redux/event-tracking/event-tracking-slice";
import { submitUserLoginActivity } from "../../redux/events/events.api";

// Apis
import { submitUserActivityData } from "../../redux/event-tracking/event.api";

// utils
// import isEmpty from "../../utils/isEmpty";   
import commonUtil from "../../utils/commonUtil";

// styles
import useStyles from "./index.styles"

// constants
import constants from "../../constants";
import isEmpty from "../../utils/isEmpty";

const OtpLogin = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const tokens = useSelector(state => state.auth.tokens);
    const userActivityData = useSelector(state => state.event);
    const isNativeApp = useSelector(state => state?.common?.isNativeApp);
    const deviceDetails = useSelector(state => state?.commonPersist?.deviceDetails);
    const otpLength = 6;
    const firstRenderRef = useRef(true);
    // const [showOtpBox, setShowOtpBox] = useState(state?.showOtpPage || false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [phone, setPhone] = useState(null);
    const [showOtp, setShowOtp] = useState(state?.MFA_enabled ? true : false);
    const [otp, setOtp] = useState('');
    const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    const [selectedCountryFlag, setSelectedCountryFlag] = useState('in');
    const year = new Date().getFullYear();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('Please enter username/password before continuing');
    const [showFreshChat, setShowFreshChat] = useState(false);
    const [loginOtpLoading, setLoginOtpLoading] = useState(false);
    const [otpHeaderText, setOtpHeaderText] = useState(state?.otpHeaderText ? state?.otpHeaderText : 'Phone Number');
    const [isEmailId] = useState(state?.isChangeEmailId || false);
    const chatRef = useRef();
    const [selectors, deviceData] = useDeviceSelectors(window.navigator.userAgent);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    // console.log("otpHeaderText", otpHeaderText);
    // console.log("showOtp", showOtp);

    var freshchatWidget;

    useEffect(() => {
        // Callback function to handle mutations
        const handleMutation = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    // Fire your event when any DOM element is added
                    // console.log('DOM element added:', mutation.target);
                }
            }
        };

        // Create a new observer instance
        const observer = new MutationObserver(handleMutation);

        // Start observing the document body for changes
        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, []);

    // useEffect(() => {
    //     if ("OTPCredential" in window) {
    //         const ac = new AbortController();
    //         navigator.credentials
    //             .get({
    //                 otp: { transport: ["sms"] },
    //                 signal: ac.signal
    //             })
    //             .then((otp) => {
    //                 // this.setState({ otp: otp.code });
    //                 console.log("otp:", JSON.stringify(otp))
    //                 window.alert(otp.code);
    //                 ac.abort();
    //             })
    //             .catch((err) => {
    //                 ac.abort();
    //                 console.log(err);
    //             });
    //     }
    // }, [otp]);

    useEffect(() => {
        // window.fcWidgetMessengerConfig = {
        //     config: {
        //         headerProperty: {
        //             hideChatButton: true,
        //         }
        //     }
        // }
        // console.log("freshchat clickeds");
        if (showFreshChat) {
            document.getElementById('fc_frame').style.display = 'block'
            // document.getElementById('fc_frame').style.paddingTop = isIOS ? constants.HEADER_TOP_PADDING : '0'   // to resolve conflict with native header in ios devices

            window.fcWidget.open();
            window.fcWidget.show();
        }
        else {
            try {
                if (document.getElementById('fc_frame') === null) return
                document.getElementById('fc_frame').style.display = 'block'
                window.fcWidget.close();
            } catch (err) {
                console.log(err);
            }
        }
    }, [showFreshChat])

    useEffect(() => {
        if (firstRenderRef.current) {
            // dispatch(ScreenChange({ oldScreen: 'LOGIN', currentScreen: 'OTP LOGIN', nextScreen: 'LEARN', activityType: "LOGIN_OTP", activityData: { screen: 'OTP Login screen' } }))
            firstRenderRef.current = !firstRenderRef.current;
        }
        else {
            // submitUserActivityData(userActivityData);
        }
    }, [userActivityData]);

    const handleCountryChange = (e) => {
        let countryObj = commonUtil.getCountryCodeByDialCode(e.target.value);
        setSelectedCountryCode(e.target.value)
        setSelectedCountryFlag(countryObj.code.toLowerCase())
    }

    const handleRequestOtpClick = async (e) => {
        e.preventDefault();
        if (isEmpty(phone)) {
            setIsDialogOpen(true);
            setDialogContent("Please provide your mobile number to generate OTP request.");
            return;
        }
        if (phone.length < 10) {
            setIsDialogOpen(true);
            setDialogContent("Please enter a valid mobile number");
            return;
        }
        setBtnLoading(true);
        let req = null;
        if (otpHeaderText === 'Email') {
            req = {
                email: phone,
                provider: 'email'
            }
        } else {
            req = {
                phone: phone,
                provider: 'phone'
            }
        }

        const respData = await requestOtp(req);
        if (respData.status === 200 || respData.status === 201) {
            setShowOtp(true);
            setBtnLoading(false);
        } else if (respData.status === 400) {
            const device = window.isNative === undefined || isNativeApp === false ? selectors.osName : deviceDetails.os;
            const details = window.isNative === undefined || isNativeApp === false ? commonUtil.getDeviceDetails(selectors, respData?.data?.errors) : {};
            respData.errors = respData?.data?.errors;
            submitUserLoginActivity({ username: phone, device: device, screen: 'LOGIN_OTP', details });
            dispatch(openDialogue({
                isDialogueOpen: true,
                dialogueTitle: '',
                dialogueContent: respData.data?.errors[0].message
            }));
            setBtnLoading(false);
        } else {
            const device = window.isNative === undefined || isNativeApp === false ? selectors.osName : deviceDetails.os;
            const details = window.isNative === undefined || isNativeApp === false ? commonUtil.getDeviceDetails(selectors, respData?.data?.errors) : {};
            details.errors = respData?.data?.errors;
            submitUserLoginActivity({ username: phone, device: device, screen: 'LOGIN_OTP', details });
            openToast({ isToastOpen: true, toastMessage: 'Something went wrong', toastSeverity: 'error' })
        }
    }

    const handleSubmitOtpClick = async (e) => {
        e.preventDefault();

        if (isEmpty(otp)) {
            setIsDialogOpen(true);
            setDialogContent("Please enter your otp");
            return;
        }

        if (otp.length < 6 || otp.length > 6) {
            setIsDialogOpen(true);
            setDialogContent("Please enter valid OTP");
            return;
        }

        setLoginOtpLoading(true);
        let req = null

        if (otpHeaderText === 'Email') {
            req = {
                email: phone,
                provider: 'email',
                otp
            }
        } else {
            req = {
                mobile: phone,
                provider: 'phone',
                otp
            }
        }

        const MFA_enabled = state?.MFA_enabled;
        if (MFA_enabled) {
            req.requestToken = tokens.requestToken;
            req.MFA = true;
            req.provider = state?.otpHeaderText
            delete req.email;
            delete req.mobile;
        }
        const respData = await verifyOtp(req);

        if (respData.status === 200 || respData.status === 201) {
            await submitUserActivityData({ oldScreen: 'LOGIN', currentScreen: 'OTP LOGIN', nextScreen: 'LEARN', activityType: 'SCREEN_CHANGE', activityData: { screen: 'OTP Login screen' } })

        } else {
            // if (respData.status !== 200) {
            const device = window.isNative === undefined || isNativeApp === false ? selectors.osName : deviceDetails.os;
            const details = window.isNative === undefined || isNativeApp === false ? commonUtil.getDeviceDetails(selectors, respData?.data?.errors) : {};
            respData.errors = respData?.data?.errors;
            details.otp = otp;
            submitUserLoginActivity({ username: phone, device: device, screen: 'LOGIN_OTP_SUBMIT', details });
            setLoginOtpLoading(false);
            dispatch(openDialogue({
                isDialogueOpen: true,
                dialogueTitle: '',
                dialogueContent: "Incorrect OTP entered."
            }));
        }
        // else {
        // }
    }

    const handleChatWithUs = () => {
        setShowFreshChat(true)
    }

    const handleOtpProviderToggle = () => {
        // setPhone(1);
        if (otpHeaderText === 'Email') {
            setOtpHeaderText('Phone Number');
        } else {
            setOtpHeaderText('Email');
        }
    }

    const _handleChangePhoneNumber = () => {
        setPhone(null);
        setShowOtp(false);
    }

    return (
        <div className={`${classes.loginContainer} ${classes.centerFlex}`}>
            {/* Desktop */}
            <FreshChat
                ref={chatRef}
                token="3d0017c5-0a60-4693-bdae-3def0407cf94"
                // widgetUuid="25380827-750a-40ae-aca5-8f2a3355f15c"
                onInit={(widget) => {
                    freshchatWidget = widget;
                    widget.on("widget:closed", function () {
                        // console.log("widget was closed");
                        document.getElementById("fc_frame").style.display = "block";
                        document.getElementById("fc_frame").style.paddingTop = "0rem";
                        document.getElementById("fc_frame").style.paddingBottom = "0rem";
                    });
                }}
            />
            {!isMobile && (
                <div className={`${classes.loginBox} ${classes.centerFlex}`}>
                    {!isTablet && (
                        <div className={classes.side1}>
                            <div className={classes.imageContainer}>
                                <img src="/images/login-screen/login_illustration.png" alt="master-o illustration" className={classes.coverImage} />
                                <div className={classes.iconBox}>
                                    <div className={classes.circleLogoBox}>
                                        <img src="/images/login-screen/new_mastero_login.png" className={classes.circleLogoImage} alt="master-o loading icon" />
                                    </div>
                                    <div className={classes.iconTextBox}>
                                        {/* <span className={classes.logoMaintext}>Master-O</span>
                                        <span className={classes.logoSubText}>Improve Skills Effortlessly</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.loginContentBox}>
                                <div className={classes.welcomeText}>Welcome to</div>
                                <div className={classes.logoText}>Master-O</div>
                                <div className={classes.divider} />
                                <div className={classes.mainTextDesc}>Improve job performance by learning new Microskills. Learn on the go using microlearning and gamification. Compete and contrast your learning performance with your peers.</div>
                            </div>
                        </div>
                    )}

                    <div className={classes.side2}>

                        {!showOtp && <div className={classes.toggleSwitchBox} style={{ marginBottom: '0px' }}>
                            <ToggleButtonGroup
                                value={otpHeaderText}
                                exclusive
                                onChange={(event) => {
                                    const newValue = event.currentTarget.value;

                                    if (newValue !== otpHeaderText) {
                                        setOtpHeaderText(newValue);
                                    } else {
                                        setOtpHeaderText(otpHeaderText);
                                    }
                                }}
                                style={{ borderRadius: '2px', overflow: 'hidden', height: '2rem' }}
                            >
                                <ToggleButton
                                    value="Phone Number"
                                    style={{
                                        color: otpHeaderText === 'Phone Number' ? 'white' : 'black',
                                        backgroundColor: otpHeaderText === 'Phone Number' ? '#F4511E' : 'transparent',
                                        border: '2px solid #F4511E',
                                        borderRadius: '5px 0px 0px 5px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    P<span style={{ textTransform: 'lowercase' }}>hone</span>
                                </ToggleButton>
                                <ToggleButton
                                    value="Email"
                                    style={{
                                        color: otpHeaderText === 'Email' ? 'white' : 'black',
                                        backgroundColor: otpHeaderText === 'Email' ? '#F4511E' : 'transparent',
                                        border: '2px solid #F4511E',
                                        borderRadius: '0px 5px 5px 0px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    E<span style={{ textTransform: 'lowercase' }}>mail</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>}



                        {!showOtp && <div className={classes.signInMainText}>Enter {otpHeaderText}</div>}

                        {/* <div className={classes.signInDetails}>Enter your phone number and request OTP to access your Master-O Microskills, Learning Documents and more!</div> */}
                        {
                            !showOtp ?
                                (
                                    <form>
                                        <div>
                                            {/* <label className={classes.labelClass}>Enter your Phone Number below:</label> */}
                                            {
                                                otpHeaderText === 'Phone Number' ? (
                                                    <Input
                                                        fullWidth
                                                        type="number"
                                                        size="large"
                                                        className={classes.numberInputBox}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                        }}
                                                        startAdornment={
                                                            <InputAdornment position="start" className={classes.inputAdorment}>
                                                                <FormControl variant="standard" className={classes.formControl}>
                                                                    <InputLabel className={classes.flagLabel}>
                                                                        <img src={`images/flags/${selectedCountryFlag}.svg`} width={12} alt={`${selectedCountryFlag}`} />
                                                                    </InputLabel>
                                                                    <Select
                                                                        className={classes.selectBox}
                                                                        value={selectedCountryCode}
                                                                        onChange={handleCountryChange}
                                                                        disabled={true}
                                                                    >
                                                                        {
                                                                            constants.COUNTRIES_LIST.map((item, index) => (
                                                                                <MenuItem value={item.dial_code} key={item.code}> {item.code} {item.dial_code}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                ) : (
                                                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                                                        <OutlinedInput
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            placeholder="Email"
                                                            type="email"
                                                            startAdornment={<InputAdornment position="start">
                                                                <img
                                                                    src="/images/login-screen/mail.png"
                                                                    width="40%"
                                                                    style={{ marginLeft: "0.9rem" }}
                                                                />
                                                            </InputAdornment>}
                                                        />
                                                    </FormControl>
                                                )
                                            }
                                        </div>

                                        <div className={classes.btnContainer}>
                                            <LoadingButton
                                                style={{ fontSize: '1rem', fontWeight: 'bold' }}
                                                type="submit"
                                                fullWidth
                                                onClick={handleRequestOtpClick}
                                                loading={btnLoading}
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                Request OTP
                                            </LoadingButton>

                                            <LoadingButton
                                                style={{ backgroundColor: '#fff', padding: '8px 22px', fontWeight: 'bold !important', border: '1px solid #f4511e', color: "#f4511E", fontWeight: '1rem', marginTop: '0.5rem' }}
                                                type="submit"
                                                fullWidth
                                                onClick={() => navigate('/login', { state: { redirectedFrom: "otp-login" } })}
                                                // loading={btnLoading}
                                                disableElevation
                                                variant="outline"
                                                color="primary"
                                                size="large"
                                                sx={theme => ({
                                                    border: "1px solid #F4511E",
                                                })}
                                            >
                                                Login with User ID / Password
                                            </LoadingButton>
                                        </div>
                                    </form>
                                ) : (
                                    <form>
                                        <div>

                                            {showOtp && <div className={classes.otpHeader} style={{ marginBottom: '1rem' }}>

                                                {isEmailId && <label className={` ${classes.cursorPointer}`}
                                                    style={{ textDecoration: 'underline' }}
                                                    onClick={_handleChangePhoneNumber}
                                                >
                                                    {otpHeaderText === 'Phone Number' ? 'Change Phone Number' : 'Change Email ID'}
                                                </label>}

                                            </div>}


                                            <div className={classes.otpBoxDesktop}>
                                                <OtpInput otpLength={otpLength} setOtp={setOtp} otp={otp.toString().split('')} />
                                            </div>
                                        </div>

                                        <div className={classes.btnContainer}>

                                            <LoadingButton
                                                style={{ borderRadius: '0.1rem', padding: "8px 22px", backgroundColor: '#F4511E', fontWeight: 'bold', fontSize: '1rem', borderRadius: '0rem' }}
                                                type="submit"
                                                fullWidth
                                                onClick={handleSubmitOtpClick}
                                                loading={loginOtpLoading}
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                Submit OTP
                                            </LoadingButton>

                                            <LoadingButton
                                                style={{ backgroundColor: '#fff', borderRadius: '0rem', padding: '8px 22px', fontWeight: 'bold !important', border: '1px solid #f4511e', color: "#f4511E", fontSize: '1rem', marginTop: '0.5rem' }}
                                                type="submit"
                                                fullWidth
                                                onClick={() => navigate('/login', { state: { redirectedFrom: 'otp-login' } })}
                                                loading={btnLoading}
                                                disableElevation
                                                variant="outline"
                                                color="primary"
                                                size="large"
                                                sx={theme => ({
                                                    border: "1px solid #F4511E",
                                                })}
                                            >
                                                Login with User ID / Password
                                            </LoadingButton>
                                        </div>
                                    </form>
                                )
                        }
                    </div>
                </div>
            )}

            {/* Mobile */}
            {isMobile && (
                <div className={classes.mobileLoginContainer}>
                    <div className={classes.mobileLoginBox}>
                        <div className={classes.mobileLogoContainer}>
                            <img src="/images/login-screen/login_logo.svg" alt="logo" />
                        </div>

                        <div className={classes.mobileLoginForm}>
                            {
                                !showOtp ? (
                                    <>
                                        <form className={classes.loginFormBox}>
                                            <div>

                                                <div className={classes.toggleSwitchBox}>
                                                    <ToggleButtonGroup
                                                        value={otpHeaderText}
                                                        exclusive
                                                        onChange={(event) => {
                                                            const newValue = event.currentTarget.value;

                                                            if (newValue !== otpHeaderText) {
                                                                setOtpHeaderText(newValue);
                                                            } else {
                                                                setOtpHeaderText(otpHeaderText);
                                                            }
                                                        }}
                                                        style={{ borderRadius: '2px', overflow: 'hidden', height: '2rem' }}
                                                    >
                                                        <ToggleButton
                                                            value="Phone Number"
                                                            style={{
                                                                color: 'white',
                                                                backgroundColor: otpHeaderText === 'Phone Number' ? '#F4511E' : 'transparent',
                                                                border: '2px solid #F4511E',
                                                                borderRadius: '5px 0px 0px 5px',
                                                            }}
                                                        >
                                                            P<span style={{ textTransform: 'lowercase' }}>hone</span>
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="Email"
                                                            style={{
                                                                color: 'white',
                                                                backgroundColor: otpHeaderText === 'Email' ? '#F4511E' : 'transparent',
                                                                border: '2px solid #F4511E',
                                                                borderRadius: '0px 5px 5px 0px',
                                                            }}
                                                        >
                                                            E<span style={{ textTransform: 'lowercase' }}>mail</span>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>

                                                {/* <label className={classes.labelClass}>{otpHeaderText}:</label> */}
                                                {
                                                    otpHeaderText === 'Phone Number' ? (

                                                        <FormControl fullWidth variant="filled" className={classes.mobileInputBox}>
                                                            <OutlinedInput
                                                                type="number"
                                                                className={classes.mobileInput}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                }}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <FormControl variant="standard" className={classes.formControl}>
                                                                            <InputLabel className={classes.flagLabel}>
                                                                                <img src={`images/flags/${selectedCountryFlag}.svg`} width={12} alt={`${selectedCountryFlag}`} />
                                                                            </InputLabel>
                                                                            <Select
                                                                                className={classes.selectBox}
                                                                                value={selectedCountryCode}
                                                                                onChange={handleCountryChange}
                                                                                disabled={true}
                                                                            >
                                                                                {
                                                                                    constants.COUNTRIES_LIST.map((item, index) => (
                                                                                        <MenuItem value={item.dial_code} key={item.code}> {item.code} {item.dial_code}</MenuItem>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                        <FormControl fullWidth className={classes.mobileLoginInputField} variant="outlined">
                                                            <OutlinedInput
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                placeholder="Email"
                                                                type="email"
                                                                className={classes.mobileInput}
                                                                startAdornment={<InputAdornment position="start" >
                                                                    <img
                                                                        src={`/images/login-screen/mail.png`}
                                                                        alt="login-screen-image"
                                                                        width="40%"
                                                                        style={{ marginLeft: "0.9rem" }}
                                                                    />
                                                                </InputAdornment>}
                                                            />
                                                        </FormControl>
                                                    )
                                                }
                                            </div>
                                            <div className={classes.btnContainer}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    fullWidth
                                                    size="large"
                                                    style={{ fontSize: '1rem', fontWeight: 'bold', borderRadius: '5px', padding: '10px' }}
                                                    loading={btnLoading}
                                                    onClick={handleRequestOtpClick}
                                                    disableElevation
                                                    className={classes.requestOtpBtn}
                                                >
                                                    Request OTP
                                                </LoadingButton>

                                                {/* <LoadingButton
                                                    className={classes.mobileOtpLoadingButton}
                                                    // type="submit"
                                                    fullWidth
                                                    onClick={handleOtpProviderToggle}
                                                    // loading={btnLoading}
                                                    disableElevation
                                                    variant="outline"
                                                    color="primary"
                                                    size="large"
                                                    sx={theme => ({
                                                        border: "1px solid #F4511E",
                                                    })}
                                                >
                                                    {otpHeaderText === 'Email' ? 'Phone OTP' : 'Email OTP'}
                                                </LoadingButton> */}


                                                <LoadingButton
                                                    // type="submit"
                                                    className={classes.mobileOtpLoadingButton}
                                                    fullWidth
                                                    onClick={() => navigate('/login', { state: { redirectedFrom: 'otp-login' } })}
                                                    disableElevation
                                                    variant="outline"
                                                    color="primary"
                                                    size="large"
                                                    style={{ fontSize: '1rem', fontWeight: 'bold', borderRadius: '5px' }}
                                                    sx={theme => ({
                                                        border: "1px solid #F4511E",
                                                    })}
                                                >
                                                    Login with User ID / Password
                                                </LoadingButton>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    <form>
                                        <div>
                                            <div className={classes.otpHeader} style={{ marginBottom: "0.5rem" }}>

                                                {isEmailId && <label className={`${classes.labelClass} ${classes.cursorPointer}`}
                                                    style={{ textAlign: 'right', textDecoration: 'underline' }}
                                                    onClick={_handleChangePhoneNumber}
                                                >
                                                    {otpHeaderText === 'Phone Number' ? 'Change Phone Number' : 'Change Email ID'}
                                                </label>}

                                            </div>
                                            <div className={classes.otpBoxDesktop}>
                                                <OtpInput otpLength={otpLength} setOtp={setOtp} otp={otp.toString().split('')} />
                                            </div>
                                        </div>

                                        <div className={classes.btnContainer}>
                                            <LoadingButton
                                                style={{ borderRadius: '0.1rem', padding: "8px 22px", backgroundColor: '#F4511E', fontWeight: 'bold', fontSize: '1rem ' }}
                                                type="submit"
                                                fullWidth
                                                onClick={handleSubmitOtpClick}
                                                loading={loginOtpLoading}
                                                disableElevation
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                            >
                                                Submit OTP
                                            </LoadingButton>
                                            <LoadingButton
                                                // type="submit"
                                                className={classes.mobileOtpLoadingButton}
                                                fullWidth
                                                onClick={() => navigate('/login', { state: { redirectedFrom: 'otp-login' } })}
                                                disableElevation
                                                variant="outline"
                                                color="primary"
                                                size="large"
                                                sx={theme => ({
                                                    border: "1px solid #F4511E",
                                                })}
                                            >
                                                Login with User ID / Password
                                            </LoadingButton>
                                        </div>
                                    </form>
                                )
                            }

                            <div className={`${classes.textBoxMobileOtp}`}>
                                <div className={`${classes.loginChatBox}`}>
                                    {/* <div className={`${classes.text}`} onClick={() => navigate('/login')}><u>Login via Username/Password</u></div> */}
                                    {/* <div className={`${classes.text}`}><u>Chat with us</u></div> */}
                                </div>
                                {/* <div className={`${classes.text} ${classes.copyright}`}>&copy; {year} InspireOne Technologies Pvt. Ltd. All rights reserved.</div> */}
                            </div>

                        </div>
                    </div>
                </div>
            )}

            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={'Okay'}
                        handleConfirmBtnClick={() => setIsDialogOpen(false)}
                    />
                )
            }
        </div >
    )
}

export default OtpLogin;