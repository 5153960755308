import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducers
import authReducer from './auth/auth-slice';
import commonReducer from './common/common-slice';
import spotlightReducer from './spotlight/spotlight-slice';
import gbaReducer from './gba/gba-slice';
import microskillReducer from './microskill/microskill-slice';
import eventReducer from './event-tracking/event-tracking-slice';
import commonPersistReducer from './common-persist/common-persist-slice';
import loginEventReducer from './events/events-slice';
import formReducer from './form/form-slice'


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'microskill', 'commonPersist']
};

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
    spotlight: spotlightReducer,
    gba: gbaReducer,
    microskill: microskillReducer,
    event: eventReducer,
    commonPersist: commonPersistReducer,
    loginEvent: loginEventReducer,
    forms: formReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;