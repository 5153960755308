/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import Lottie from 'react-lottie-player';

// mui
import { Button, CircularProgress, Fade } from "@mui/material";

// components
// import GbaHeader from "../header";

// utils
import isEmpty from "../../../../utils/isEmpty";
import commonUtil from "../../../../utils/commonUtil";
import utilityFunctions from "../../../../utils/utilityFunctions";

// lottie assest
import trophyLottie from "../../../../lottie-assets/win_chalice_mastery.json"

// Style
import useStyle from "./index.styles";
import { useTranslation } from "react-i18next";
import LaVoiceOverAudioPlayer from "../../../la-voiceover-audio-player";
import { handleGbaScreenState } from "../../../../redux/gba/gba-slice";
import constants from "../../../../constants";
import palette from "../../../../theme/palette";

// const star_empty = '/images/gba/star_empty.png';
// const star_full = '/images/gba/star_full.png';
const star_empty = '/images/gba/common/star_empty.svg';
const star_full = '/images/gba/common/star_full.svg';

const GbaWinScreen = (props) => {

    const {
        maxScore,
        obtainedScore,
        bg,
        totalGoals,
        gbaDesignType
    } = props;
    const { microskillId, challengeId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');
    const learnerJourneyEnded = useSelector(state => state?.common?.learnerJourneyEnded);
    let stars = commonUtil.getStarValue(maxScore, obtainedScore);
    let animationData = trophyLottie;
    let mastery = obtainedScore === maxScore;
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const gradientOverlay = useRef(null);
    const compltTextBoxRef = useRef(null);
    const section1Ref = useRef(null);
    const section1ContainerRef = useRef(null);
    const achivementTextRef = useRef(null);
    const masteryRef = useRef(null);
    const star1Ref = useRef(null);
    const star2Ref = useRef(null);
    const star3Ref = useRef(null);
    const rect1Child1Ref = useRef(null);
    const rect1Child2Ref = useRef(null);
    const rect2ChildRef = useRef(null);
    const challengeListBtnBoxRef = useRef(null);
    const nextChallengeBtnBoxRef = useRef(null);
    const [playAnimation, setIsPlayAnimation] = useState(false);
    const [nextChallengeData, setNextChallengeData] = useState({});
    const [showNextChallengeBtn, setShowNextChallengeBtn] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [isChallengeDataLoading, setIsChallengeDataLoading] = useState(true);

    const { getChallengeListData, handleChallengeListCardClick } = utilityFunctions;
    const { t, i18n } = useTranslation()
    const classes = useStyle(i18n);
    let searchParam = isEmpty(location.search.split('?')[1]) ? '' : `&${location.search.split('?')[1]}`

    // let bgGradient = !isEmpty(defaultGradient) ? defaultGradient : `linear-gradient(#272626, #04a404)`;
    // const deviceOs = useSelector((state) => state.challangeList.deviceOs);
    // const bgImage = bg.includes('tikkiMonkey') ? `${bgGradient}, url(${bg})` : `${bgGradient} ${!isEmpty(bg) ? `url(${bg})` : ''}`;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        let isMounted = true; // Prevent memory leaks if the component unmounts during animations
        if (isEmpty(section1ContainerRef.current)) return;

        const animateSequence = async () => {
            // if (!gradientOverlay.current) return;
            // gradientOverlay.current.classList.add(classes.gradientOverlayAnimation);

            await sleep(1500);
            if (isMounted && section1Ref.current) section1Ref.current.classList.add(classes.section1Animation);

            await sleep(800);
            if (isMounted && section1ContainerRef.current) {
                section1ContainerRef.current.classList.add(classes.section1ContainerAnimation);
            }

            await sleep(800);
            if (isMounted && achivementTextRef.current) achivementTextRef.current.style.opacity = '1';
            if (isMounted && masteryRef.current) masteryRef.current.style.opacity = '1';

            await sleep(800);

            // Star 1 Animation
            if (stars >= 1 && isMounted) {
                requestAnimationFrame(() => {
                    if (!star1Ref.current) return;
                    star1Ref.current.src = star_full;
                    star1Ref.current.classList.add(classes.star1);
                    rect1Child1Ref.current.style.width = "50%";
                });
            }

            // Star 2 Animation
            if (stars >= 2 && isMounted) {
                await sleep(800); // Delay between stars
                requestAnimationFrame(() => {
                    if (!star2Ref.current) return;
                    star2Ref.current.src = star_full;
                    star2Ref.current.classList.add(classes.star2);
                    rect1Child2Ref.current.style.width = "100%";
                });
            }

            // Star 3 Animation
            if (stars >= 3 && isMounted) {
                await sleep(800); // Delay between stars
                requestAnimationFrame(() => {
                    if (!star3Ref.current) return;
                    star3Ref.current.src = star_full;
                    star3Ref.current.classList.add(classes.star3);
                    rect2ChildRef.current.style.width = "100%";
                });
            }
        };

        animateSequence();

        return () => {
            isMounted = false; // Cleanup on unmount
        };
    }, [section1ContainerRef.current]);


    useEffect(() => {
        let time = new Date();
        let totalTimeSpent = time.getTime();
        // dispatch(challengeAnswers(
        //     deviceOs,
        //     totalTimeSpent,
        //     obtainedScore,
        //     'success',
        //     questions,
        //     isEmpty(totalTimeSpentOnQuestions) ? totalTimeSpent : totalTimeSpentOnQuestions,
        // ));
    }, []);

    const onScoreCounterStart = () => {
        // console.log("started")
    }

    // const handleScoreCounterEnd = () => {
    //     compltTextBoxRef?.current?.classList?.add(classes.compltTextBoxAnimation);
    //     compltTextBoxRef?.current?.style?.transform = 'translateY(0)';
    //     challengeListBtnBoxRef.current.classList.add(classes.bouncingAnimation);
    //     // nextChallengeBtnBoxRef.current.classList.add(classes.bouncingAnimation);
    //     setIsPlayAnimation(true);
    // }


    const handleScoreCounterEnd = () => {
        const compltTextBox = compltTextBoxRef?.current;
        if (compltTextBox) {
            compltTextBox.classList.add(classes.compltTextBoxAnimation);
            // compltTextBox.style.transform = 'translateY(0)';
        }

        if (challengeListBtnBoxRef.current) {
            challengeListBtnBoxRef.current.classList.add(classes.bouncingAnimation);
        }

        // Uncomment if needed
        // if (nextChallengeBtnBoxRef.current) {
        //     nextChallengeBtnBoxRef.current.classList.add(classes.bouncingAnimation);
        // }

        setIsPlayAnimation(true);
    };

    const handleChallengeListClick = () => {
        navigate(`/challenge-list/${microskillId}`);
    };

    useEffect(() => {
        setIsChallengeDataLoading(true);
        getChallengeListData(microskillId,
            challengeId,
            setNextChallengeData,
            setShowNextChallengeBtn,
            setShowCertificate);
        setIsChallengeDataLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microskillId]);

    const handleNextBtnClick = () => {
        dispatch(handleGbaScreenState(constants.GBA_SCREEN_STATE.START));
        handleChallengeListCardClick(nextChallengeData, navigate, microskillId);
    };

    const handleCertificateClick = () => {
        if (!showCertificate) return;
        navigate(`/challenge-list/${microskillId}/certificate`);
    }

    const ActionButton = ({ variant = "contained", color = "gbabtn", bg = palette.yellow, btnText, handleBtnClick }) => {

        return (
            <Button variant='contained'
                ref={challengeListBtnBoxRef}
                fullWidth
                size="small"
                color="gbabtn"
                className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                sx={{
                    fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                    fontWeight: '500',
                    backgroundColor: bg,
                    color: { color },
                }}
                onClick={handleBtnClick} >
                {t(btnText)}
            </Button>
        );
    };


    return (
        <div className={classes.winScreenRoot}>
            <div className={classes.audioPlayerContainer}>
                <LaVoiceOverAudioPlayer voiceOverTrack={"/audio/GBA-win.mp3"} autoPlay={true} loop={false} />
            </div>
            <div className={`${classes.gradientOverlay} ${classes.gradientOverlayAnimation}`} ref={gradientOverlay} />
            <div className={classes.winScreenContainer}
            // style={{ backgroundImage: `${(bgImage)}` }}
            >
                <div className={classes.section1} ref={section1Ref}>
                    <div className={classes.section1Container} ref={section1ContainerRef}>
                        <div className={classes.starBox}>

                            <img src={star_empty} alt="star" className={`disableSelection disablePointerEvents ${classes.star} `} ref={star1Ref} />
                            <img src={star_empty} alt="star" className={`disableSelection disablePointerEvents ${classes.star} `} ref={star2Ref} />
                            <img src={star_empty} alt="star" className={`disableSelection disablePointerEvents ${classes.star} `} ref={star3Ref} />
                        </div>
                        <div className={classes.textBox}>
                            <div className={classes.challengeComplete} style={{ fontSize: i18n.language === 'en' ? '2em' : '1.5em' }}>
                                {t("CHALLENGE COMPLETE")}!
                            </div>
                            {
                                gbaDesignType && gbaDesignType === 'MCQ_Football' ?
                                    <div className={classes.scoreContainer}>
                                        <div style={{ justifySelf: 'start' }}>
                                            <div style={{ fontSize: i18n.language === 'en' ? '2.5em' : '2.5em' }}>{t("SCORE")}</div>
                                            <div className={classes.totalScore} style={{ fontSize: '3em' }}>
                                                <CountUp className={classes.countUp}
                                                    delay={3.5}
                                                    start={0}
                                                    end={obtainedScore}
                                                    duration={2.75}
                                                    onStart={() => { onScoreCounterStart() }}
                                                    onEnd={handleScoreCounterEnd}
                                                />
                                                /{maxScore}
                                            </div>
                                        </div>
                                        <div style={{ justifySelf: 'end' }}>
                                            <div style={{ fontSize: i18n.language === 'en' ? '2.5em' : '2.5em' }}>{t("GOALS")}</div>
                                            <div style={{ fontSize: '3em', textAlign: 'center' }}>{props.totalGoals}</div>
                                        </div>
                                    </div>
                                    : <>
                                        <div>
                                            <div className={classes.totalScoreTxt} style={{ fontSize: i18n.language === 'en' ? '2em' : '1.5em' }}>
                                                {t("TOTAL SCORE")}
                                            </div>
                                            <div className={classes.totalScore}>
                                                <CountUp className={classes.countUp}
                                                    delay={3.5}
                                                    start={0}
                                                    end={obtainedScore}
                                                    duration={2.75}
                                                    onStart={() => { onScoreCounterStart() }}
                                                    onEnd={handleScoreCounterEnd}
                                                />
                                                /{maxScore}
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>

                <div className={classes.section2}>
                    <div>
                        <div className={classes.achivementText} ref={achivementTextRef}>
                            <div className={classes.text}>{t("MASTERY")}</div>
                            <div className={classes.trophyBox} >
                                {
                                    mastery && (
                                        <Lottie
                                            loop={true}
                                            animationData={animationData}
                                            play={playAnimation}
                                            style={{ height: '100%' }}
                                        />
                                        // <img src="/winScreen/trophy2.png" alt="trophy" className={`${ classes.trophyIcon } `} />
                                    )
                                }
                            </div>
                        </div>
                        <div className={classes.rectBox} ref={masteryRef}>
                            <div className={classes.rect1}>
                                <div className={classes.rect1Child1} ref={rect1Child1Ref} />
                                <div className={classes.rect1Child2} ref={rect1Child2Ref} />
                            </div>
                            <div className={classes.rect2}>
                                <div className={classes.rect2Child1} ref={rect2ChildRef} />
                            </div>
                        </div>
                    </div>

                    <div className={classes.compltTextBox} ref={compltTextBoxRef}>
                        <div className={classes.compltText1} style={{ fontSize: i18n.language === 'en' ? '2em' : '3em', }}>{t("CONGRATULATIONS")}!</div>
                        <div className={classes.compltText2} style={{ fontSize: i18n.language === 'en' ? '1.2em' : '1.2em' }}>{t("You have successfully mastered this challenge")}</div>
                    </div>

                    <div className={classes.challengeListBtnBox}>
                        {
                            learnerJourneyEnded ? (
                                <Button
                                    ref={challengeListBtnBoxRef}
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    className={`${classes.challengeListBtn} `}
                                    sx={{
                                        fontSize: i18n.language === 'en' ? "3em !important" : "2em !important",
                                    }}
                                    onClick={() => navigate(`/learning-journey/${journeyId}`)}
                                >
                                    {t("BACK TO JOURNEY")}
                                </Button>
                            ) : (

                                <div
                                    // className={`${classes.btnContainer}`}
                                    style={{
                                        marginTop: !showNextChallengeBtn && !showCertificate ? '2rem' : '0',
                                        width: '100%'
                                    }}                                >
                                    {
                                        isChallengeDataLoading ? <div className={classes.centerFlex}><CircularProgress /></div>
                                            : (
                                                <>
                                                    {showNextChallengeBtn && (<ActionButton btnText="NEXT CHALLENGE" handleBtnClick={handleNextBtnClick} />)}

                                                    {showCertificate && (<ActionButton btnText="SHOW MY CERTIFICATE" handleBtnClick={handleCertificateClick} />)}
                                                </>
                                            )
                                    }

                                    <Button
                                        variant={!showNextChallengeBtn && !showCertificate ? 'contained' : 'text'}
                                        // variant="contained"
                                        ref={challengeListBtnBoxRef}
                                        fullWidth
                                        size="small"
                                        color="gbabtn"
                                        className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                                        sx={{
                                            fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                                            fontWeight: '500',
                                            color: '#fff',
                                            fontFamily: 'MotionControl',

                                        }}
                                        onClick={handleChallengeListClick} >
                                        {t("CHALLENGE LIST")}
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GbaWinScreen;