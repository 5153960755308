import { makeStyles } from "@mui/styles";
import constants from "../../constants";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const bannerHeight = '180px';
const headerHeightMobile = '80px';
const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    challengeListContainer: {
        padding: '2rem',
        userSelect: 'none',
        [theme.breakpoints.down('md')]: {
            padding: '0',
            height: '100vh',
            overflow: 'scroll'
        },
    },
    challengeListBox: {
        position: 'relative',
        backgroundColor: '#FFF',
        borderRadius: '12px',
        height: `calc(100vh - 4rem - ${constants.HEADER_HEIGHT})`,
        overflow: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 4rem - ${constants.HEADER_HEIGHT} - ${constants.BOTTOM_NAV_HEIGHT})`,
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0',
            height: 'inherit',
            backgroundColor: theme.palette.voilet,
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    challengeListBg: {
        transition: 'height 1.2s ease 0s',
        height: bannerHeight,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'sticky',
        zIndex: 2,
        top: '0',
        backgroundImage: 'url(/images/sample-image/master_o_brand_icon.png)',
        // height: '180px',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
            backgroundBlendMode: "multiply",
            backgroundImage: 'url(/images/sample-image/master_o_brand_icon.png)',
            backgroundColor: "radial-gradient(transparent, black) !important",
            height: `calc(${bannerHeight} + 75px) !important`,
        },
    },
    challengeListHeader: {
        height: `calc(${bannerHeight} - 10px)`,
        backgroundColor: `${palette.voilet}`,
        borderRadius: '12px',
        width: '80%',
        margin: 'auto',
        transition: 'width 0.8s, top 0.8s, background 0.8s',
        position: 'absolute',
        top: `${headerHeightMobile}`,
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0',
            bottom: '0',
            top: 'auto !important',
            // opacity: '0.7',
            backgroundColor: "transparent",
            width: "100%",
            height: `calc(100% - ${headerHeightMobile})`,
            display: 'grid',
            alignContent: 'flex-end'
        },
        // [theme.breakpoints.down('sm')]: {
        //     backgroundColor: "transparent",
        //     width: "100%",
        //     height: `calc(100% - ${headerHeightMobile})`
        // },
    },
    // challengeCardContainer: {
    //     height: '1000px'
    // },
    scoreText: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em'
        }
    },
    detailBox: {
        display: 'flex',
        gap: '1rem',
        paddingTop: '0.5rem',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0rem',
        }
    },
    countText: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
            fontWeight: 600
        }
    },
    iconBox: {
        gap: '0.5rem'
    },
    pathwayText: {
        fontSize: '0.9rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: '2rem 1rem 0 1.5rem',
        color: theme.palette.fontColor,
    },
    challengeListMobileHeader: {
        height: `${headerHeightMobile}`,
        background: 'linear-gradient(#4b4949, #ffffff00)',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'grid',
        alignItems: 'center',
        width: '100%',
        transition: '.6s',
        zIndex: '2',
        gridTemplateColumns: '15% 85%',
        paddingBottom: '0.7rem'
    },
    backButton: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255)',
        marginLeft: '0.8rem',
        cursor: 'pointer',
    },
    moduleName: {
        fontWeight: 600,
        fontSize: '0.9rem',
        color: '#FFF',
        height: '40px',
        padding: '0 0.6rem'
    },


    /* challengeListHeaderBox */
    challengeListHeaderBox: {
        position: 'relative',
        width: '100%',
        height: '100%',
        color: '#FFF',
        padding: '1rem 3.5rem',
        display: 'grid',
        [theme.breakpoints.down('sm')]: {
            padding: '1.2rem',
            display: 'grid',
            alignItems: 'center',
            // justifyContent: 'space-between'
        }
    },
    headerMainText: {
        fontSize: '2rem',
        fontWeight: 'bold',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    completionBox: {
        // fontSize: '1.5rem',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerdetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.6rem',
        fontWeight: '600',
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
        // justifyContent: 'center',
    },
    viewContainer: {
        width: '50%',
        columnGap: '0.8rem',
        justifyContent: 'flex-start',
        fontSize: '1.5rem'
    },
    ratingContainer: {
        width: '50%',
        columnGap: '0.5rem',
        justifyContent: 'flex-end'
    },
    backIcon: {
        position: 'absolute',
        left: '0.6rem',
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.15)',
    },
    challengeListCardBox: {
        marginTop: `calc(${bannerHeight} / 2)`,
        position: 'relative',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
        },
    },
    stepperUl: {
        width: '80%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '92%',
        },
    },
    stepperLi: {
        gap: '1rem',
        display: 'flex',
        justifyContent: 'center',
        '&:first-child': {
            '& .topPathBox': {
                background: 'transparent'
            }
        },
        '&:last-child': {
            '& .bottomPathBox': {
                background: 'transparent'
            }
        }
    },
    pathway: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    topPath: {
        width: '5px',
        background: 'grey',
        flex: '1 1 0%',
        borderRadius: '0 0 8px 8px',
        [theme.breakpoints.down('sm')]: {
            // backgroundColor: '#FFF'
            backgroundColor: theme.palette.fontColor
        },
    },
    bottomPath: {
        width: '5px',
        background: 'grey',
        flex: '1 1 0%',
        borderRadius: '8px 8px 0 0',
        // backgroundColor: '#FFF'
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.fontColor
        },
    },
    icon: {
        margin: '0.6rem 0',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            // filter: 'brightness(2)'
        },
    },
    certificateBox: {
        width: '100%',
        height: '150px',
        display: 'grid',
        justifyItems: 'flex-start',
        alignContent: 'space-around',
        fontSize: '1.3rem',
        fontWeight: '600',
        cursor: 'pointer',
        color: '#707070',
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.fontColor,
            padding: '3rem 0',
            fontSize: '1rem'
        },
    },
    /* challengeListHeaderBox */

    marqueeText: {
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        paddingRight: '1.5rem',
        // display: 'flex',
        whiteSpace: 'nowrap',
        height: '2rem',
        position: 'relative'

        // '-webkit-line-clamp': 1,
        // '-webkit-box-orient': 'vertical',
        // display: '-webkit-box',
        // width: '100%',
    },
    marqueeAnimation: {
        animation: `$marqueeAnimation 12s linear infinite`
    },
    '@keyframes marqueeAnimation': {
        '0%': { transform: 'translateX(100%)' },
        '100%': { transform: 'translateX(-100%)' }
    },
    marqueeTextSpan: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingRight: '1.5rem',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
        whiteSpace: 'nowrap',
        width: 'max-content',
        position: 'absolute',
        // width: '100%'
        // width: '400px'
    },
    perDetailContainer: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: (i18n) => i18n.language === 'ml' ? '50% 50%' : '55% 45%',
            fontSize: (i18n) => i18n.language === 'en' ? '16px' : '14px'
        }
    },
    moduleNameText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingRight: '1.5rem',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
    },
    certificateBtn: {
        fontWeight: '600 !important',
        fontSize: '1.5rem !important',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem !important',
        }
    },
    feedbackConatiner: {
        margin: '1rem 0'
    },
    feedbackHeadline: {
        fontSize: '0.9rem',
        fontWeight: '700',
        marginBottom: '1rem',
        [theme.breakpoints.up('sm')]: {
            color: '#000',
            fontSize: '1.5rem'
        }
    },
    feedbackRatingConatiner: {
        justifyContent: 'flex-start',
        fontWeight: '700',
        columnGap: '1rem',
    },
    feedbackRatingText: {
        width: '15%',
        textAlign: 'center',
        fontSizel: '1.5rem'
    },
    noChallengeText: {
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            color: '#000',
        }
    }

}))

export default useStyles