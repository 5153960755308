import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import rootReducer from './root-reducer';
import { thunk } from 'redux-thunk';
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
// import logger from 'redux-logger'; // Optional: Uncomment if needed

const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
    // middlewares.push(logger); // Optional: Uncomment if needed
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
    }).concat(...middlewares)
});

export const persistor = persistStore(store);

export default { store, persistor };