import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";
import constants from "../../../constants";

const isHDScreen = commonUtil.getScreenResolution() === 'FHD';

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mcqRootContainer: {
        // display: 'grid',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column',
        position: 'absolute',
        top: "-20%",
        height: "120%"
        // alignContent: 'space-between',
    },
    mcqBox: {
        margin: 'auto',
        position: 'relative',
        display: 'grid',
        gap: '1em',
        gridTemplateColumns: '1fr 1fr',
        overflowWrap: 'anywhere',
        hyphens: 'auto',
        fontSize: isHDScreen ? '0.6em' : '0.9em',
        width: '100%',
        height: '16em',
        padding: '2em 4em',
    },

    cardBox: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '5px',
        height: '4rem',
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 2px 0px',
        transition: 'transform 0.3s linear',
        minHeight: '2rem',
        // outline: '1px solid #fff',
        // border: '1px solid #fff',
        position: 'relative',
        zIndex: '5',
        fontFamily: 'MotionControl',
        fontSize: '2rem',
        fontWeight: '700',
    },
    cardGridAdd: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        placeItems: 'center',
    },
    cardScore: {
        width: "2rem",
        height: "2rem",
        borderRadius: "50%",
        backgroundColor: "#F4511E",
        color: "#fff",
        fontSize: "1rem",
        display: 'none',
        placeItems: "center",
        justifySelf: "center"
    },
    cardFootballImg: {
        width: '2rem',
        height: '2rem',
        justifySelf: "center",
        display: "none"
    },
    card: {
        padding: '1rem 0.6rem',
        height: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans',
        transition: 'all 0.2s linear',
        fontSize: isHDScreen ? '0.6rem' : '0.8rem',

        overflowWrap: 'anywhere',
        hyphens: 'auto',

        [theme.breakpoints.down("sm")]: {
            padding: '0.3rem',
            fontSize: isHDScreen ? '0.6rem' : '0.7rem',
        },
    },
    aimContainer: {
        height: '100%',
        position: 'relative',
        cursor: 'pointer',
        touchAction: 'none',
    },
    aimBox: {
        // '--angle': '0deg',
        position: 'absolute',
        bottom: '2.5rem',
        width: '5rem',
        height: '5rem',
        // transform: 'rotate(var(--angle))',
    },
    navigation: {
        '--height': '120px',
        '--angle': '0deg',
        position: 'absolute',
        bottom: '0rem',
        height: 'var(--height)',
        left: '50%',
        width: '1px',
        transform: 'translateX(-50%) rotate(var(--angle))',
        backgroundColor: 'transparent',
        transformOrigin: 'center bottom',
        zIndex: '999',
    },

    ball: {
        '--transisnTime': '400ms',
        position: 'absolute',
        bottom: 'var(--top)',
        left: '50%',
        width: '2.5rem',
        height: '2.5rem',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        zIndex: '10',
        backgroundImage: 'url(/images/gba/football/ball.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition: 'bottom var(--transisnTime) linear'
    },

    disableText: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
    },

    guideText: {
        '--angle': '0deg',
        // display: 'none',
        display: 'block',
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        background: '#F4511E',
        padding: '0.5rem',
        transform: 'rotate(var(--angle))',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '2rem', /* Adjust size as needed */
            height: '2rem', /* Adjust size as needed */
            background: '#F4511E',
            clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
            left: 'calc(50% - 1rem)', /* Adjust to center the triangle */
            top: '-1.5rem', /* Adjust position */
        },
    },

    cricketRoot: {
        height: '100%',
    },
    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running, $animateOverlay 3s ease 1s 1 normal forwards running',
        // backgroundImage: "linear-gradient(#00000000, #00000000), url(/images/gba/cricket_bg_2.png)",
        background: "#5c533c",
        backgroundRepeat: 'repeat-x',
        backgroundSize: 'cover',
        transition: '1s',
        // gridTemplateRows: '25% 25% 50%',
        gridTemplateRows: '1.25% 25% 23.75% 50%',
        // 1.25% added for progress bar, 23.75 for staduim and lighting.
    },
    blinkAnimationEffect: {
        animation: '$blinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes blinkAnimation': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 }
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' }
    },
    "@keyframes animateOverlay": {
        // "to": { backgroundImage: 'linear-gradient(#0000007a, #00000069), url(/images/gba/cricket_bg_2.png)' },
    },
    progressBarContainer: {
        position: "relative",
        height: "100%",
        zIndex: "2",
        width: '100%',
        borderRadius: '0px',
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
        // marginTop: '4px',
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    // questionBox container
    qstnContainer: {
        height: "100%",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative',
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        display: 'grid',
        gridTemplateRows: '35% 65%',
        backgroundColor: 'rgb(0 0 0 / 56%)',
        fontSize: "1.2em",
        height: '100%'
    },
    qstnBoxBoxShadow: {
        // animation: '$wrongAnsAnimation 1.5s ease 0s 1 normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "100%": { boxShadow: "0px 0px 20px -1px #F63B34E8" },
    },
    assetBox: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "0 12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        width: "1em",
        height: "1em",
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: '1em',
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: '1.2em',
        padding: '0rem 0.9rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2em ',
        },
    },
    gameContainerBox: {
        position: 'relative',
        height: '100%'
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    wrongAnswerAnimation: {
        animation: '$wrongAnswerAnim 1s ease-in-out 0s infinite normal forwards running'
    },
    "@keyframes wrongAnswerAnim": {
        "0%": {
            boxShadow: "inset 0px 0px 10px 4px red",
            background: "#ff000063",
            zIndex: '2',
            opacity: 0,
        },
        "100%": {
            boxShadow: "inset 0px 0px 10px 1px red",
            background: "#ff000029",
            zIndex: '2',
            opacity: 1,
        },
    },
    rightAnswerAnimation: {
        animation: '$rightAnswerAnim 1s ease-in-out 0s 3 normal forwards running'
    },
    "@keyframes rightAnswerAnim": {
        "0%": {
            boxShadow: "inset 0px 0px 10px 4px #0cc43d",
            zIndex: '2',
            opacity: 0,
        },
        "100%": {
            boxShadow: "inset 0px 0px 10px 1px #0cc43d",
            zIndex: '2',
            opacity: 1,
        },
    },
    blinkAnimation: {
        animation: "$blinkAnim 1s ease 0s infinite normal forwards running",
    },
    "@keyframes blinkAnim": {
        "0%": { opacity: "1" },
        "50%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    answerText: {
        // display: 'none',
        width: '95%',
        backgroundColor: 'rgb(0 0 0 / 56%)',
        opacity: '0.8',
        margin: 'auto',
        padding: '0.5rem',
        borderRadius: '8px',
        fontSize: '1rem',
        gridTemplateColumns: "1fr",
        placeItems: 'center',
        position: 'absolute',
        top: '15%',
        left: "2%",
        right: '2%',
        display: 'grid',
        // visibility: 'hidden'
    },
    answerBallImg: {
        marginRight: "1rem",
        width: "2rem",
        height: "2rem",
        fontSize: "1.5rem",
        color: "#fff",
        backgroundImage: "url(/images/gba/football/ball.svg)",
        borderRadius: "50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
        backgroundColor: "rgba(0,0,0,0.5)",
        // display: "flex",
        fontFamily: 'MotionControl',
        justifyContent: "center",
        alignItems: "center",
        display: 'none',
    },
    pointShootText: {
        // display: 'block',
        // backgroundColor: 'rgb(0 0 0 / 56%)',
        opacity: '1',
        margin: 'auto',
        // padding: '0.8rem',
        // borderRadius: '2rem',
        fontSize: '2.2rem',
        fontFamily: 'MotionControl',
        position: 'absolute',
        // top: '15%',
        left: "50%",
        transform: 'translateX(-50%)',
        // position: 'absolute',
        bottom: '0.3rem',
    },
    ballImg: {
        width: '5rem',
        height: '5rem',
        padding: '0.5rem',
    },
    billa: {
        width: "8rem",
        height: "8rem",
        position: "absolute",
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '10',
    },
    billaAnimation: {
        '--animTime': '3.5',
        animation: "$billaMovement var(--animTime) linear 0s infinite",
    },
    '@keyframes billaMovement': {
        '0%': { left: '50%' },
        '25%': { left: '90%' },
        '50%': { left: '50%' },
        '75%': { left: '10%' },
        '100%': { left: '50%' }
    },
    billaMissed: {
        width: "8rem",
        height: "8rem",
        position: "absolute",
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '10',
        display: "none",
    },
    billaCaught: {
        width: "8rem",
        height: "8rem",
        position: "absolute",
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '10',
        display: "none",
    },
    resultStatusContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        zIndex: '1',
        pointerEvents: 'none',
    },
    resultText: {
        fontSize: "2rem",
        color: "#fff",
        fontWeight: "700",
        textAlign: "center",
        position: "absolute",
        top: "5rem",
        width: "100%",
        zIndex: "20",

    },
    goalText: {
        fontSize: "2rem",
        color: "#fff",
        fontWeight: "700",
        textAlign: "center",
        position: "absolute",
        top: "10rem",
        width: "100%",
        zIndex: "20",
    },
    goalStatus: {
        position: 'absolute',
        bottom: '4rem',
        display: 'none',
        fontSize: "3rem",
        fontFamily: 'MotionControl',
        fontWeight: 700,
        color: "#fff",
    },
    '@keyframes blinkRed': {
        ' 0%, 100%': {
            boxShadow: 'inset 0 0 10px 10px rgba(255, 0, 0, 0)',
        },
        '50%': {
            boxShadow: 'inset 0 0 100px 200px rgba(255, 0, 0, 0.2)',
        }
    },
    blinkRed: {
        animation: '$blinkRed 1.5s ease-in-out 0s infinite normal forwards running',
    },
    goalPostImg: {
        position: 'absolute',
        top: "0",
        width: "100%",
        height: 'auto',
    },
    grassContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    answerStatus: {
        display: 'none',
        width: '2rem',
        height: '2rem',
    },
    muteSwitch: {
        // zIndex: 2,
        justifySelf: 'end',
        cursor: 'pointer',
        padding: '0.4rem'
    },
    fadeInfintite: {
        animation: '$fadeInfi 1s linear 0s infinite normal forwards running',
    },
    '@keyframes fadeInfi': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 },
    },

}))

export default useStyles