import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { List, ListItemButton, ListItemText, Collapse, Typography, CircularProgress } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Modal } from '@mui/material';
import { Grid, Box } from "@mui/material";
import { styled } from "@mui/styles";
import Carousel from "react-multi-carousel";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import VideoPlayer from "../video-player";
import webViewJavaScriptBridge from "webview-javascript-bridge";

// styles
import useStyles from "./index.styles";

// redux
import { PerformancePage } from "../../redux/event-tracking/event-tracking-slice";
import { setIsNativeApp } from "../../redux/common/common-slice";

// api
import { getUserPerformance, getPerformanceLeaderboard, getSpotlightChallenges, getTopSpotlights } from "../../redux/performance/performance.api";
import { getAllModulesAsync } from "../../redux/microskill/microskill.api";
import { submitUserActivityData } from "../../redux/event-tracking/event.api";

//  mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from "react-i18next";
import isEmpty from "../../utils/isEmpty";
import constants from "../../constants";
import commonUtil from "../../utils/commonUtil";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1400, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 2
  }
};

const Performance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timeColor = '#00CC2E';
  const scoreColor = '#F4511E';
  const attemptsColor = "#FFDD00"
  const accColor = '#0043B5';
  const isMobile = useSelector(state => state?.common?.isMobile);
  const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode)
  const userActivityData = useSelector(state => state.event);
  const firstRenderRef = useRef(true);
  const leaderboardRef = useRef();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userPerformanceData, setUserPerformanceData] = useState({});
  const [completed, setCompleted] = useState(false);
  const [userName, setUserName] = useState('');
  const [userUnique, setUserUnique] = useState('');
  const [assignedMicroskill, setAssignedMicroskill] = useState(0);
  const [completedMicroskill, setCompletedMicroskill] = useState(0);
  const [microskillDataList, setMicroskillDataList] = useState([]);
  const [microskillDropDown, setMicroskillDropdown] = useState([]);
  const [microskillDropDownSl, setMicroskillDropdownSl] = useState([]);
  const [spotlightChallenges, setSpotlightChallenges] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [selectedMicroskill, setSelectedMicroskill] = useState({});
  const [selectedMicroskillSl, setSelectedMicroskillSl] = useState({});
  const [videoData, setVideoData] = useState({})
  const [spotlights, setSpotlights] = useState([]);
  const [score, setScore] = useState(0);
  const [timespent, setTimespent] = useState('00:00:00');
  const [attempts, setAttempts] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [compAvgScore, setCompAvgScore] = useState(0);
  const [compAvgTimespent, setCompAvgTimespent] = useState('00:00:00')
  const [compAvgAttempts, setCompAvgAttempts] = useState(0);
  const [compAvgAccuracy, setCompAvgAccuracy] = useState(0);
  const [currVideo, setCurrVideo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const [isDataLoaded, setIsDataLoaded] = useState(false);

  const slMicroskillListRef = useRef(null);

  const { t, i18n } = useTranslation()
  const classes = useStyles(i18n);

  const compId = commonUtil.getCompanyId()
  let isNative = localStorage.getItem("isNative");

  // MUI Component Styles
  const listItemStyle = (theme) => ({
    color: theme.palette.fontColor,
    textAlign: 'left',
    font: 'normal normal bold 1rem Montserrat',
    letterSpacing: '1px',
    opacity: '1',
    borderBottom: `0.5px solid ${theme.palette.fontColor}`,
    paddingBottom: '0.5rem'
  })

  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.darkVoilet,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.fontColor,
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      // height: (leaderboardList.length == 10 && leaderboardRef.current ? `${~~((leaderboardRef.current.clientHeight-16)/2)}px` : '355px')
      height: '100%',
      // marginBottom: theme.spacing(2)
      padding: theme.spacing(4)
    }
  }));

  const styleTableBodyCell = {
    color: (theme) => theme.palette.fontColor,
    font: 'normal normal normal 0.9rem Open Sans',
    borderBottom: theme => `1px solid ${theme.palette.fontColor}`
  }

  const styleTableHeadCell = {
    color: (theme) => theme.palette.fontColor,
    font: 'normal normal bold 0.9rem Open Sans',
    borderBottom: theme => `1px solid ${theme.palette.fontColor}`
  }

  useEffect(() => {
    if (firstRenderRef.current) {
      dispatch(PerformancePage({ oldScreen: userActivityData?.currentScreen, currentScreen: 'PERFORMANCE', activityData: { message: 'Performance page loaded' } }));
      firstRenderRef.current = false;
    } else {
      submitUserActivityData(userActivityData);
    }
  }, [userActivityData])

  /* Event Handlers */
  const handleClick = () => {
    setOpen(!open);
  };

  const handleDropdownClick = (id, name) => {
    // console.log(id);
    // selected microskill
    setOpen(!open);
    setSelectedMicroskill({ id, name });
    getPerformanceLeaderboardAsync(id);
  }

  const handleSlDropdownClick = (challenge) => {
    // console.log(challenge)
    setSelectedMicroskillSl({ ...challenge });
    slMicroskillListRef.current.style.display = 'none';
    const payload = {
      challengeId: challenge.id,
      microskillId: challenge?.Microskill.id
    }
    handleTopSpotlights(payload);
  }

  /* Utility Functions */
  const roundToTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  const fancyTimeFormat = (d) => {
    // Hours, minutes and seconds
    let hrs = Math.floor(d / 3600);
    const mins = Math.floor(d % 3600 / 60);
    const secs = Math.floor(d % 3600 % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs >= 1000) {
      hrs = Math.round(hrs / 1000);
      ret += "" + hrs + "k hrs";
    } else {
      if (hrs > 0) {
        ret += "" + (hrs < 10 ? `${hrs}` : hrs) + ":";
      }
      ret += "" + (mins < 10 ? `0${mins}` : mins) + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
    }
    return ret;
  }

  // const fancyNameFormat = (name) => name ? name.split(' ').map((s) => s.split('')[0].toUpperCase() + s.split('').slice(1).join('')).join(' ') : '';


  /* Data Fetching Functions */
  const getUserPerformanceAsync = async () => {
    // setLoading(true)
    const resp = await getUserPerformance();
    // setLoading(false)
    if (resp.status == 200 || resp.status == 201) {
      const userPerformance = resp.data?.data?.userPerformance;
      setUserPerformanceData(userPerformance);
      setUserName(`${!isEmpty(userPerformance.User.firstName) ? userPerformance?.User?.firstName : ''} ${(!isEmpty(userPerformance.User.lastName)) ? userPerformance?.User?.lastName : ''} `);
      setUserUnique(userPerformance?.User?.username);
      setAssignedMicroskill(userPerformance?.microskillLaunched);
      setCompletedMicroskill(userPerformance?.microskillCompleted);
      setScore(userPerformance?.scored);
      setAttempts(userPerformance?.totalAttempts);
      setTimespent(fancyTimeFormat(parseFloat(userPerformance?.totalTimespent)));

      let userAccuracy = (~~((userPerformance.correctAttempts / userPerformance.totalAttempts) * 10000)) / 100;
      userAccuracy = Math.round(userAccuracy)
      setAccuracy(`${userAccuracy}% `);

      const companyAverage = resp?.data?.data?.companyAverage;
      // console.log(companyAverage, companyAverage.scored, companyAverage.totalTimespent)
      setCompAvgScore(Math.round(parseFloat(companyAverage?.scored)));
      setCompAvgAttempts(Math.round(parseFloat(companyAverage?.totalAttempts)));
      setCompAvgTimespent(fancyTimeFormat(parseFloat(companyAverage?.totalTimespent)));

      let compAvgAccuracy = (~~((companyAverage.correctAttempts / companyAverage.totalAttempts) * 10000)) / 100;
      compAvgAccuracy = Math.round(compAvgAccuracy)
      setCompAvgAccuracy(`${compAvgAccuracy}% `);
    }
  };

  const handleAllModuleAsync = async () => {
    const resp = await getAllModulesAsync('?startIndex=0&limit=100&sort=createdOn&sortDirection=asc');
    // console.log(resp);
    if (resp.status == 200 || resp.status == 201) {
      let microskillList = resp.data?.data?.microskills || [];
      setMicroskillDataList([...microskillList]);
      microskillList = microskillList.map((microskill) => {
        return {
          id: microskill?.Microskill.id,
          name: microskill?.Microskill.name
        }
      })
      setMicroskillDropdown([...microskillList]);
      setMicroskillDropdownSl([...microskillList]);
      if (microskillList.length > 0) {
        setSelectedMicroskill({ id: microskillList[0].id, name: microskillList[0].name });
        getPerformanceLeaderboardAsync(microskillList[0].id);
      }
    }
  }

  const getPerformanceLeaderboardAsync = async (id) => {
    const resp = await getPerformanceLeaderboard(id);
    if (resp.status == 200 || resp.status == 201) {
      let leaderboard = resp.data?.data;
      setLeaderboardData([...leaderboard]);
      leaderboard = leaderboard.map((user, i) => (
        {
          rank: `${i + 1} `,
          name: user.User?.firstName ? `${!isEmpty(user.User?.firstName) ? user.User?.firstName : ""} ${!isEmpty(user.User?.lastName) ? user.User?.lastName : ""}` : '-',
          username: user.User?.username,
          points: user.scored,
          time: user.totalTimespent,
          completion: (~~((user.completedChallenges / user.totalChallenges) * 10000)) / 100,
          accuracy: ((~~((user.correctAttempts / user.totalAttempts) * 10000)) / 100)
        }
      ))
      setLeaderboardList([...leaderboard]);
    }
  }

  const handleSpotlightChallenges = async () => {
    const resp = await getSpotlightChallenges();
    // console.log(resp);
    if (resp.status == 200 || resp.status == 201) {
      let challenges = resp.data?.data;
      console.log(challenges)
      setSpotlightChallenges([...challenges]);
    }
  }

  const handleTopSpotlights = async (payload) => {
    const resp = await getTopSpotlights(payload);
    // console.log(resp);
    if (resp.status == 200 || resp.status == 201) {
      let spotlights = resp.data?.data;
      // console.log(spotlights);
      setSpotlights([...spotlights]);
    }
  }

  const handleSlMicroskillClick = (e) => {
    slMicroskillListRef.current.style.display = slMicroskillListRef.current.style.display == 'none' ? 'block' : 'none';
  }

  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleClickOnMicroskillCompleted = () => {
    if (!completed) {
      if (userPerformanceData.microskillCompleted > 0 && userPerformanceData.microskillLaunched > 0) {
        const temp = Math.floor((userPerformanceData.microskillCompleted / userPerformanceData.microskillLaunched) * 100)
        setCompletedMicroskill(`${temp}%`);
      }
      else {
        setCompletedMicroskill('0%');
      }
      setCompleted(true);
    }
    else {
      setCompletedMicroskill(userPerformanceData.microskillCompleted);
      setCompleted(false);
    }
  }

  /* UseEffects */
  useEffect(() => {
    (async () => {
      setLoading(true)
      await getUserPerformanceAsync();
      await handleAllModuleAsync();
      await handleSpotlightChallenges();
      setLoading(false)
    })()

  }, [])

  const NoMicroskillHoc = () => {
    return (
      <div className={`${classes.textContainer} ${classes.centerFlex}`} >
        <div className={classes.NoMicroskillText}>{t("No Microskills Assigned")}</div>
        <div className={classes.displayText}>{t("Please contact your company administrator")}</div>
      </div>
    )
  }

  const ItemStyle = (theme) => ({
    backgroundColor: theme.palette.darkVoilet,
    borderRadius: '10px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.mobilePerfPageCardsBg
    }

  })


  useEffect(() => {
    if (window.isNative || window.deviceDetails || window.deviceToken) {
      dispatch(setIsNativeApp(true));
    }
  }, [window.isNative, window.deviceDetails, window.deviceToken]);

  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      console.log('Page loaded');
      if (isNative) {
        webViewJavaScriptBridge.sendMessage({ action: 'TurnOffNativeSplashScreenChannel', params: true })
      }
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const getValue = (value) => {
    if (value >= 1000) {
      return `${Math.round(value / 1000)}k`
    }
    return value
  }

  if (loading) {
    return (
      <Box sx={(theme) => ({
        ...constants.FULL_HEIGHT_CENTER,
        position: 'absolute',
        top: `calc(${constants.HEADER_HEIGHT} + 0.5rem)`,
        width: '100vw',
        backgroundColor: theme.palette.mobilePerfPageBg
      })}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <>

        <div className={classes.nameContainer}>
          <h1 className={classes.userName}>{!isEmpty(userName) ? userName : userUnique}</h1>
          {/* Microskills Assigned */}
          <div className={classes.microskillContainerParent}>
            <div className={classes.microskillContainer}>
              <h2 className={classes.mcHeading}>{assignedMicroskill}</h2>
              <div className={classes.mcHeading2}>{t("Microskills Assigned")}</div>
            </div>
            {/* Microskills Completed */}
            <div className={classes.microskillContainer} onClick={handleClickOnMicroskillCompleted}>
              <h2 className={classes.mcHeading}>{completedMicroskill}</h2>
              <div className={classes.mcHeading2} style={{ wordBreak: 'break-word' }}>{t("Microskills Completed")}</div>
            </div>
          </div>
        </div>

        {
          assignedMicroskill > 0 ? <>
            {/* Should take 50% of the screen on desktop */}
            <div className={classes.leaderboardSection} ref={leaderboardRef}>
              <h2 className={classes.leaderboardHeading}>{t("Leaderboard")}</h2>
              <List>
                <ListItemButton sx={theme => ({ pl: 4, color: theme.palette.fontColor })} onClick={handleClick}>
                  <ListItemText disableTypography primary={<Typography
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.palette.fontColor,
                      textAlign: 'left',
                      font: 'normal normal bold 1rem Montserrat',
                      letterSpacing: '1px',
                      opacity: '1',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '0px',
                    })}>{selectedMicroskill.name ? selectedMicroskill.name : t('Select a Microskill')} {!open ? <KeyboardArrowDownIcon style={{ color: '#F4511E' }} /> : <KeyboardArrowUpIcon style={{ color: "#F4511E" }} />} </Typography>} />
                  {open ? <ExpandLess style={{ color: 'transparent' }} /> : <ExpandMore style={{ color: 'transparent' }} />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {
                      microskillDropDown.map((microskill) => (
                        <ListItemButton sx={{ pl: 4 }} key={microskill.id} onClick={() => handleDropdownClick(microskill.id, microskill.name)} data-id={microskill.id}>
                          <ListItemText disableTypography primary={<Typography
                            variant="body2"
                            sx={listItemStyle}>{microskill.name}</Typography>} />
                        </ListItemButton>
                      ))
                    }
                  </List>
                </Collapse>
              </List>
              <TableContainer component={Paper} sx={(theme) => ({
                backgroundColor: theme.palette.darkVoilet,
                boxShadow: '0px 0px 0px 0px transparent',
                overflowX: 'auto',
                [theme.breakpoints.down('md')]: {
                  backgroundColor: theme.palette.mobilePerfPageCardsBg,
                }
              })}>
                <Table sx={{
                  minWidth: 650
                }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={(theme) => ({
                      backgroundColor: theme.palette.darkVoilet,
                      [theme.breakpoints.down('md')]: {
                        backgroundColor: theme.palette.mobilePerfPageCardsBg,
                      }
                    })}>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("RANK")}</TableCell>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("NAME")}</TableCell>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("POINTS")}</TableCell>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("TIME")}</TableCell>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("COMPLETION")}</TableCell>
                      <TableCell align="center" sx={styleTableHeadCell}>{t("ACCURACY")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaderboardList.map((row) => (
                      <TableRow
                        key={row.rank}
                        sx={theme => ({
                          '&:last-child td, &:last-child th': { border: 0 },
                          backgroundColor: (row.username == userUnique ? theme.palette.voilet : theme.palette.darkVoilet),
                          fontWeight: (row.username == userUnique ? 'bold' : 'normal'),
                          borderTop: (row.username == userUnique || row.name == userName ? '2px dashed #F4511E' : '0px'),
                          borderBottom: (row.name == userName || row.username == userUnique ? '2px dashed #F4511E' : '0px'),
                          height: '3.99rem',
                          [theme.breakpoints.down('md')]: {
                            backgroundColor: (row.username == userUnique ? theme.palette.headerBg : theme.palette.mobilePerfPageCardsBg),
                          }

                        })}
                      >
                        <TableCell component="th" scope="row" align="center" sx={styleTableBodyCell} >
                          {row.rank}
                        </TableCell>
                        <TableCell align="center" sx={styleTableBodyCell}>{row.name !== '-' ? row.name : row.username}</TableCell>
                        <TableCell align="center" sx={styleTableBodyCell}>{row.points}</TableCell>
                        <TableCell align="center" sx={styleTableBodyCell}>{fancyTimeFormat(parseFloat(row.time))}</TableCell>
                        <TableCell align="center" sx={styleTableBodyCell}>{Math.round(row.completion)}%</TableCell>
                        <TableCell align="center" sx={styleTableBodyCell}>{Math.round(row.accuracy)}%</TableCell>
                      </TableRow>
                    ))}
                    {/* <TableRow
                              // key={row.rank}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell component="th" scope="row" align="center" sx={styleTableBodyCell} >

                              </TableCell>
                              <TableCell align="center" sx={styleTableBodyCell}></TableCell>
                              <TableCell align="center" sx={styleTableBodyCell}></TableCell>
                              <TableCell align="center" sx={styleTableBodyCell}></TableCell>
                              <TableCell align="center" sx={styleTableBodyCell}></TableCell>
                              <TableCell align="center" sx={styleTableBodyCell}></TableCell>
                          </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* User Performacr data: should */}
            <Grid container justifyContent='center'
              sx={{
                marginTop: '1rem',
                paddingBottom: '1rem',
                rowGap: "1.2rem",
                columnGap: "1.2rem",
                "& .MuiGrid-root": {
                  justifyContent: "center",
                  alignItems: 'center',
                },
                '@media (min-width: 900px)': {
                  width: '50%',
                  marginTop: '0',
                  marginLeft: '0',
                  rowGap: "3rem",
                  columnGap: "3rem",
                  height: '100%',//85%
                  // paddingLeft: (theme) => theme.spacing(2)
                }
              }} >
              <Grid item xs={5} md={5}>
                <Item sx={ItemStyle}>
                  <div className={classes.cardSpacing}>
                    <p className={classes.cardHeading}>{t("Score")} </p>
                    <p className={classes.cardValue}>{getValue(score)}</p>
                    <p className={classes.cardSubHeading}>{t("Your Score")} </p>
                    <p className={classes.cardBar} style={{ backgroundColor: `${scoreColor} ` }}></p>
                    <p className={classes.cardValue2}>{getValue(compAvgScore)}</p>
                    <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                    {/* <p className={classes.cardFooter}><img src="/images/performance/down-arrow.svg"/> Lower than 54% of your batch</p>x */}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={5} md={5}>
                <Item sx={ItemStyle}>
                  <div className={classes.cardSpacing} style={{ overflowX: 'hidden' }}>
                    <p className={classes.cardHeading}>{t("Time")} </p>
                    <p className={`${classes.cardValue} scrollbar-hidden`} style={{ overflowX: 'auto' }}>{timespent}</p>
                    <p className={classes.cardSubHeading}>{t("Your Time")} </p>
                    <p className={classes.cardBar} style={{ backgroundColor: `${timeColor} ` }}></p>
                    <p className={classes.cardValue2}>{compAvgTimespent}</p>
                    <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                    {/* <p className={classes.cardFooter}><img src="/images/performance/down-arrow.svg"/> Lower than 54% of your batch</p> */}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item sx={ItemStyle}>
                  <div className={classes.cardSpacing}>
                    <p className={classes.cardHeading}>{t("Attempts")} </p>
                    <p className={classes.cardValue}>{getValue(attempts)}</p>
                    <p className={classes.cardSubHeading}>{t("Your Attempts")} </p>
                    <p className={classes.cardBar} style={{ backgroundColor: `${attemptsColor} ` }}></p>
                    <p className={classes.cardValue2}>{getValue(compAvgAttempts)}</p>
                    <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                    {/* <p className={classes.cardFooter}><img src="/images/performance/up-arrow.svg"/> Higher than 54% of your batch</p> */}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item sx={ItemStyle}>
                  <div className={classes.cardSpacing}>
                    <p className={classes.cardHeading}>{t("Accuracy")} </p>
                    <p className={classes.cardValue}>{accuracy}</p>
                    <p className={classes.cardSubHeading}>{t("Your Accuracy")} </p>
                    <p className={classes.cardBar} style={{ backgroundColor: `${accColor} ` }}></p>
                    <p className={classes.cardValue2}>{compAvgAccuracy}</p>
                    <p className={classes.cardSubHeading}>{t("Company Average")}</p>
                    {/* <p className={classes.cardFooter}><img src="/images/performance/up-arrow.svg"/> Higher than 54% of your batch</p> */}
                  </div>
                </Item>
              </Grid>
            </Grid>

            {
              compId === 'clf15f5o1000tqn043k2g2umg' || compId === 'clfwf58uk002figur4i1915pp' || compId === 'compy_EP9cACGPdfNij3qGVzx' &&
              <div className={classes.spotlightContainer}>
                <h1 className={classes.spotlightHeading}>{t("Featured Spotlights")}</h1>
                <div style={{ marginTop: '2%' }}>
                  <h3 className={classes.slSelectedMicroskill} onClick={handleSlMicroskillClick}>{selectedMicroskillSl?.name ? selectedMicroskillSl.name : 'Select a Challenge'} {slMicroskillListRef.current?.style.display == 'block' ? <ExpandLess style={{ color: '#F4511E' }} /> : <ExpandMore style={{ color: '#F4511E' }} />}</h3>
                  <div ref={slMicroskillListRef} className={classes.slMicroskillCont}>
                    <ul className={classes.slMicroskillList}>
                      {
                        spotlightChallenges.map((challenge) => (
                          <li className={classes.microListEl} onClick={() => { handleSlDropdownClick(challenge.Challenge) }}>{challenge.Challenge.name}</li>
                        ))
                      }

                    </ul>
                  </div>
                </div>

                <div className={classes.spotlightCardContainer}>
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    // customLeftArrow={<ArrowBackIosNewIcon />}
                    // customRightArrow={<CustomRightArrow />}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className={classes.reactCustomClass}
                    containerClass="container"
                    dotListClass=""
                    draggable={isMobile}
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable={isMobile}
                    responsive={responsive}
                  >
                    {
                      spotlights.map((spotlight) => (
                        <div className={classes.spotlightVideo} onClick={() => { setCurrVideo({ media: spotlight.videoPath, challengeName: selectedMicroskillSl.name }); setVideoData(spotlight.response); handleModalOpen(); }} >
                          <h3 className={classes.spotlightName}>{spotlight.name ? spotlight.name : ''}</h3>
                          <h4 className={classes.spotlightPoints}>{`${spotlight.scored} /120`}</h4 >
                          <ReactPlayer
                            playsinline
                            url={`${spotlight.videoPath}`}
                            playing={false}
                            width="100%"
                            height="100%"
                          />
                        </div >
                      ))
                    }
                  </Carousel >
                </div >

              </div >
            }
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <VideoPlayer setOpenModal={setModalOpen} currVideo={currVideo} videoData={videoData} />
            </Modal>

          </> : <NoMicroskillHoc />
        }



      </>
    )
  }

}

export default Performance;