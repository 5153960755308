import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { styled, } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// styles 


// theme
import palette from '../../../../theme/palette';
import theme from '../../../../theme';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


// i18-next
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './index.styles';
import { useContext } from 'react';
import { KeyboardArrowDown, KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, List, ListItemText } from '@mui/material';

import { openToast, setHeaderSearchText, setMicroskillCategories, setSelectedLanguage, setSelectedMicroskillCategories, setSelectedMicroskillCategory, setViewMicroskillByCategory } from '../../../../redux/common/common-slice';

const SearchBoxWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        // width: '98%',
        // background: theme.palette.mobileMainContainerBg,
        background: theme.palette.lightVoilet1,
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 2),
}));
const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '600',
    fontSize: '1.2rem',
    position: "relative",
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}));

const SearchBar = (props) => {

    const { showSearchHeader, handleSearchBarText, CategoryFilterComp, handleSearchBarClose, searchInputValue, isSearchPageVisible, isPlaceholderVisible = false } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { isMobile, isTablet, isDocViewing, selectedLanguage, microkillCategories, selectedMicrokillCategories } = useSelector(state => state?.common);


    const classes = useStyles({ isDarkMode });
    const dispatch = useDispatch();

    const url = window.location.href;

    const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
    const [searchedInputValue, setSearchedInputValue] = useState('');
    const [toggleCategories, setToggleCategories] = useState(null);
    const [microkillCategoriesList, setMicroskillCategoriesList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const filterBtnContainerRef = useRef();

    let texts = [
        'Search for “Microskills”',
        'Search for “Learning Journeys”',
        'Search for “Forms”',
    ];

    // const searchPlaceholderTextArray = ["Search Microskills", "Search Learning Journeys", "Search Forms"];
    // let t = 0;

    // // Flag to prevent multiple updates at once
    // let isUpdating = false;

    // const changeSearchPlaceholderValue = () => {
    //     const inputElement = document.getElementById('search');

    //     if (inputElement && !isUpdating) {
    //         isUpdating = true;

    //         // Check bounds for the array
    //         if (t < searchPlaceholderTextArray.length) {
    //             const newPlaceholder = searchPlaceholderTextArray[t];
    //             const currentWordArray = newPlaceholder.split("");

    //             // Clear the placeholder first
    //             inputElement.placeholder = "";

    //             // Update the placeholder one character at a time
    //             const updatePlaceholder = (index) => {
    //                 if (index < currentWordArray.length) {
    //                     setTimeout(() => {
    //                         inputElement.placeholder = currentWordArray.slice(0, index + 1).join('');
    //                         updatePlaceholder(index + 1); // Call for the next character
    //                     }, 100); // Adjust this delay to make it slower
    //                 } else {
    //                     // Once the word is complete, wait for 1 second : 
    //                     setTimeout(() => {
    //                         t++; // Now we can increment t to move to the next word
    //                         isUpdating = false; // Reset the flag
    //                         changeSearchPlaceholderValue(); // Start the next placeholder
    //                     }, 1000); // Wait before starting the next placeholder
    //                 }
    //             };

    //             updatePlaceholder(0); // Start updating from the first character
    //         } else {
    //             // Reset t to loop through the placeholders again
    //             t = 0;
    //             isUpdating = false; // Reset the flag
    //             changeSearchPlaceholderValue(); // Start over with the first placeholder
    //         }
    //     }
    // };

    // useEffect(() => {
    //     changeSearchPlaceholderValue(); // Start the placeholder changing on mount
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <SearchBoxWrapper  >
            <Search
                className={`search ${classes.search}`}
                sx={theme => ({
                    display: 'none',
                    [theme.breakpoints.down('md')]: {
                        display: url.includes('/learn') ? 'grid !important' : 'none',
                    },
                    width: '100%',
                    height: '100%',
                    // visibility: showSearchHeader && !url.includes('/challenge-list') && !url.includes('/performance') && !url.includes('/privacy-policy') && !isDocViewing ? 'visible' : 'hidden',
                    // gridTemplateColumns: openCategoryFilter ? "5% 90% 5%" : "5% 95%",
                    gridTemplateColumns: "90% 10%",
                    borderRadius: openCategoryFilter ? `4px 4px 0 0` : "4px",
                })}
            >

                <div className={classes.searchInputBox}>
                    <StyledInputBase
                        // id='search'
                        placeholder=''
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => { handleSearchBarText(e) }}
                        value={searchInputValue}
                        autoFocus={isPlaceholderVisible}
                    />

                    {(!isPlaceholderVisible) && <div className={classes.rolllerBox}>
                        {texts.map((item, index) => (
                            <div key={index} className={classes.rollerItem} style={{ animationDelay: `${(10 / 3) * index}s` }}>{item}</div>
                        ))}
                    </div>}
                </div>




                <SearchIconWrapper className={classes.centerFlex} style={{ justifyContent: 'flex-end' }}>
                    <SearchIcon
                        style={{ height: '50px', width: '30px', color: isDarkMode ? palette.white : palette.orange }}
                    />
                </SearchIconWrapper>

                {/* <CloseIconWrapper className={classes.centerFlex} style={{ justifyContent: 'flex-end' }}>
                    <CloseIcon color={isDarkMode ? palette.white : palette.orange} style={{ height: '50px', width: '25px' }} onClick={handleSearchBarClose} />
                </CloseIconWrapper> */}
            </Search>
            {
                (!isMobile && !isTablet) && openCategoryFilter && url.includes("/learn") && <CategoryFilterComp
                    openCategoryFilter={openCategoryFilter}
                    containerRef={containerRef}
                    toggleCategories={toggleCategories}
                    setToggleCategories={setToggleCategories}
                    filterBtnContainerRef={filterBtnContainerRef}
                    microkillCategoriesList={microkillCategoriesList}
                    selectedMicrokillCategories={selectedMicrokillCategories}
                    dispatch={dispatch}
                />
            }
        </SearchBoxWrapper>
    )
}

export default SearchBar;