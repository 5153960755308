import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";

const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const useStyle = makeStyles((theme) => ({
    BuildingBlocksRoot: {
        color: "#fff",
        boxSizing: "border-box",
        position: "relative",
        //transition: "0.7s",
        backgroundSize: '600% 100%',
        animation: '$animateGameLandingPage 5s ease infinite',
        height: '100%',
    },
    startPage: {
        height: "100%",

    },
    container: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        zIndex: '2',
        position: 'relative'
    },
    "@keyframes animateGameLandingPage": {
        '0%': { backgroundPosition: '0%' },
        '100%': { backgroundPosition: '100%' }
    },
    containerBox: {
        display: 'grid',
        height: '100%',
        gridAutoRows: '1.25% 25% 73.75%',
    },
    progressBarContainer: {
        position: "relative",
        height: "100%",
        backgroundColor: '#fff',
        zIndex: "2",
        width: 'calc(100% - 0px)',
        borderRadius: '0px',
        overflow: 'hidden',
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    // questionBox container
    qstnContainer: {
        cursor: 'default',
        height: "100%",
        maxHeight: "130%",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        height: "100%",
        fontSize: "1.2em",
        background: '#1D1D1D 0% 0% no-repeat padding-box',
        display: 'grid',
        gridAutoRows: '30% 70%',
    },
    assetBox: {
        display: "grid",
        gridTemplateColumns: "1fr  1fr 1fr",
        padding: "12px 0",
        alignItems: 'center',
        fontSize: '1.2em'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 'clamp(1rem, 1.2vw, 1.4rem)',
        },
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        justifySelf: "end"
    },
    icon: {
        width: '1em',
        height: '1em',
        margin: "0 5px"
    },
    timerIcon: {
        width: "1em",
        height: "1em",
        margin: "0 6px"
    },
    life: {
        fontWeight: '600',
        fontSize: '0.9rem',
    },
    time: {
        fontWeight: '600',
        fontSize: '0.9rem',
        minWidth: '2rem'
    },
    qstn: {
        transition: '.9s',
        padding: "2%",
        textAlign: "center",
        flexDirection: "column",
        scale: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        wordBreak: "break-word",
        fontSize: isHDScreen ? '0.6em' : '1em',
        opacity: '1',
        transition: '.8s',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: isHDScreen ? '0.75rem' : '1rem',
        },
    },
    gamePlayBox: {
        display: 'grid',
        gridAutoRows: '80% 20%',
        width: "100%",
        position: 'relative',
    },
    dropContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "flex-end",
        overflow: 'hidden',
        zIndex: '1',
        height: '100%',
        padding: '1rem',
        containerType: 'inline-size'
    },
    bottomContainer: {
        width: '100%'
    },
    "@keyframes rotateClockWise": {
        "0%": { transform: "translate(-50%, -50%) rotate(0deg)", },
        "100%": { transform: "translate(-50%, -50%) rotate(360deg)" },
    },
    "@keyframes rotateAntiClockWise": {
        "0%": { transform: "translate(-50%, -50%) rotate(360deg)", },
        "100%": { transform: "translate(-50%, -50%) rotate(0deg)" },
    },
    playPauseBtn: {
        width: '35%'
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        opacity: '1',
        transition: '.8s',
        position: 'relative',
        zIndex: '5',
        '& > *': {
            fontSize: '0.8em',
            padding: '0.2rem 0.6rem',
            border: 'none',
            background: '#D6619F',
            margin: '0 1rem',
            color: '#fff',
            borderRadius: '5px',
            width: '40%',
            minHeight: '40px',
            transition: '.5s',
            webkitTransition: '.5s',
        },
        '& > button': {
            cursor: 'pointer',
        }
    },
    optionButton: {
        background: "#FFF",
        color: "#1A3154",
        fontWeight: "bolder",
        minHeight: "2em",
        fontSize: "2em"
    },
    gradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        opacity: '0',
        transition: '.5s ease',
        zIndex: '1',
        backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
    },
    incorrectGradientOverlayAnimation: {
        animation: '$incorrectGradientOverlayAnimation 2s ease-in-out 0.1s 1 normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimation': {
        '0%': { opacity: '0' },
        '10%': { opacity: '1' },
        '80%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { opacity: "0" },
        "20%": { opacity: "1" },
        "50%": { opacity: "1" },
        "75%": { opacity: "1" },
        "100%": { opacity: '0' }
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },
    loadingText: {
        color: '#cb3ef3',
    },
    pauseOverlay: {
        height: 'inherit',
        width: 'inherit'
    },

    imagesBlocks: {
        width: "100%",
        position: "absolute",
        left: '0%',
        top: '-40%',
        opacity: "0",
        transition: "1s",
    },
    imagesAnimation: {
        opacity: '1',
        top: '0%',
        [theme.breakpoints.down('md')]: {
            top: '1%'
        },
        [theme.breakpoints.down('sm')]: {
            top: '0%',
        }
    },
    particalurImageAnimation: {
        opacity: '1',
        top: '0%',
        [theme.breakpoints.down('md')]: {
            top: '1%'
        },
        [theme.breakpoints.down('sm')]: {
            top: '0%',
        }
    },
    imagesContainer: {
        backgroundImage: "url(/images/gba/Building_Blocks_base_station.svg)",
        height: '60cqi',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '60cqi'
        },
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    correctIncorrectText: {
        fontFamily: 'MotionControl !important',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        zIndex: 2,
        transition: '0.5s',
        opacity: '0',
        width: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '2rem',
        display: 'flex',
        justifyContent: 'center',
    },
    thickText: {
        fontFamily: 'MotionControl',
        fontSize: '3em',
        // fontWeight: 900,
        transform: 'scale(0)',
        transformOrigin: 'center center',
        transition: 'transform 0.5s linear',
        position: 'absolute',
        color: theme.palette.white
    },
    muteSwitch: {
        right: '3%',
        bottom: '0px',
        transform: 'translateY(50%)',
        cursor: 'pointer',
        zIndex: '2',
        position: 'absolute',
    },
    playButton: {
        width: '40px',
        height: '40px',
        background: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        border: 'none',
        cursor: 'pointer',
    },
    playIcon: {
        width: '0',
        height: '0',
        borderLeft: '15px solid #5c4a45',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        position: 'relative',
        zIndex: '2',
    }
}));

export default useStyle;
