import axiosInstance from "../../utils/axios";
// import { getAllModules } from "./learn.actions";

export const getAllContentRepoData = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/repository?${params}`);
        return respData;
    } catch (err) {
        console.log("getAllContentRepoData:", err)
        // dispatch(fetchFail(err));
    }
}

export const markRepoViewed = async (repoId, signal) => {
    try {
        const respData = await axiosInstance.get(`/repository/${repoId}/view`, {signal});
        return respData;
    } catch (err) {
        console.log("markRepositoryViewed:", err)
    }
} 

export const markRepoShared = async (repoId, signal) => {
    try {
        const respData = await axiosInstance.get(`/repository/${repoId}/share`, {signal});
        return respData;
    } catch (err) {
        console.log("markRepositoryViewed:", err)
    }
}

export const markRepoDownloaded = async (repoId, signal) => {
    try {
        const respData = await axiosInstance.get(`/repository/${repoId}/download`, {signal});
        return respData;
    } catch (err) {
        console.log("markRepositoryViewed:", err)
    }
} 


