import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import BulletinBoard from "../../components/bulletin-board";
import { useSelector } from "react-redux";
import BulletinHeader from "../../components/bulletin-board/bulletin-header";
import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const Bulletins = () => {

    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        navigate({
            search: "?name=Bulletin Board"
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isTablet]);

    return (
        <div className={classes.root}>
            <BulletinHeader />
            <BulletinBoard />
        </div>
    )
}

export default Bulletins;

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            maxHeight: '100dvh',
            overflowY: 'hidden'
        },
        [theme.breakpoints.up("md")]: {
            maxHeight: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'row',
        }
    }
}))