import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import constants from "../../constants";
import { Height } from "@mui/icons-material";

const CRAD_BORDER_RADIUS = '0.4rem';

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        display: 'grid',
        gridTemplateRows: `5.5rem calc(100dvh - 4rem - 5.5rem)`,
        width: '100%',
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: '15% 85%',
            height: '100% !important',
            gridTemplateRows: 'none',
            width: '100%',
            background: `${theme.palette.mobileHeaderBg} !important`,
        }
    },
    viewFullScreen: {
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: '15% 85%',
            height: '100% !important',
            gridTemplateRows: 'none',
            width: '100%',
            background: `${theme.palette.mobileHeaderBg} !important`,
        }

    },
    circularLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
    }
}))

export default useStyles