import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';
const bgThemeColor = "#350050";
const voilet = "#4f0060";
const voilet2 = "#410058";
const voilet3 = "#4F0160";

const darkVoilet = "#2D0037"
const darkVoilet2 = "#080037"
const lightVoilet1 = "#692678"
const lightVoilet2 = "#7b4088"

const orange = "#F4511E";
const linearGradientPrimary = 'linear-gradient(rgb(79, 0, 96), rgb(8, 0, 55))';
const lightGrey1 = "#f6f6f6"
const lightGrey2 = "#f1f1f1"
const greyText1 = '#3a3a3a';
const greyText4 = '#484848';
const greyText2 = '#636363';
const greyText3 = '#cccccc';
const yellow = '#F5D03C';
const spotlightGreyBg = '#E1E1E1';
const errorColor = '#F63B34';
const ilaBg = 'rgb(91 52 154)'
const ilaQuestionHeadColor = 'rgb(91 52 154/ 10%)'
const ilaCorrect1 = '#238817';
const ilaCorrect2 = '#1a6611';
const ilaInCorrect1 = '#C6211B';
const ilaInCorrect2 = '#9b1813';
const ilaCardBlue = '#6C4996';
const lightGreen = '#5FE001';

export const lightPalette = {
  mode: 'light',
  black,
  white,
  bgThemeColor,
  orange,
  linearGradientPrimary,
  voilet: white,
  voilet2,
  voilet3,
  darkVoilet: lightGrey1,
  darkVoilet2: lightGrey2,
  lightVoilet1: white,
  lightVoilet2: lightGrey2,
  greyText1,
  greyText2,
  greyText3,
  greyText4,
  spotlightGreyBg,
  errorColor,
  ilaQuestionHeadColor,
  ilaBg,
  ilaCorrect1,
  ilaCorrect2,
  ilaInCorrect1,
  ilaInCorrect2,
  ilaCardBlue,

  lightGrey1,
  lightGrey2,
  lightVoilet1ToLightGrey1: lightGrey1,
  whiteToOrange: orange,
  blackToWhite: {
    color: black,
    bgColor: lightGrey1
  },
  progressBarBg: lightGrey2,
  fontColor: greyText4,
  headerBg: lightGrey1,
  mobileHeaderBg: white,
  spotLightBg: lightGrey1,
  mobileMainContainerBg: `linear-gradient(180deg, ${white}, ${lightGrey1})`,
  laBoxBg: lightGrey1,
  laNavBg: white,
  mobilePerfPageBg: lightGrey1,
  mobilePerfPageCardsBg: white,
  lightGreen,
  yellow,
  filterWhiteToORange: `invert(50%) sepia(61%) saturate(6061%) hue-rotate(351deg) brightness(99%) contrast(93%)`,
  darkVioletToLightGrey: lightGrey1,
  lightVioletToGrey: lightGrey1,

  primary: {
    main: orange,
    contrastText: white,
  },
  secondary: {
    contrastText: white,
    main: voilet,
  },
  default: {
    contrastText: white,
    main: orange,
  },
  White: {
    contrastText: '#FFF',
    main: greyText2,
  },
  grey: {
    contrastText: '#FFF',
    main: greyText3,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },

  gbabtn: {
    contrastText: '#000',
    main: yellow,
  },
  link: colors.blue[800],
  icon: '#F4511E',
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200],
  checkBox: {
    checked: orange,
    default: orange,
  },

}

const palette = {
  mode: 'dark',
  black,
  white,
  bgThemeColor,
  orange,
  linearGradientPrimary,
  voilet,
  voilet2,
  voilet3,
  darkVoilet,
  darkVoilet2: darkVoilet2,
  lightVoilet1,
  lightVoilet2,
  greyText1,
  greyText2,
  greyText3,
  greyText4,
  spotlightGreyBg,
  errorColor,
  ilaQuestionHeadColor,
  ilaBg,
  ilaCorrect1,
  ilaCorrect2,
  ilaInCorrect1,
  ilaInCorrect2,
  ilaCardBlue,


  lightGrey1,
  lightGrey2,
  fontColor: white,
  progressBarBg: white,
  headerBg: voilet,
  mobileHeaderBg: voilet,
  whiteToOrange: white,
  blackToWhite: {
    color: white,
    bgColor: black
  },
  lightVoilet1ToLightGrey1: lightVoilet1,
  spotLightBg: linearGradientPrimary,
  mobileMainContainerBg: `linear-gradient(180deg, #4f0060, #080037)`,
  mobilePerfPageBg: voilet,
  mobilePerfPageCardsBg: darkVoilet,
  laBoxBg: voilet,
  laNavBg: darkVoilet,
  lightGreen,
  yellow,
  filterWhiteToORange: `none`,
  darkVioletToLightGrey: voilet,
  lightVioletToGrey: lightVoilet1,

  primary: {
    main: orange,
    contrastText: white,
  },
  secondary: {
    contrastText: white,
    main: voilet,
  },
  default: {
    contrastText: white,
    main: orange,
  },
  White: {
    contrastText: '#FFF',
    main: greyText2,
  },
  grey: {
    contrastText: '#FFF',
    main: greyText3,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },

  gbabtn: {
    contrastText: '#000',
    main: yellow,
  },
  link: colors.blue[800],
  icon: '#F4511E',
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200],
  checkBox: {
    checked: orange,
    default: "#FFFFFF80",
  },
};

export default palette
