import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    videoContentWrapper: {
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        }
    },
    resetRotation: {},
}));

export default useStyles;
