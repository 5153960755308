import { makeStyles } from "@mui/styles";
import constants from "../../../constants";
import palette from "../../../theme/palette";
import { getHeaderHeight } from "../../../utils/commonUtil";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toolbar: {
        color: theme.palette.fontColor,
        // color: '#FFF',
        display: 'grid !important',
        gridTemplateColumns: '20% 50% 20% 10%',
        height: 'inherit',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '15% 70% 15%',
        },
        // justifyContent: 'space-between'
    },
    accountDetailBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    mobileDrawer: {
        zIndex: '9999 !important',
        '& .MuiDrawer-paper': {
            width: '100% !important',
            backgroundColor: `${palette.voilet} `
        }
    },
    headerBox2: {
        // height: getHeaderHeight(),
        display: 'flex'
    },
    appBarRoot: {
        zIndex: '9999',
        backgroundColor: `transparent !important`,
        // backgroundColor: `${theme.palette.voilet} !important`,
        height: 'inherit',
    },
    headerText: {
        fontWeight: 600
    },
    searchBox: {
        position: 'fixed',
        width: '100%',
        minHeight: '44px',
        height: '5%',
        top: '2%',
        padding: '0 1rem',
        zIndex: '2',
        // bottom: '-1rem',
        // top: '50%',
        left: '0%',
        // transform: 'translate(-50% , -50%)',
        // padding: '1rem 1rem 0.5rem 1rem',
        '& input': {
            width: '100%',
            height: '100%',
            padding: '0 2rem 0 2rem',
            outline: palette.orange,
            borderRadius: '4px',
            '&:focus': {
                border: `2px solid ${palette.orange}`,
            }
        },
        '& img': {
            opacity: '1',
            position: 'absolute',
            transform: 'translateY(-50%)',
        }
    },
    searchIcon: {
        left: '1.5rem',
        top: '50%',
    },
    closeIcon: {
        right: '1.5rem',
        top: '50%',
    },
    menuDropdown: {
        '& ul': {
            backgroundColor: `${palette.voilet} !important`,
        }
    },
    menuText: {
        color: '#fff !important',
        fontSize: '0.9rem !important',
        paddingLeft: '0.8rem'
        // fontFamily: 'Open sans !important'
    },
    dropDownIcon: {
        // paddingRight: '1rem'
    },
    avatarBox: {
        width: '50px',
        height: '50px',
        background: `${theme.palette.fontColor}4d`,
        borderRadius: '50%',
        color: palette.orange,
        fontWeight: '700',
        fontSize: '1.2rem',
        fontFamily: 'Open sans',
        cursor: 'pointer'
    }

}))

export default useStyles