import { makeStyles } from "@mui/styles";
const CRAD_BORDER_RADIUS = '0.4rem';

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    bulletinHeaderWrapper: {
        display: 'flex',
        margin: '0 0.8rem',
        overflowX: 'auto',
        height: '5.5rem',
        [theme.breakpoints.up("md")]: {
            display: 'grid',
            gridTemplateRows: '10% 90%',
            height: `calc(100dvh - 5rem)`
        },
    },
    timeContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: 'center',
        lineHeight: '1.1 !important',
        color: `${theme.palette.fontColor} !important`,
    },

    timeContainerClicked: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: 'center',
        lineHeight: '1.1 !important',
        color: `${theme.palette.orange} !important`,
        backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
    },

    dateContainer: {
        fontWeight: '800',
        fontSize: '2.5rem',
    },
    dayContainer: {
        fontWeight: '600',
        fontSize: 'larger',
    },
    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    card: {
        position: 'relative',
        cursor: 'pointer',
        borderRadius: `${CRAD_BORDER_RADIUS}`,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up("md")]: {
            borderRadius: `calc(${CRAD_BORDER_RADIUS} / 2)`,
        }
    },
    backBtn: {
        height: '100%',
        background: 'none !important',
        margin: '0.2rem',
    },
    backBtnText: {
        fontWeight: '700',
    },
    dateWrapper: {

        [theme.breakpoints.up("md")]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1rem',
            flexDirection: 'column',
            overflowY: 'scroll',
        }
    }
}));

export default useStyles;
