import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import CountUp from 'react-countup';

// context
import LearningAidContext from "../../context/learningAidContext";

// mui
import { Button, CircularProgress, Typography } from "@mui/material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// components
import LearningAidCardsContainer from "../../components/learning-aid-cards-container";
import DialogAlert from "../../components/dialog-alert";
import HeaderBackButton from "../../components/layout-elements/header-back-button";
import LaVoiceOverAudioPlayer from "../../components/la-voiceover-audio-player";

// redux
import { getChallengeData, startChallengeAttempt } from "../../redux/microskill/microskill.api";
import { getLearnerJourneyStatus, submitChallengeAttemptData, submitEndChallengeAttempt } from "../../redux/gba/gba.api";
import { openToast, setLearnerJourneyEnded } from "../../redux/common/common-slice";
import { setLaOnboardingScreen } from "../../redux/common/common-slice";

// utils
import isEmpty from "../../utils/isEmpty";
import commonUtil, { getHeaderHeight } from "../../utils/commonUtil";
import { isNativeApp } from '../../utils/commonUtil';
import eventBus from "../../utils/eventBus";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import palette from "../../theme/palette";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    height: '100%'
};

const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;

const LearningAid = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');
    const {
        addFiles,
        isFileViewerOpen,
        setOpenDocInIframe,
        setFileType,
        scoreBoxRef,
        scoreCounterTime,
        scoreIncrementRef,
        isClickEnabledRef,
        // isVoiceoverAudioPlayer, setIsVoiceoverAudioPlayer // voice over at la cards
    } = useContext(LearningAidContext)
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const viewLaOnboardingScreen = useSelector(state => state?.common?.viewLaOnboardingScreen);
    // const isNativeAppState = useSelector(state => state?.common?.isNativeApp);
    const userId = commonUtil.getUserId();
    const companyId = commonUtil.getCompanyId();
    const { microskillId, challengeId } = useParams();
    const learningAidInitialData = useRef();
    const [learningAidData, setLearningAidData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeSubIndex, setActiveSubIndex] = useState(0);
    const [subCardIndex, setSubCardIndex] = useState(0);
    const attemptedIdsRef = useRef([]);
    const [viewThumbnail, setViewThumbnail] = useState(false);
    const [showWinScreen, setShowWinScreen] = useState(false);
    const [attemptData, setAttemptData] = useState();
    const [viewFullScreen, setViewFullScreen] = useState(false);
    const [isChallengeSubmitted, setIsChallengeSubmitted] = useState(false);
    const [onBoardingScreenNo, setOnBoardingScreenNo] = useState(1);
    const [lastActiveIndex, setLastActiveIndex] = useState(0);
    const [lastActiveSubIndex, setLastActiveSubIndex] = useState(0);
    // const [laCardsLoadingProgress, setLaCardsLoadingProgress] = useState(0) // // to show progress of preloading la card images
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogueDiscardBtnName, setDialogueDiscardBtnName] = useState('');
    const [dialogueConfirmBtnName, setDialogueConfirmBtnName] = useState('');
    const [progressStep, setProgressStep] = useState(0);

    const scoreListRef = useRef(new Set());
    const scoreRef = useRef(0);
    const scoreValueRef = useRef();
    const timeRef = useRef(0);
    const headerTimeRef = useRef();
    const intervalRef = useRef();
    const currentTimeRef = useRef(Date.now());
    const finalChallengeDataRef = useRef();
    const ilaIndices = useRef([]);
    const showActiveCardRef = useRef();
    const elementCounterRef = useRef([]);
    const cardsViewedRef = useRef([]);
    const attemptDataRef = useRef({});

    // const lastActiveIndexRef = useRef(0);
    // const lastSubActiveIndexRef = useRef(0);
    const { t } = useTranslation()
    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    const minTimeSpentTimer = useRef();
    const minTimeSpentProgressTimerRef = useRef();

    useEffect(() => {

        if (!isEmpty(learningAidData) && !isChallengeSubmitted && !viewLaOnboardingScreen && !viewThumbnail) {
            // if (isMobile && !isEmpty(learningAidData) && !isChallengeSubmitted && !viewLaOnboardingScreen && !viewThumbnail) {


            const currentCard = learningAidData.Cards[activeIndex];
            const minTimeSpent = learningAidData.setting.minTimeSpent;

            if (minTimeSpent === 0) {
                return;
            }

            if (cardsViewedRef.current.length !== learningAidData.Cards.length) {
                return
            }
            if (cardsViewedRef.current[activeIndex] === true) {
                return
            }

            let oneSecProgress = 100 / minTimeSpent;
            let currentProgress = 0;
            let countDown = minTimeSpent
            setProgressStep(oneSecProgress);

            if (!isMobile) {
                handleChangeProgress(currentCard, 0, "", countDown)
            }

            // let currentProgressTimer = setInterval(() => {
            minTimeSpentProgressTimerRef.current = setInterval(() => {

                currentProgress += oneSecProgress;
                countDown -= 1
                handleChangeProgress(currentCard, currentProgress, "", countDown)
            }, 1000)
            minTimeSpentTimer.current = setTimeout(() => {
                clearTimeout(minTimeSpentTimer.current);
                clearInterval(minTimeSpentProgressTimerRef.current);
                minTimeSpentTimer.current = null;
                minTimeSpentProgressTimerRef.current = null;
                cardsViewedRef.current[activeIndex] = true;

            }, minTimeSpent * 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, learningAidData, isChallengeSubmitted, viewLaOnboardingScreen, viewThumbnail, cardsViewedRef.current])

    const handleChangeProgress = (currentCard, progress, bgColor = "", countDown = 0) => {

        if (isMobile) {

            let cardProgressElem = document.querySelector(`#card-progressbar-${currentCard.idMod}`);
            let bg_color = isEmpty(bgColor) ? progress <= 100 / 3 ? palette.ilaInCorrect1 : progress >= 99 ? palette.lightGreen : palette.gbabtn.main : bgColor;
            if (isEmpty(cardProgressElem)) return
            cardProgressElem.style.width = `${progress}%`;
            cardProgressElem.style.backgroundColor = bg_color;

        } else {

            let nextBtn = document.querySelector(`#next-btn`);
            let circularProgressBar = document.querySelector(`#circular-progressbar`);
            let circularProgressBarText = document.querySelector(`#circular-progressbar-text`);

            let circleForeground = document.querySelector(`#circle_foreground`);

            circularProgressBarText.innerHTML = countDown;

            circleForeground.setAttribute("stroke", "#ff00ff")

            if (countDown <= 0) {

                circularProgressBar.style.display = 'none';
                nextBtn.style.display = 'block';

            } else {

                nextBtn.style.display = 'none';
                circularProgressBar.style.display = 'flex';

            }
        }
    }

    // useEffect(() => {
    //     if(showToast) {
    //         setTimeout(() => {
    //             setShowToast(false);
    //         }, (learningAidData.setting.minTimeSpent) / 3 * 1000  || 2000);
    //     }
    // }, [showToast])

    useEffect(() => {
        eventBus.on(BACK_BTN_EVENT, backBtnListnerFunction);
        // window.onbeforeunload = function (e) {
        //     e.returnValue = 'You have unsaved data. do you really want to reload?';
        //     return '';
        // }
        return () => {
            eventBus.remove(BACK_BTN_EVENT, backBtnListnerFunction);
            // dispatch(handleGbaScreenState(GBA_SCREEN_STATE.START));
            // window.onbeforeunload = "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backBtnListnerFunction = async (data) => {

        if (!isEmpty(intervalRef.current)) clearInterval(intervalRef.current);

        if (activeIndex !== learningAidData?.Cards?.length - 1) {
            submitEndChallengeAttempt({
                microskillId,
                challengeId,
                timespent: timeRef.current,
                attemptId: attemptDataRef.current.id,
                status: constants.GBA_ILA_STATUS.exited,
                completion: false
            });
        }

        if (journeyId && journeyId !== 'null') {
            let reqData = {
                microskillId,
                challengeId,
                journeyId: journeyId
            }
            getLearnerJourneyStatus(reqData).then(res => {
                dispatch(setLearnerJourneyEnded(res?.data?.data?.journeyEnded))
            })
        }
        navigate(`/challenge-list/${microskillId}`);
    }

    const handleBackButtonClick = () => {
        eventBus.dispatch(BACK_BTN_EVENT, {});
    }

    // useEffect(() => {
    //     const pauseInterval = setInterval(() => {
    //         if (window.pausedApp) {
    //             handleBackButtonClick();
    //         }
    //     }, 1000); // Check every second

    //     return () => clearInterval(pauseInterval); // Cleanup interval on component unmount
    // }, []);



    useEffect(() => {
        const killedInterval = setInterval(() => {
            if (window.killedApp) {
                handleBackButtonClick();
            }
        }, 1000); // Check every second

        return () => clearInterval(killedInterval); // Cleanup interval on component unmount
    }, []);

    const handleKeyDown = (event) => {
        switch (event.keyCode) {
            case 37:
                handlePrevClick();
                break;
            case 39:
                handleNextBtnClick();
                break;
            case 27:
                // normal screen
                setViewFullScreen(false);
                break;
            case 122:
                // full screen
                setViewFullScreen(true);
                break;
            default:
                break;
        }
    };

    const handleMTRRgameData = (data) => {
        let tempArray = [];
        data.forEach(d => {
            let questionData = { ...d, answers: d.answers[0], isViewed: false };
            let temp = {
                Context: d.Context,
                questions: [questionData],
                score: d.score,
                id: d.id,
                mandatory: d.mandatory,
                sequence: d.sequence,
                cardId: d.cardId,
                designType: d.designType,
                // questionNo: d.questionNo,
            };
            let sameContextIndex = tempArray.findIndex(m => m.Context.id === d.Context.id);
            if (isEmpty(tempArray) || sameContextIndex === -1) tempArray.push(temp)
            else {
                let questions = tempArray[sameContextIndex].questions;
                let sameQuestionIndex = questions.findIndex(q => q.Context.id === d.Context.id);
                if (sameQuestionIndex !== -1) {
                    tempArray[sameContextIndex].questions.push(questionData);
                }
            }
        });
        return tempArray
    };

    const getScoreForIla = (data) => {
        let score = data.score;
        if (data.designType === 'ILA_MTRR_1' || data.designType === 'ILA_MTRR_2') {
            score = data.score * data.questions.length;
        }
        return score
    };

    const focusRootDiv = () => {
        // focus div for keyboard events
        let rootElement = document.getElementById('learning-aid-root');
        rootElement.focus();
    };

    const getChallengeDataAsync = async (challenge_Id) => {

        let respData = await getChallengeData(microskillId, challengeId);

        let userMicroskillChallenge = respData.data.data.userMicroskillChallenge;
        setIsChallengeSubmitted(userMicroskillChallenge?.completion);

        if (isEmpty(respData.data.data.gameData.Cards)) {
            setIsDialogOpen(true);
            setDialogueConfirmBtnName('Okay');
            setDialogueDiscardBtnName('');
            setDialogContent("No data found for this challenge");
            return;
        }

        try {
            if (respData.status === 200 || respData.status === 201) {
                // let res = sampleData;
                const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
                setAttemptData(attemptResp.data.data);
                // await new Promise(resolve => {
                attemptDataRef.current = attemptResp.data.data;

                // resolve();
                // })
                if (attemptResp.data.data.attemptNo > 1) {
                    dispatch(setLaOnboardingScreen(false));
                }
                let res = respData.data.data.gameData;
                // setLearningAidSettings({
                //     minTimeSpent: 10,
                // })
                res.setting = isEmpty(res.setting) ? { minTimeSpent: 0 } : { ...res.setting, minTimeSpent: isEmpty(res.setting.minTimeSpent) ? 0 : res.setting.minTimeSpent };
                // res.setting = { minTimeSpent: 25 }
                let tempResp = [];
                let questionCount = 0;
                let maxScore = 0;
                learningAidInitialData.current = respData.data.data.gameData;
                let sortedCard = [...res.Cards.sort((a, b) => a.sequence - b.sequence)];
                cardsViewedRef.current = new Array(sortedCard.length).fill((!isEmpty(res.setting) && !isEmpty(res.setting.minTimeSpent) && res.setting.minTimeSpent > 0 && !userMicroskillChallenge?.completion) ? false : true); // set cards viewed array to false initially

                // // // // <=== loading images before challange start code starts ====>
                function loadImage(url) {
                    return new Promise(resolve => {
                        const image = new Image();
                        image.src = url;
                        image.addEventListener("error", () => {
                            resolve(image);
                        })
                        image.addEventListener('load', () => {
                            resolve(image);
                        });
                    });
                }

                const imagePromises = [];

                sortedCard.forEach((item, index) => {
                    let curCard = item.card;
                    if (isEmpty(curCard)) return;

                    if (!isEmpty(curCard.backgroundImageURL)) { // check for background image of current card
                        if (index <= 3) { // load only first 3 card's images
                            imagePromises.push(loadImage(curCard.backgroundImageURL));
                        } else {
                            loadImage(curCard.backgroundImageURL);
                        }
                    }

                    let content = curCard.contents.filter(item => item.name === "IMAGE");
                    if (!isEmpty(content)) {
                        let innerItem = content[0];
                        if (!innerItem.content.includes('.gif')) {

                            if (index <= 3) { // load only first 3 card's images
                                imagePromises.push(loadImage(innerItem.content));
                            } else {
                                loadImage(innerItem.content)
                            }
                        }
                    }
                    // Update progress

                    // setLaCardsLoadingProgress((prev) => {
                    //     const progress = (index / totalImages) * 100;
                    //     return progress >= prev ? progress : prev;
                    // })
                })

                // Wait for all image rendering promises to resolve
                await Promise.all(imagePromises);

                // // // // <=== loading images before challange start code ends ====>

                sortedCard.forEach(item => {
                    item.idMod = item.id;
                    item.ila = [];
                    item.subCards = [];

                    // this code handles copied microskill issue for null gameType
                    if (isEmpty(item.gameType) && item.Questions.length > 0) {
                        item.gameType = item.Questions[0].type
                    }
                    // this code handles copied microskill issue for null gameType

                    // check for ILA
                    if (!isEmpty(item.gameType)) {
                        if (item.gameType === 'MTRR') {
                            item.Questions = handleMTRRgameData(item.Questions)
                        }
                        item.Questions = item.Questions.sort((a, b) => a.sequence - b.sequence);
                        const ilaTypes = new Set(item.Questions.map(obj => obj.designType));
                        item.ila = [];
                        ilaTypes.forEach(function (value) {
                            let filteredList = item.Questions.filter(d => d.designType === value);
                            filteredList.forEach(m => {
                                questionCount++;
                                m['questionNo'] = questionCount;
                                maxScore += getScoreForIla(m);
                                // maxScore += m.score;
                            })
                            item.ila = [...item.ila, {
                                Questions: filteredList,
                                designType: value,
                                cardType: item.cardType,
                                context: item.context,
                                gameType: item.gameType,
                                idMod: `${item.id}-${value}`,
                                id: `${item.id}`,
                                sequence: item.sequence,
                                thumbImage: item.thumbImage,
                            }];
                        });

                        if (isEmpty(item.context)) {
                            item.subCards = [
                                { thumbImage: item.thumbImage },
                                { ila: item.ila, }
                            ];
                        } else {
                            item.subCards = [
                                { thumbImage: item.thumbImage },
                                { context: item.context },
                                { ila: item.ila, }
                            ];
                        }

                        let iterator = ilaTypes.values();
                        let firstElement = iterator.next().value;
                        if (ilaTypes.size === 1) item.designType = firstElement;
                    }
                });
                res.maxScore = maxScore;
                tempResp = [...res.Cards];

                // check if a data index contains more than 1 type of ILA then replace that particular index with ILA(s)
                res.Cards.forEach(d => {
                    if (d.ila.length > 1) {
                        let index = tempResp.findIndex(m => m.id === d.id);
                        tempResp.splice(index, 1, ...d.ila);
                    }
                })

                tempResp.forEach((item, i) => {
                    if (!isEmpty(item.gameType)) ilaIndices.current.push(i)
                });

                // focus div for keyboard events
                focusRootDiv();
                setLearningAidData({ ...res, Cards: tempResp })
            }
        } catch (err) {
            openToast({ isToastOpen: true, toastMessage: 'Something went wrong', toastSeverity: 'error' })
        }
    };

    const setCardCountOnElementsNew = () => {
        elementCounterRef.current = [];
        if (!isEmpty(elementCounterRef.current)) return
        let parent = document.querySelector('#thumbCardScrollBox');
        let nodeCount = 0;
        parent.childNodes.forEach(node => {
            if (node.childNodes.length > 1) {
                node.childNodes.forEach(child => {
                    nodeCount++;
                    elementCounterRef.current.push({ id: child.id, count: nodeCount })
                })
            } else {
                nodeCount++;
                elementCounterRef.current.push({ id: node.lastChild.id, count: nodeCount })
            }
        })
    }

    useEffect(() => {
        if (!isEmpty(learningAidData)) {
            setCardCountOnElementsNew();
            // add 1st index to the analytics for the 1st time.
            let currentTime = Date.now();
            currentTimeRef.current = currentTime;
        }
    }, [learningAidData])

    useEffect(() => {
        if (!isEmpty(learningAidData)) {
            const currentCard = learningAidData.Cards[activeIndex];
            const subCardsArray = currentCard?.subCards;
            const subCard = subCardsArray[activeSubIndex];
            let cardType = isEmpty(subCard) ? 'thumbImage' : isEmpty(Object.keys(subCard)[0]) ? 'context' : Object.keys(subCard)[0];

            let parent = document.querySelector('#thumbCardScrollBox');
            let child2 = document.querySelector(`#card-${cardType}-${currentCard.idMod}`);

            if (isEmpty(child2) && cardType === 'ila') {
                child2 = document.querySelector(`#card-${'context'}-${currentCard.idMod}`);
            }

            let cardCount2 = elementCounterRef.current.find(e => e.id === child2.id);
            let cardCount = cardCount2.count;
            // let cardCount = getCardCount(activeIndex, activeSubIndex);
            if (isEmpty(parent) || isEmpty(child2)) return
            const computedStyles = window.getComputedStyle(child2);
            const clientHeightWithMargin = child2.clientHeight + parseInt(computedStyles.marginTop) + (parseInt(computedStyles.marginBottom) / 1.7);

            if (!isMobile) {
                const distance = (clientHeightWithMargin * cardCount) - parent.offsetTop;
                const center = parent.offsetHeight / 2;
                parent.scrollTo({
                    top: distance - center,
                    left: 0,
                    // behavior: 'smooth'
                });
            } else {
                // const distance = (clientWidthWithMargin * cardCount) - parent.offsetTop;
                // const center = parent.clientWidth / 2;
                // parent.scrollTo({
                //     top: 0,
                //     left: distance - center,
                //     // behavior: 'smooth'
                // });
                child2.scrollIntoView({ block: 'center', inline: 'center' });
                // child2.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningAidData, showActiveCardRef.current]);

    const setDocumentData = () => {
        let cardDetails = learningAidData.Cards[activeIndex].card;
        if (!isEmpty(cardDetails.link) && cardDetails.link.type === 'document') {
            setFileType('document');
            setOpenDocInIframe(false);
            addFiles(cardDetails.link.value[0]);
        } else if (!isEmpty(cardDetails.link) && cardDetails.link.type === 'link') {
            setFileType('link');
            let fileType = commonUtil.getFileType(cardDetails.link.value);
            if (isEmpty(fileType)) {
                addFiles({
                    type: 'link',
                    path: cardDetails.link.value,
                })
                return setOpenDocInIframe(true);
            }
            else {
                setFileType('link');
                addFiles({
                    type: 'link',
                    path: cardDetails.link.value,
                    id: Date.now(),
                    name: cardDetails.link.value,
                    mimeType: fileType
                })
            }
        } else {
            setOpenDocInIframe(false);
            addFiles();
        }
    }

    useEffect(() => {
        isClickEnabledRef.current = true;
        showActiveCardRef.current = "currentCard-" + activeIndex + "-" + activeSubIndex;
        if (isEmpty(learningAidData) || isFileViewerOpen) return
        setDocumentData();
        focusRootDiv();
        if (activeIndex > lastActiveIndex) setLastActiveIndex(activeIndex);
        if (activeSubIndex > lastActiveSubIndex) setLastActiveSubIndex(activeSubIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, activeSubIndex]);

    useEffect(() => {
        if (isEmpty(learningAidData) || isFileViewerOpen) return
        let currentTime = Date.now();
        let activeCard = learningAidData.Cards[lastActiveIndex];

        if (isEmpty(activeCard)) return
        let tempData = {
            cardId: activeCard.id,
            qId: activeCard.id,
            t: Math.round((currentTime - currentTimeRef.current) / 1000),
        };
        handleChallengeAttemptData(tempData);
        currentTimeRef.current = currentTime;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningAidData, isFileViewerOpen, activeIndex]);

    useEffect(() => {
        if (isEmpty(learningAidData) || isFileViewerOpen) return
        let currentTime = Date.now();
        let activeSubCard = learningAidData.Cards[activeIndex].subCards;

        if (isEmpty(activeSubCard)) return
        if (activeSubIndex === 0 || Object.keys(activeSubCard[activeSubIndex - 1]) === "ila") return
        let tempData = {
            cardId: learningAidData.Cards[activeIndex].id,
            qId: learningAidData.Cards[activeIndex].id,
            t: Math.round((currentTime - currentTimeRef.current) / 1000),
            answers: [`${Object.keys(activeSubCard[activeSubIndex - 1])[0]}`]
        };
        handleChallengeAttemptData(tempData);
        currentTimeRef.current = currentTime;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningAidData, isFileViewerOpen, activeSubIndex]);

    useEffect(() => {
        (async () => {
            if (!isEmpty(challengeId)) {
                resetLearningAidData();
                await sleep(50)
                getChallengeDataAsync(challengeId);
            }
            return () => {
                clearInterval(intervalRef.current)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeId]);

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            timeRef.current += 1;
            if (!isEmpty(headerTimeRef.current)) {
                headerTimeRef.current.innerHTML = commonUtil.getMinutesFromSeconds(timeRef.current)
            }
        }, 1000);
    };

    useEffect(() => {
        if (!isMobile) setViewThumbnail(false);
        if (!isMobile) dispatch(setLaOnboardingScreen(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    const runCounter = (start, end, duration) => {
        const counterElement = scoreValueRef.current;
        const range = end - start;
        const intervalVal = (duration) / range;

        let current = start;
        const interval = setInterval(() => {
            if (current >= end) {
                clearInterval(interval);
                counterElement.textContent = current;
                return
            }
            current += 1;
            counterElement.textContent = Math.round(current);
        }, intervalVal);
    }

    const addScore = async (score, isCorrect) => {
        return new Promise(async (resolve, reject) => {
            let scoreValue = 0;
            scoreListRef.current.forEach(item => {
                scoreValue += parseInt(item.split('-')[1])
            });
            scoreRef.current = scoreValue;
            scoreIncrementRef.current = score;

            if (!isEmpty(scoreBoxRef.current)) scoreBoxRef.current.style.opacity = '1';

            if (!isEmpty(scoreValueRef.current)) {
                await sleep(scoreCounterTime / 2);
                if (isCorrect) runCounter(scoreRef.current - scoreIncrementRef.current, scoreValue, scoreCounterTime)
                else {
                    const counterElement = scoreValueRef.current;
                    counterElement.textContent = scoreValue;
                }
                resolve();
                await sleep(scoreCounterTime);
                setTimeout(() => {
                    if (!isEmpty(scoreBoxRef.current)) scoreBoxRef.current.style.opacity = '0';
                }, scoreCounterTime);
            }
        })
    };

    useEffect(() => {
        if (!isEmpty(learningAidData)) {
            setDocumentData()
        }
        startTimer();
        if (activeIndex === 0 && !isEmpty(learningAidData)) {
            let activeCard = learningAidData.Cards[activeIndex];

            if (!isEmpty(activeCard?.subCards)) {
                setActiveSubIndex(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningAidData]);

    const checkCardNavigation = () => {
        return (showWinScreen || isFileViewerOpen || viewLaOnboardingScreen)
    }

    const handleNextBtnClick = () => {
        if (checkCardNavigation()) return;

        if (!isEmpty(minTimeSpentTimer.current) && cardsViewedRef.current[activeIndex] === false) {
            // if (isMobile && !isEmpty(minTimeSpentTimer.current) && cardsViewedRef.current[activeIndex] === false) {
            if (isMobile) showWarningToast()
            return;
        } // do not allow to navigate next card if minimum time spent timer is running

        let activeCard = learningAidData.Cards[activeIndex];
        let activeSubCard = learningAidData.Cards[activeIndex].subCards;

        if (isEmpty(activeCard.gameType)) {
            handleNext();
        } else {
            let activeSubKey = Object.keys(activeSubCard[activeSubIndex])[0];
            if (activeSubIndex === activeSubCard.length - 1) {
                // change logic here for different types of ila in same card
                if (activeSubKey !== 'ila') {
                    handleNext();
                } else if (isChallengeSubmitted) {
                    handleNext();
                }
            } else {
                if (activeSubKey === 'context') {
                    if (subCardIndex < activeSubCard[activeSubIndex].context.length - 1) {
                        setSubCardIndex(prev => prev + 1);
                    } else {
                        setActiveSubIndex(prev => prev + 1);
                    }
                } else {
                    setSubCardIndex(0);
                    setActiveSubIndex(prev => prev + 1);
                }
            }
        }
    };

    const handlePrevClick = () => {
        if (checkCardNavigation() || (activeIndex === 0 && activeSubIndex === 0)) return

        // fix to prevent previous button click when ILA present at first card
        if (activeIndex === 0) {
            setActiveSubIndex(0)
            return
        }

        if (!isEmpty(minTimeSpentTimer.current) && !isEmpty(minTimeSpentProgressTimerRef.current) && cardsViewedRef.current[activeIndex] === false) {
            // showWarningToast()
            // return;
            clearTimeout(minTimeSpentTimer.current);
            clearInterval(minTimeSpentProgressTimerRef.current);
            minTimeSpentTimer.current = null;
            minTimeSpentProgressTimerRef.current = null;
            handleChangeProgress(learningAidData.Cards[activeIndex], 0, 'transparent')
            cardsViewedRef.current[activeIndex] = false;
        } // do not allow to navigate previous card if minimum time spent timer is running

        if (viewThumbnail) setViewThumbnail(false); // close thumbnail view on next card click

        let currentCard = learningAidData.Cards[activeIndex];
        let currentSubCard = currentCard.subCards;

        // handling ila / context active card on prev btn click
        if (!isEmpty(currentSubCard) && currentSubCard.length > 1) {

            if (activeSubIndex === 1) {
                setActiveSubIndex(0);
            }
            else {
                if (!isEmpty(currentSubCard)) {

                    let activeSubKey = Object.keys(currentSubCard[activeSubIndex])[0];
                    if (activeSubKey === 'ila') {
                        setActiveSubIndex(prev => prev - 1);
                    } else {
                        setActiveSubIndex(0); // this is to fix VoiceOver not working properly on LA cards when attempting ILA or coming back from next card
                        // setActiveSubIndex(currentSubCard.length - 1);
                        setActiveIndex(prev => prev - 1);
                    }
                } else {
                    // setActiveSubIndex(0);
                    setActiveSubIndex(currentSubCard.length - 1);
                    setActiveIndex(prev => prev - 1);
                }
            }
        } else {

            setActiveIndex(prev => prev - 1);
            let prevCard = learningAidData.Cards[activeIndex - 1];
            let prevSubCard = prevCard.subCards;
            if (!isEmpty(prevSubCard) && prevSubCard.length > 1) {
                // setActiveSubIndex(0);
                // this is to fix while going back from the next to ILA card it will the to LA not ILA
                setActiveSubIndex(() => (isMobile || isTablet) ? 0 : prevSubCard.length - 1);
            }
        }

    };

    const showWarningToast = (message = "") => {
        let toastMessage = isEmpty(message) ? "Please wait for timer to finish before moving ahead!" : message;
        let toastElement = document.getElementById('customToastId');
        let toastTextElement = document.getElementById('customToastTextId');
        toastTextElement.innerHTML = toastMessage;
        toastElement.style.transform = `translate(-50%, 0%)`;

        setTimeout(() => {
            toastElement.style.transform = `translate(-50%, -150%)`;
        }, 3 * 1000)
    };

    const handleNext = async () => {
        if (checkCardNavigation()) return;
        // if (isMobile && !isEmpty(minTimeSpentTimer.current)) {
        //     showWarningToast()
        //     return;
        // } // do not allow to navigate next card if minimum time spent timer is running

        if (activeIndex === learningAidData.Cards.length - 1) {
            clearInterval(intervalRef.current);
            setShowWinScreen(true);
            submitEndChallengeAttempt({ ...finalChallengeDataRef.current, timespent: timeRef.current, scored: scoreRef.current, completion: true, status: "COMPLETED" })
            if (!isEmpty(journeyId)) {
                let reqData = {
                    microskillId,
                    challengeId,
                    journeyId
                }
                getLearnerJourneyStatus(reqData).then(res => {
                    dispatch(setLearnerJourneyEnded(res?.data?.data?.journeyEnded))
                })
            }
            // dispatch(setLearnerJourneyEnded(endChallengeResp?.data?.data?.learnerJourneyEnded));
            return
        }

        if (viewThumbnail) setViewThumbnail(false); // close thumbnail view on next card click

        let activeCard = learningAidData.Cards[activeIndex];
        let activeSubCard = learningAidData.Cards[activeIndex].subCards;

        setActiveSubIndex(0);
        setSubCardIndex(0);

        if (isEmpty(activeSubCard)) {
            // handleChallengeAttemptData({
            //     cardId: activeCard.id,
            //     t: Math.round((currentTime - currentTimeRef.current) / 1000),
            // });
            // currentTimeRef.current = currentTime;
        }

        if (isEmpty(activeCard.gameType)) {
            setActiveIndex(prev => prev + 1);
        } else {
            if (activeSubIndex === activeSubCard.length - 1) {
                setActiveIndex(prev => prev + 1);
            } else {
            }
        }
    };

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {

        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        }

        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                attemptNo: attemptDataRef.current.attemptNo,
                attemptId: attemptDataRef.current.id,
                // attemptNo: attemptData.attemptNo,
                // attemptId: attemptData.id,
                companyId: companyId,
                userId: userId,
                questions: [tempQuestionObj],
                completion: true,
                timespent: timeRef.current,
                // timespent: totalTimeSpent,
                scored: 0,
            }
        } else {
            let foundIndex = finalChallengeDataRef.current.questions.findIndex(d => d.questionId === tempQuestionObj.questionId);
            // let foundIndex = finalChallengeDataRef.current.questions.findIndex(d => d.cardId === tempQuestionObj.cardId);
            if (foundIndex !== -1) {
                finalChallengeDataRef.current.questions[foundIndex] = tempQuestionObj
            } else {
                finalChallengeDataRef.current.questions.push(tempQuestionObj)
            }
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                timespent: totalTimeSpent,
            }
        }

    };

    const handleChallengeAttemptData = (params) => {
        console.log("handleChallengeAttemptData");

        let { qId, aId, isCorrect, t, skillId, contextId, cardId, answers = [] } = params;

        let questionObj = {
            questionId: qId,
            cardId: cardId,
            answers: answers,
            // questionId: qId,
            // contextId: contextId,
            // skillId: skillId,
            // answers: [{
            //     answerId: aId,
            //     correct: isCorrect
            // }],
            timespent: t
        };

        if (!isEmpty(qId)) {
            questionObj[`answers`] = [{
                answerId: aId,
                correct: isCorrect
            }]
        }

        // if (isEmpty(attemptDataRef.current)) return;

        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptDataRef.current.id,
            // attemptId: attemptData.id,
            questions: [questionObj]
        }

        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId);
    };

    const handleCardClick = (item, i, type) => {
        if (checkCardNavigation()) return
        let isLocked = checkCardBlur(i);
        if (isLocked) return

        const currentCard = item;
        const currentSubCardList = currentCard?.subCards;
        let indexValue = currentSubCardList.findIndex(d => (Object.keys(d).includes(type)));
        // lastActiveIndexRef.current = activeIndex;
        // lastSubActiveIndexRef.current = activeSubIndex;
        setActiveIndex(i);
        if (isEmpty(currentSubCardList)) {
            setActiveSubIndex(0);
        } else {
            setActiveSubIndex(indexValue);
        }
    };

    const checkCardBlur = (index, type, item) => {
        if (isChallengeSubmitted) return false;
        /*************** Last logic ****************/
        // let closetIlaIndex = commonUtil.getGreatestClosetNumberFromAnArray(ilaIndices.current, activeIndex);
        // if (isEmpty(closetIlaIndex)) return false
        // if (activeIndex === index && type === 'ila') {
        //     return true
        // } else {
        //     return closetIlaIndex < index;
        // }
        /*************** Last logic ****************/


        /*********** do not delete this code ************/
        // if (activeIndex < lastActiveIndex) return false
        const currentCard = learningAidData.Cards[activeIndex];
        const currentSubCardList = currentCard.subCards;
        if (index <= lastActiveIndex) return false

        if (!isEmpty(currentSubCardList) && !isEmpty(currentSubCardList[activeSubIndex + 1]) && Object.keys(currentSubCardList[activeSubIndex + 1]).includes(type)) {
            return index === activeIndex;
        } else {
            return index > activeIndex;
        }
        /*********** do not delete this code ************/
    };

    const checkLockedCard = (index, type) => {
        // Exit early if the challenge is submitted
        if (isChallengeSubmitted) return false;

        // Get the current and next card data
        const currentCard = learningAidData.Cards[activeIndex];
        const nextCard = learningAidData.Cards[activeIndex + 1];
        const actualCard = learningAidData.Cards[index];

        // Early exit if there is no next card
        if (isEmpty(nextCard)) return;
        // if (index <= lastActiveIndex) return false

        const currentSubCardList = currentCard?.subCards || [];
        const actualSubCardList = actualCard?.subCards || [];

        // Check for non-empty sub-cards and activeSubIndex logic
        const isTypeInNextSubCard = (subCardIndex) =>
            !isEmpty(actualSubCardList[subCardIndex]) &&
            Object.keys(actualSubCardList[subCardIndex]).includes(type);

        if (!isEmpty(actualSubCardList)) {
            if ((activeSubIndex === 0 && isTypeInNextSubCard(activeSubIndex + 1)) ||
                (activeSubIndex === 1 && isTypeInNextSubCard(activeSubIndex))
            ) {
                if (type === 'ila' || type === 'context') return true;
                return index === activeIndex;
            }
        }

        // Handle currentSubCardList being empty or with 'ila' key
        if (isEmpty(currentSubCardList)) {
            return activeIndex + 1 === index && type === 'ila';
        }

        if (Object.keys(currentSubCardList[activeSubIndex] || {}).includes('ila')) {
            return activeIndex + 1 === index && type === 'ila';
        }

        // Default return (undefined)
    };

    const checkBorder = (i, type) => {
        const currentCard = learningAidData.Cards[activeIndex];
        const currentSubCardList = currentCard.subCards;
        let style = {};
        // let minTimeSpentProgress = 0
        // setInterval(()=> {
        //     minTimeSpentProgress += 10
        // }, 3 * 1000)


        if (!checkCardBlur(i, type) && i === activeIndex) {
            if (i === activeIndex) {
                if (!isEmpty(currentSubCardList) && !isEmpty(currentSubCardList[activeSubIndex])) {
                    style = Object.keys(currentSubCardList[activeSubIndex]).includes(type)
                        ? (viewThumbnail || !isMobile ? { border: `3px solid ${palette.orange}` } : learningAidData.setting.minTimeSpent > 0 && type !== 'ila' && !isChallengeSubmitted ? { width: '2.5rem', backgroundColor: cardsViewedRef.current[activeIndex] === true ? palette.orange : '' } : { backgroundColor: palette.orange })
                        // ? (viewThumbnail || !isMobile ? { border: `3px solid ${palette.orange}` } : { backgroundColor: palette.orange})
                        :
                        (type === 'context' && Object.keys(currentSubCardList[activeSubIndex]).includes('ila'))
                            ? { border: `3px solid ${palette.orange}` } : null;

                } else {
                    style = viewThumbnail || !isMobile ? { border: `3px solid ${palette.orange}` } : learningAidData.setting.minTimeSpent > 0 && !isChallengeSubmitted ? { width: '2.5rem', backgroundColor: cardsViewedRef.current[activeIndex] === true ? palette.orange : '' } : { backgroundColor: palette.orange }
                }
            }
            return { ...style };
        }
    };

    const handleViewThumbnail = () => {
        setViewThumbnail(prev => !prev)
    };

    const handleConfirmBtnClick = () => {
        setIsDialogOpen(false);
        navigate(-1);
    };

    const handleSkipButtonClick = () => {
        handleNext();
    };

    const handleOnboardingScreenClick = (no) => {
        if (no + 1 === (isMobile ? 5 : 2)) {
            setActiveIndex(0);
            dispatch(setLaOnboardingScreen(false));
            return
        }
        setOnBoardingScreenNo(no + 1);
        handleNext();
    };

    const resetLearningAidData = () => {
        setActiveIndex(0);
        setActiveSubIndex(0);
        setShowWinScreen(false);
        setSubCardIndex(0);
        scoreListRef.current = new Set();
        scoreValueRef.current = null;
        scoreRef.current = 0;
        timeRef.current = 0;
        setProgressStep(0);
        cardsViewedRef.current = [];
        setShowWinScreen(false);
        setLearningAidData(null)
        attemptDataRef.current = null;
        finalChallengeDataRef.current = null;
        setLastActiveIndex(0);
        // setAttemptData({})
    }

    // components
    const LockedOverlay = ({ index, type, item }) => {
        let isBlur = checkCardBlur(index, type, item);
        let isLocked = checkLockedCard(index, type, isBlur);
        const showLockIcon = !isChallengeSubmitted && type === 'ila' && index >= lastActiveIndex &&
            (activeIndex < index || (activeIndex === index && activeSubIndex === 0));

        const showUnlockIcon = (isChallengeSubmitted && type === 'ila') ||
            (!isChallengeSubmitted && type === 'ila' && (index < lastActiveIndex ||
                (activeIndex > index || (activeIndex === index && activeSubIndex === 1))));

        const isBlurCondition = !isChallengeSubmitted && (index > lastActiveIndex || (type === 'ila' && index === lastActiveIndex && activeSubIndex === 0));

        const overlayStyle = {
            backdropFilter: isBlurCondition ? 'blur(4px)' : null,
            backgroundColor: isBlurCondition ? '#0000003d' : null
        };

        return (
            viewThumbnail || (!isMobile && !isTablet) ? (
                <div className={`${classes.lockOverlay} ${classes.centerFlex}`}>
                    <div className={`${classes.overlay}`} style={overlayStyle} />

                    {showLockIcon && (
                        <img src="/images/icons/lock.png" alt="lock" width={isMobile ? 13 : 20} className={classes.lockImage} />
                    )}

                    {showUnlockIcon && (
                        <img src="/images/icons/unlocked_white.png" alt="lock" width={isMobile ? 13 : 20} className={classes.lockImage} />
                    )}
                </div>
            ) : (
                <>
                    {showLockIcon && (
                        <img src="/images/icons/lock.png" alt="lock" width={11} className={classes.lockImageOnBar} />
                    )}
                    {showUnlockIcon && (
                        <img src="/images/icons/unlocked_white.png" alt="lock" width={(isMobile || isTablet) ? 13 : 20} className={classes.lockImageOnBar} />
                    )}
                </>
            )
        );

    };

    const IlaImage = ({ data }) => {
        let imageName = !isEmpty(data) ? `/images/learning-aid/${data}.png` : `/images/learning-aid/ILA_TF_1.png`;
        return (
            <img src={imageName} className={classes.ilaImage} alt="img" />
        )
    };

    const LaSideBar = () => {
        return (
            <div className={`${classes.section} ${classes.sectionOne}`}>
                {
                    (!isMobile && !isTablet) && (
                        <div className={`${classes.card} ${classes.backBtn}`}>
                            <Button variant="outlined"
                                fullWidth
                                onClick={handleBackButtonClick}
                                sx={{ border: 2, backgroundColor: '#fff' }}>
                                <KeyboardArrowLeftIcon />
                                <span className={classes.backBtnText}>Back</span>
                            </Button>
                        </div>
                    )
                }
                <div className={`${classes.thumbCards} scrollbar-hidden`} id={`thumbCardScrollBox`} onClick={() => {
                    if (isEmpty(minTimeSpentTimer.current)) {
                        setViewThumbnail(prev => !prev)
                    } else {
                        showWarningToast("Please wait for timer to finish!")
                    }
                }}>
                    {
                        !isEmpty(learningAidData) ? (
                            learningAidData?.Cards?.map((item, i) => {
                                return (
                                    <div key={item.idMod}
                                        id={`card-container-${item.idMod}`}
                                        className={classes.cardContainer}
                                    // onClick={() => handleCardClick(item, i)}
                                    >
                                        <>
                                            {
                                                viewThumbnail || (!isMobile && !isTablet) ? (
                                                    <>
                                                        <div className={classes.card}
                                                            id={`card-thumbImage-${item.idMod}`}
                                                            style={checkBorder(i, 'thumbImage')}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleCardClick(item, i, 'thumbImage')
                                                                if (isMobile && viewThumbnail) {
                                                                    handleViewThumbnail()
                                                                }
                                                            }}
                                                        >
                                                            <div className={classes.cardBeforeElement} style={{ backgroundImage: `url(${item.thumbImage})` }} />
                                                            <LockedOverlay index={i} type={'thumbImage'} item={item} />
                                                            <img src={item.thumbImage} className={classes.sideThumb} alt="card" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className={classes.thumbnailBarBox} id={`card-thumbImage-${item.idMod}`}
                                                    >
                                                        <LockedOverlay index={i} type={'thumbImage'} item={item} />
                                                        <div className={classes.thumbnailBar} style={checkBorder(i, 'thumbImage')} >
                                                            {
                                                                !isChallengeSubmitted && (isMobile || isTablet) && (
                                                                    <span className={classes.minTimeProgress} id={`card-progressbar-${item.idMod}`}></span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                !isEmpty(item.gameType) && (
                                                    <>
                                                        {
                                                            !isEmpty(item.context) ? (
                                                                viewThumbnail || (!isMobile && !isTablet) ? (
                                                                    <>
                                                                        <div key={`context-${item.idMod}`}
                                                                            className={`${classes.card}`}
                                                                            id={`card-context-${item.idMod}`}
                                                                            style={checkBorder(i, 'context')}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleCardClick(item, i, 'context')
                                                                                if ((isMobile || isTablet) && viewThumbnail) {
                                                                                    handleViewThumbnail()
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className={classes.cardBeforeElement} style={{ backgroundImage: `url(${item.thumbImage})` }} />
                                                                            <LockedOverlay index={i} type={'context'} item={item} />
                                                                            <div className={classes.contextIlaBox}>
                                                                                <img src={item.thumbImage} className={classes.contextSideImg} alt="card" />
                                                                                <IlaImage data={item.designType} />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className={classes.thumbnailBarBox} id={`card-context-${item.idMod}`}
                                                                    >
                                                                        <LockedOverlay index={i} type={'context'} item={item} />
                                                                        <div className={classes.thumbnailBar} style={checkBorder(i, 'context')} >
                                                                            {
                                                                                !isChallengeSubmitted && (!isMobile && !isTablet) && (
                                                                                    <span className={classes.minTimeProgress} id={`card-progressbar-${item.idMod}`}></span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ) : (
                                                                viewThumbnail || (!isMobile && !isTablet) ? (
                                                                    <>
                                                                        <div className={`${classes.card}`}
                                                                            id={`card-ila-${item.idMod}`}
                                                                            style={checkBorder(i, 'ila')}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleCardClick(item, i, 'ila')
                                                                                if ((isMobile || isTablet) && viewThumbnail) {
                                                                                    handleViewThumbnail()
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className={classes.cardBeforeElement} style={{ backgroundImage: `url(${item.thumbImage})` }} />
                                                                            <LockedOverlay index={i} type={'ila'} item={item} />
                                                                            <IlaImage data={item.designType} />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className={classes.thumbnailBarBox} id={`card-ila-${item.idMod}`}
                                                                    >
                                                                        <LockedOverlay index={i} type={'ila'} item={item} />
                                                                        <div className={classes.thumbnailBar} style={checkBorder(i, 'ila')} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    </div>
                                )
                            })
                        ) : (
                            <div style={constants.FULL_HEIGHT_CENTER}>
                                <CircularProgress />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    };

    const Timer = () => {
        return (
            <div className={`${classes.timerBox} ${classes.centerFlex}`}>
                <img src={'/images/icons/clock-orange.png'} alt="icon" width={isMobile ? 18 : null} />
                <span className={classes.timerText} ref={headerTimeRef}>
                    {commonUtil.getMinutesFromSeconds(timeRef.current)}
                </span>
            </div>
        )
    };

    const SkipButton = (styling = { paddingRight: `1.6rem` }) => {
        return (
            <>
                {
                    isMobile && (
                        <div className={classes.skipButtonBox} onClick={handleSkipButtonClick}>
                            <Button variant="outlined" className={classes.skipBtn} style={styling}>{t("Skip")} <ChevronRightIcon /></Button>
                        </div>
                    )
                }
            </>
        )
    };

    const LaOnboardingScreenTemplate = () => {
        return (
            <div className={classes.laOnboardingRoot}>
                <div className={classes.backDropFilter} />
                <div className={classes.onBoardingMain}
                    onClick={() => handleOnboardingScreenClick(onBoardingScreenNo)}
                >
                    {
                        isMobile && (
                            <>
                                {
                                    onBoardingScreenNo === 1 && (
                                        <div className={classes.onBoardingScreen1} >
                                            <div className={classes.onBoardingSide1} />
                                            <div className={`${classes.onBoardingSide2} ${classes.gridCenter}`}>
                                                <img src="/images/icons/touch_app.svg" alt="icon" width={35} />
                                                <span>{t("Tap to go forward")}</span>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    onBoardingScreenNo === 2 && (
                                        <div className={classes.onBoardingScreen1}>
                                            <div className={`${classes.onBoardingSide1} ${classes.gridCenter}`} >
                                                <img src="/images/icons/touch_app.svg" alt="icon" width={35} />
                                                <span>{t("Tap to go back")}</span>
                                            </div>
                                            <div className={classes.onBoardingSide2} />
                                        </div>
                                    )
                                }

                                {
                                    onBoardingScreenNo === 3 && (
                                        <div className={`${classes.onBoardingScreen2}`}>
                                            <span>{t("Tap to open navigation bar")}</span>
                                            <img src="/images/icons/touch_app.svg" alt="icon" width={35} />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }

                    {
                        onBoardingScreenNo === (isMobile ? 4 : 1) && (
                            <div className={`${classes.onBoardingScreen3}`}>
                                <img src={isMobile ? "/images/icons/pinch_2.svg" : "/images/icons/pinch_4.svg"} alt="icon" width={70} />
                                <span>{t("Pinch to zoom on the image")}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    };

    const LearningAidCardsTemplate = () => {
        return (
            <>
                {
                    !isEmpty(learningAidData) && (
                        <>
                            <LearningAidCardsContainer
                                data={learningAidData}
                                initialData={learningAidInitialData.current}
                                attemptedIdsRef={attemptedIdsRef}
                                activeIndex={activeIndex}
                                activeSubIndex={activeSubIndex}
                                subCardIndex={subCardIndex}
                                maxScore={learningAidData.maxScore}
                                scoreListRef={scoreListRef}
                                showWinScreen={showWinScreen}
                                addScore={addScore}
                                scoreValueRef={scoreValueRef}
                                scoreRef={scoreRef}
                                timeRef={timeRef}
                                handleChallengeAttemptData={handleChallengeAttemptData}
                                func={{ handleNext, handleNextBtnClick, handlePrevClick }}
                                setViewFullScreen={setViewFullScreen}
                                viewFullScreen={viewFullScreen}
                                CorrectTemplate={CorrectTemplate}
                                IncorrectTemplate={IncorrectTemplate}
                                SkipButton={SkipButton}
                                isChallengeSubmitted={isChallengeSubmitted}
                                viewLaOnboardingScreen={viewLaOnboardingScreen}
                                progressStep={progressStep}
                                cardsViewedRef={cardsViewedRef}
                            />
                            {
                                viewLaOnboardingScreen && isMobile && (
                                    <LaOnboardingScreenTemplate />
                                )
                            }
                        </>
                    )
                }
            </>
        )
    };

    const CorrectTemplate = () => {
        return (
            <div className={classes.correctIncorrectBox}>
                {t("CORRECT")}!
            </div>
        )
    };

    const IncorrectTemplate = ({ text }) => {
        return (
            <div className={classes.correctIncorrectBox}>
                {isEmpty(text) ? `${t("WRONG")}!` : t(text)}
            </div>
        )
    };

    // components

    return (
        <div className={classes.learningAidContainer}
            // style={{ height: isMobile ? '100vh' : `calc(${constants.FULL_HEIGHT_VALUE})` }}
            // style={isNativeApp() ? { ...constants.FULL_HEIGHT_CENTER_NATIVE }
            style={(isMobile || isTablet) ? {
                height: `calc(${window.innerHeight}px - 0rem)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            } : { ...constants.FULL_HEIGHT_CENTER }
            }
            tabIndex={0}
            onKeyDown={handleKeyDown}
            id={'learning-aid-root'}
        >
            {
                !isEmpty(learningAidData) ? (
                    <>
                        {/* Timer */}
                        {
                            !isMobile && !viewFullScreen && (
                                <div className={`${classes.timerBoxRoot} ${classes.centerFlex}`}>
                                    <Timer />
                                </div>
                            )
                        }
                        {/* Timer */}

                        <div className={classes.learningAidBox}
                            style={{ gridTemplateRows: isNativeApp() && (isMobile || isTablet) ? `calc(${getHeaderHeight()}) calc(100% - ${getHeaderHeight()}) ` : null, }}
                        // style={{ gridTemplateRows: isNativeApp() ? `calc(${getHeaderHeight()} + 1rem) calc(100% - ${getHeaderHeight()} - 1rem) ` : null, }}
                        >
                            {
                                (!isMobile && !isTablet) ? (
                                    <LaSideBar />
                                ) : (
                                    <div className={classes.customLaHeader}
                                        style={{
                                            // paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null,
                                            gridTemplateColumns: learningAidData.Cards[activeIndex].card.audioURL
                                                && !isEmpty(learningAidData.Cards[activeIndex].card.audioURL)
                                                && !showWinScreen ? '' : isMobile ? "13% 87%" : (isTablet ? "10% 87%" : '15% 82%')
                                        }}
                                    >
                                        {!showWinScreen &&
                                            <div className={`${classes.backButtonBox} ${classes.centerFlex}`}>
                                                <div className={`${classes.backButton} ${classes.centerFlex}`}>
                                                    <HeaderBackButton onClick={() => backBtnListnerFunction()} />
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {
                                                !showWinScreen && (
                                                    <div className={`${classes.sidebarBox} ${classes.centerFlex}`}>
                                                        <LaSideBar />
                                                        {/* <div className={classes.dropArrow}>
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '2rem', color: palette.orange }} onClick={handleViewThumbnail} />
                                                        </div> */}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {/* <div className={`${classes.timeButtonBox} ${classes.centerFlex}`}>
                                            <div className={`${classes.timeButton} ${classes.centerFlex}`}>
                                                <Timer />
                                            </div>
                                        </div> */}
                                        {
                                            learningAidData.Cards[activeIndex].card.audioURL
                                            && !isEmpty(learningAidData.Cards[activeIndex].card.audioURL)
                                            // && !(checkCardNavigation)
                                            && activeSubIndex <= 0
                                            && !showWinScreen
                                            && (
                                                <div className={`${classes.backButtonBox} ${classes.centerFlex}`}>
                                                    <LaVoiceOverAudioPlayer
                                                        voiceOverTrack={learningAidData.Cards[activeIndex].card.audioURL}
                                                        loop={learningAidData.Cards[activeIndex].card.cardType.toUpperCase() === "COVER" ? true : false}
                                                        autoPlay={true}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )
                            }

                            <div className={`${classes.section} ${classes.sectionTwo}`}>
                                {
                                    isMobile && (
                                        <div className={classes.customToastContainer} id="customToastId">
                                            {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}

                                            <h6 className={classes.customToastText} id="customToastTextId" aria-hidden="true"></h6>
                                        </div>
                                    )
                                }
                                {
                                    viewFullScreen ? (
                                        <Modal
                                            open={viewFullScreen}
                                            onClose={() => setViewFullScreen(prev => !prev)}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={modalStyle}>
                                                <LearningAidCardsTemplate />
                                            </Box>
                                        </Modal>
                                    ) : (
                                        <LearningAidCardsTemplate />
                                    )
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                        {/* <CircularProgress variant="determinate" value={laCardsLoadingProgress} /> */}
                        <Typography variant="h5" sx={{
                            position: 'absolute',
                            bottom: '5%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                            fontWeight: 600,
                            color: palette.orange
                        }}>{t("Loading Learning Aid...")}</Typography>
                    </div>
                )
            }
            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={dialogueConfirmBtnName}
                        discardBtnName={dialogueDiscardBtnName}
                        handleDiscardBtnClick={() => setIsDialogOpen(false)}
                        handleConfirmBtnClick={handleConfirmBtnClick}
                    />
                )
            }
        </div>
    )
}

export default LearningAid;


/**
 * unused code
 *

// const getCardCount = (indexVal, subIndexValue) => {
//     let cardCount = 0;
//     for (let i = 0; i <= activeIndex; i++) {
//         let subCardLength = learningAidData.Cards[i].subCards.length;
//         cardCount += 1;
//         if (subCardLength !== 0) {
//             if (i === activeIndex) {
//                 cardCount += subIndexValue
//             } else {
//                 for (let j = 0; j < subCardLength - 1; j++) {
//                     cardCount += 1;
//                 }
//             }

//         }
//     return cardCount
// }

*
 */