import React, { useEffect } from "react";

// mui
import { Button } from "@mui/material";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setChristmasGiftAnimation, setIsDarkMode } from "../../redux/common-persist/common-persist-slice";

// styles
import useStyles from './index.styles';

// lottie
import Lottie from 'react-lottie-player';
// import giftAnnouncement from '../../lottie-assets/gift-announcement.json'
import diwaliAnimation from "../../lottie-assets/common/diwali_animation.json"
import { CloseOutlined } from "@mui/icons-material";



const ChristmasGiftAnimation = (props) => {
    const { wrapperPosition, containerWidth } = props
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isMobile, isTablet } = useSelector(state => state.common);
    const { isDarkMode, viewChristmasGiftAnimation } = useSelector(state => state.commonPersist);

    useEffect(() => {
        if (isMobile) return;
        if (viewChristmasGiftAnimation) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [viewChristmasGiftAnimation, isMobile])

    return (
        <div className={classes.giftAnimeWrapper} style={{
            visibility: viewChristmasGiftAnimation ? 'visible' : 'hidden',
            transform: viewChristmasGiftAnimation ? 'translateY(0)' : 'translateY(-100vh)',
            position: wrapperPosition,
            overflow: 'hidden',
        }}>
            <div className={classes.giftAnimeWrapperOverlay}></div>
            {/* <ul className={classes.snowContainer}>
                {
                    Array.from(Array(100)).map((_, index) => {
                        let width = `${Math.floor(Math.random() * 15)}px`
                        return (<li key={index} className={classes.snow}
                            style={{
                                left: `${Math.floor(Math.random() * 100)}%`,
                                width: width,
                                height: width,
                                animationDelay: `${Math.floor(Math.random() * 10)}s`,
                                animationDuration: `${Math.floor(Math.random() * 50) + 10}s`,
                                animationTimingFunction: `cubic-bezier(${Math.random()}, ${Math.random()}, ${Math.random()}, ${Math.random()})`,
                                filter: `blur(${Math.floor(Math.random() * 5)}px)`,
                            }}
                        ></li>)
                    })
                }
            </ul> */}
            <div className={classes.giftAnimeContainer}>
                {/* <div className={classes.giftAnimeContainer} style={{ width: containerWidth }}> */}
                <div
                    className={`${classes.centerFlex} ${classes.topRightBtnWrapper}`}
                    onClick={() => {
                        console.log("close icon clicked");
                        dispatch(setChristmasGiftAnimation(false));
                    }}
                >
                    <CloseOutlined sx={theme => ({ color: theme.palette.white })} />
                </div>
                <div className={classes.lottieContainer}>
                    <Lottie
                        animationData={diwaliAnimation}
                        loop={true}
                        play={true}
                        height="100%"
                        width="100%"
                        style={{
                            marginTop: (isMobile || isTablet) ? "" : '-5rem'
                        }}
                    />
                </div>
                
                <h2 className={`${classes.giftAnimeTitle} ${classes.lgBoldText}`}>
                    {/* Happy Holidays from Master-O! */}
                    Happy Diwali
                </h2>
                <h2 className={classes.giftAnimeTitle}>
                    {/* Happy Holidays from Master-O! */}
                    From Team Master-O!
                </h2>
                <h6 className={classes.giftAnimeBody}>
                    {/* We've got a new light mode to brighten up your festive mood! */}
                </h6>
                {false && <Button
                    variant="contained"
                    // fullWidth
                    color="primary"
                    sx={theme => ({
                        borderRadius: '4px',
                        textTransform: 'uppercase',
                        fontSize: '1rem',
                    })}
                    onClick={() => {
                        console.log("first")
                        dispatch(setChristmasGiftAnimation(false))
                        // dispatch(setIsDarkMode(false))
                    }}
                >Happy Diwali</Button>}
            </div>
        </div>
    )
}

export default ChristmasGiftAnimation