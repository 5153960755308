import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    giftAnimeWrapper: {
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        zIndex: '100',
        top: '0',
        left: '0',
        transition: 'visibility 0.5s ease-in-out, transform 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     top: `-${constants.HEADER_HEIGHT}`,
        // }
    },
    giftAnimeWrapperOverlay: {
        inset: 0,
        filter: 'blur(50px)',
        position: 'absolute',
        borderRadius: '1rem',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    giftAnimeContainer: {
        background: theme.palette.white,
        width: '90%',
        height: 'max-content',
        maxHeight: '60%',

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '101',

        padding: '1rem 1rem 2rem',
        borderRadius: theme.shape.borderRadius,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // display: 'grid',
        // gridTemplateRows: '70% 10% 10% 10%',
        // justifyContent: 'center',
        gap: '1rem',
        position: 'relative',
        [theme.breakpoints.up("md")]: {
            width: '50%',
            maxHeight: '90%'
        }
    },
    topRightBtnWrapper: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        zIndex: '5',
        width: '2em',
        aspectRatio: '1/1',
        borderRadius: '50%',
        backgroundColor: theme.palette.orange
    },
    lottieContainer: {
        [theme.breakpoints.up("md")]: {
            height: '70dvh',
        }
    },
    giftAnimeTitle: {
        fontSize: '1.5rem',
        // fontWeight: 'bold',
        color: theme.palette.black,
        textAlign: 'center'
    },
    giftAnimeBody: {
        fontSize: '0.9rem',
        color: theme.palette.black,
        textAlign: 'center'
    },
    lgBoldText: {
        fontSize: '2rem !important  ',
        textTransform: 'capitalise',
        fontWeight: 900
    },

    // snow fall animation
    snowContainer: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        "& li": {
            opacity: 0,
            position: 'absolute',
            top: 0,
            borderRadius: '100%',
            backgroundColor: '#FFFFFF',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            webkitAnimationName: '$snowDrop',
            animationName: '$snowDrop',
            webkitAnimationIterationCount: 'infinite',
            animationIterationCount: 'infinite',
            webkitAnimationIterationCount: 'linear',
            animationTimingFunction: 'linear',
            webkitAnimationFillMode: 'forwards',
            animationFillMode: 'forwards',
        },
    },

    "@keyframes snowDrop": {
        "0%": {
            transform: 'translate(0, 0)',
            opacity: 0.5,
            marginLeft: 0,
        },
        "10%": {
            marginLeft: '15px',
        },
        "20%": {
            marginLeft: '20px',
        },
        "25%": {
            transform: 'translate(0, 166.6666666667px)',
            opacity: 0.75,
        },
        "30%": {
            marginLeft: '15px',
        },
        "40%": {
            marginLeft: '0',
        },
        "50%": {
            transform: 'translate(0, 333.3333333333px)',
            opacity: 1,
            marginLeft: '-15px',
        },
        "60%": {
            marginLeft: '-20px',
        },
        "70%": {
            marginLeft: '-15px',
        },
        "75%": {
            transform: 'translate(0, 500px)',
            opacity: 0.5,
        },
        "80%": {
            marginLeft: '0',
        },
        "100%": {
            transform: 'translate(0, 666.6666666667px)',
            opacity: 0,
        }
    }
}))

export default useStyles