import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { isNativeApp } from '../../utils/commonUtil';

// mui
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CircularProgress } from "@mui/material";

// components

// redux
import { getCertificateData } from "../../redux/microskill/microskill.api";
import { microskillCertificateUpload } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil";

// theme
import palette from "../../theme/palette";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index_prev.styles";
import webViewJavaScriptBridge from "webview-javascript-bridge";
import LinkedInIcon from '@mui/icons-material/LinkedIn';


//images
// import { ifl_certificate_body, generic_certificate_body } from "../../base64";

const Certificate = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    // console.log("deviceData::", deviceData);
    const { microskillId, learnerJourneyId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile)
    const accessToken = useSelector(state => state?.auth?.tokens.accessToken)
    const userInfo = jwtDecode(accessToken);
    const pdfRef = useRef();
    const pdfMainRef = useRef();
    const [fSize, setFSize] = useState(1);
    const [loadingCertificate, setLoadingCertificate] = useState(false);
    const [certificateUrl, setCertificateUrl] = useState('');
    // const [dimension, setDimension] = useState(false);

    const [certificateData, setCertificateData] = useState();

    const pdf = new jsPDF("l", "pt", "a4", true);
    // const doc = new jsPDF("l", "pt", "a4", true);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const s3MediaUrl = process.env.REACT_APP_CERTIFICATE_IMAGE_S3_URL;

    const isAbsli = commonUtil.getCompanyId() === process.env.REACT_APP_ABSLI_COMPANY_ID;
    const isMondelez = commonUtil.getCompanyId() === process.env.REACT_APP_MONDELEZ_COMPANY_ID;
    const isBajajChetak = commonUtil.getCompanyId() === process.env.REACT_APP_BAJAJ_CHETAK_COMPANY_ID;
    const isIfl = commonUtil.getCompanyId() === process.env.REACT_APP_IFL_COMPANY_ID;
    const isMahindraLMM = commonUtil.getCompanyId() === process.env.REACT_APP_MAHINDRA_LMM_COMPANY_ID;
    const isIndiamart = commonUtil.getCompanyId() === process.env.REACT_APP_INDIAMART_COMPANY_ID;
    const isIndiabulls = commonUtil.getCompanyId() === process.env.REACT_APP_INDIABULLS_COMPANY_ID;
    const isBajajICV = commonUtil.getCompanyId() === process.env.REACT_APP_BAJAJ_ICV_COMPANY_ID;
    const isAlkem = commonUtil.getCompanyId() === process.env.REACT_APP_ALKEM_COMPANY_ID;
    const isIndigo = commonUtil.getCompanyId() === process.env.REACT_APP_INDIGO_COMPANY_ID;
    const isMaxlife = commonUtil.getCompanyId() === process.env.REACT_APP_MAX_LIFE_COMPANY_ID;
    const isKelloggs = commonUtil.getCompanyId() === process.env.REACT_APP_KELLOGGS_COMPANY_ID
    const isNovoNordisk = commonUtil.getCompanyId() === process.env.REACT_APP_NOVO_NORDISK_COMPANY_ID
    // const isDemoCompQa = commonUtil.getCompanyId() === process.env.REACT_APP_DEMO_COMP_QA_COMPANY_ID

    const { t } = useTranslation();
    const linkedinCertificateUrl = process.env.REACT_APP_LINKEDIN_CERTIFICATION_URL;

    useEffect(() => {
        async function fetchData() {
            resFont();
            let params = !isEmpty(learnerJourneyId) ? `journeyId=${learnerJourneyId}` : `microskillIds=${microskillId}`
            const resp = await getCertificateData(params);
            setCertificateData(resp.data.data);
            let certificateConfig = resp?.data?.data?.certificateConfigs;
            let certificateUrl = resp?.data?.data?.userMicroskill?.certificateUrl;

            if (certificateUrl !== null) {
                setCertificateUrl(certificateUrl);
            }
            if (!resp?.data?.data[!isEmpty(learnerJourneyId) ? 'userLearnerJourney' : 'userMicroskill']?.completion) return navigate(-1);
            // if (!resp?.data?.data?.userMicroskill?.certified) return navigate(-1);
            if (!isEmpty(certificateConfig)) {
                if (!isEmpty(certificateConfig.footer_img)) {
                    // handleBase64ImageConversion('ifl_footer.png', setFooterImgBase64)
                    handleBase64ImageConversion(certificateConfig.footer_img)
                }
                if (!isEmpty(certificateConfig.header_img)) {
                    // handleBase64ImageConversion(`ifl_header.png`, setHeaderImgBase64)
                    handleBase64ImageConversion(certificateConfig.header_img)
                }
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBase64ImageConversion = async (imageUrl) => {
        let imgTemp = `${s3MediaUrl}/${imageUrl}?bypasscor=anything`;
        const fetchedImage = await fetch(imgTemp);
        const blob = await fetchedImage.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
            // const base64String = reader.result.split(',')[1];
            // setType(reader.result)
        };
        reader.readAsDataURL(blob);
    }

    useEffect(() => {
        if (isEmpty(certificateData)) return
        let pdfElement = document.getElementById('pdfRef');
        let height = pdfMainRef.current.getBoundingClientRect().height;
        let width = pdfMainRef.current.getBoundingClientRect().width;
        // setDimensionsValue({ height, width })
        if (isMobile) {
            pdfElement.style.width = `${width}px `;
            pdfElement.style.height = `${width * 0.7070}px`;
        } else {
            pdfElement.style.height = `${height}px `;
            pdfElement.style.width = `${height * 1.4142756349952965}px`;
        }

        // pdfRef.current.style.display = 'grid';
    }, [isMobile, certificateData]);

    const downloadPdf = (addToLinkedIn = false) => {

        // doc.text(20, 20, "This is sample text");
        // doc.addImage(genericCertificateImgBase64, 'png', 0, 0, 200, 200)
        // doc.save("test.pdf");

        // if (certificateUrl !== '') {
        //     // window.open(certificateUrl, "_blank");
        //     window.location.replace(certificateUrl);
        //     return;
        // }
        // let pdfElement = document.getElementById('pdfRef');

        setLoadingCertificate(true);
        pdfRef.current.style.borderRadius = "0px";
        html2canvas(document.querySelector("#pdfRef"), {
            scale: 4,
        }).then(async (canvas) => {
            pdf.addImage(
                canvas.toDataURL("image/png"),
                "png",
                0,
                0,
                pageWidth,
                pageHeight,
                'SLOW'
            );
            pdfRef.current.style.borderRadius = "0.5rem";
            const certificatePdf = pdf.output('blob');
            const certificateFormData = new FormData();
            certificateFormData.append('microskillCertificateFile', certificatePdf, `microskillCertificate.pdf`);
            certificateFormData.append('userId', userInfo.userId);
            if (!isEmpty(learnerJourneyId)) {
                certificateFormData.append('journeyId', learnerJourneyId);
            } else {
                certificateFormData.append('microskillId', microskillId);
            }
            const respData = await microskillCertificateUpload(certificateFormData);
            console.log(addToLinkedIn, 'addToLinkedIn')

            // return
            setLoadingCertificate(false);

            const isNative = localStorage.getItem('isNative');

            if (!addToLinkedIn) {
                if (isNative) {
                    let microskillName = certificateData?.microskill?.name;
                    microskillName = microskillName.replace(/ /g, '_');
                    microskillName = microskillName.replace(/\//g, '-');
                    let certificateUrl = `${respData?.data?.data?.certificateUrl}|${microskillName}.pdf`;
                    console.log(`'${certificateUrl}'`);
                    webViewJavaScriptBridge.sendMessage({ action: 'DownloadChannel', params: `'${certificateUrl}'` }, (response) => {
                        console.log('DownloadChannel response:', response);
                    });
                } else {
                    window.location.replace(respData?.data?.data?.certificateUrl);
                }
            } else {
                liknedInBtnClick();
            }
        })
    }

    const handleBackBtnClick = () => {
        if (!isEmpty(learnerJourneyId)) {
            navigate(`/learning-journey/${learnerJourneyId}`)
        } else {
            navigate(`/challenge-list/${microskillId}`);
        }
    }

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    window.addEventListener("resize", resFont);

    function extractMonthAndYear(timestamp) {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return { month: month, year: year };
    }

    const liknedInBtnClick = () => {
        const windowOrigin = () => {
            let originUrl = window.location.origin;
            if (originUrl.includes("web-staging")) {
                return originUrl.replace("web-staging", "web");
            }
            return originUrl;
        }

        const certificationName = certificateData[isEmpty(learnerJourneyId) ? 'microskill' : 'learnerJourney']?.name;
        // const certificationName = certificateData?.microskill?.name;
        // const timestamp = certificateData?.userMicroskill?.completedOn;
        const timestamp = certificateData[isEmpty(learnerJourneyId) ? 'userMicroskill' : 'userLearnerJourney']?.completedOn;

        const issueTime = extractMonthAndYear(timestamp);
        const month = issueTime?.month;
        const year = issueTime?.year;
        const createdUrl = `${windowOrigin()}/generic-doc-viewer?docUrl=${certificateUrl}`

        const LINKEDIN_URL = `${linkedinCertificateUrl}&name=${encodeURIComponent(
            certificationName
        )}&certUrl=${encodeURIComponent(createdUrl)}&issueYear=${encodeURIComponent(
            year
        )}&issueMonth=${encodeURIComponent(
            month
        )}&external=true`;

        console.log("LINKEDIN_URL::", LINKEDIN_URL);

        // window.open(
        //     LINKEDIN_URL,
        //     "_blank",
        //     `location=yes,height=${window.innerHeight},width=${window.innerWidth},scrollbars=yes,status=yes`
        // );

        window.location.replace(LINKEDIN_URL);
    };

    return (
        <div
            className={classes.certificateRoot}
            // style={isMobile ? constants.FULL_HEIGHT_MOBILE : constants.FULL_HEIGHT}
            style={isMobile ? {
                height: `calc(100dvh - ${constants.HEADER_HEIGHT} - ${constants.BOTTOM_NAV_HEIGHT})`,
            } : constants.FULL_HEIGHT}
        >
            {isEmpty(certificateData) ? (
                <div style={constants.FULL_HEIGHT_CENTER}>
                    <CircularProgress />
                </div>
            ) : (
                <div
                    className={classes.certificateBox}
                    style={{ fontSize: `${fSize}rem` }}
                >
                    <>
                        <div className={classes.certificateText}>
                            <div>
                                {!isMobile && (
                                    <div
                                        className={`${classes.centerFlex} ${classes.backButton}`}
                                        onClick={handleBackBtnClick}
                                    >
                                        <ArrowBackIosNewIcon sx={{ color: palette.orange }} />
                                    </div>
                                )}
                            </div>
                            {/* <span>{t("Your Certificate")}</span> */}
                        </div>
                        <div ref={pdfMainRef}>
                            <div
                                className={classes.pdfRoot}
                                ref={pdfRef}
                                id="pdfRef"
                            // style={{ gridTemplateColumns: isAbsli ? 'auto' : null }}
                            >
                                <>

                                    {
                                        !isEmpty(learnerJourneyId) ? <>
                                            <div className={classes.genericRoot}>
                                                <img src={"/images/certificates/generic_learning_journey_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />

                                                <div className={classes.genericUserName}>{`${certificateData.user.firstName !== null ? certificateData.user.firstName : userInfo.username} ${certificateData.user.firstName !== null && certificateData.user.lastName !== null ? certificateData.user.lastName : ""}`}</div>
                                                <div className={classes.genericMicroskillName}>{certificateData?.learnerJourney?.name}</div>
                                            </div>
                                        </> :
                                            // isEmpty(certificateUrl) ? (
                                            <div className={classes.genericRoot}>
                                                {/* <img src={isIfl ? ifl_certificate_body : generic_certificate_body} className={classes.genericCoverImage} alt="generic" /> */}
                                                {
                                                    isAbsli && <img src={"/images/certificates/ABSLI_certificate.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isIfl && <img src={"/images/certificates/ifl_certificate_body_new.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isBajajChetak && <img src={"/images/certificates/bajaj_chetak_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isMondelez && <img src={"/images/certificates/mondelez_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isMahindraLMM && <img src={"/images/certificates/mahindra_lmm_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isIndiamart && <img src={"/images/certificates/indiamart_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isIndiabulls && <img src={"/images/certificates/indiabulls_samman_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                    // isIndiabulls && <img src={"/images/certificates/indiabulls_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isBajajICV && <img src={"/images/certificates/bajaj_icv_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isAlkem && <img src={"/images/certificates/alkem_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isIndigo && <img src={"/images/certificates/indigo_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isMaxlife && <img src={"/images/certificates/max_life_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isKelloggs && <img src={"/images/certificates/kelloggs_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    isNovoNordisk && <img src={"/images/certificates/novo_nordisk_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    !isAbsli && !isAlkem && !isMondelez && !isIfl && !isBajajChetak && !isMahindraLMM && !isIndiamart && !isIndiabulls && !isBajajICV && !isIndigo && !isMaxlife && !isKelloggs && !isNovoNordisk && <img src={"/images/certificates/generic_certificate_body.png"} className={classes.genericCoverImage} alt="certificate" />
                                                }
                                                {
                                                    !isMondelez && !isBajajICV && <div className={classes.genericCertifiedDate}>
                                                        {!isEmpty(certificateData?.userMicroskill?.completedOn) ? moment(certificateData?.userMicroskill?.completedOn).format("DD MMMM YYYY") : moment().format("DD MMMM YYYY")}
                                                    </div>
                                                }
                                                <div className={classes.genericUserName}>{`${certificateData.user.firstName !== null ? certificateData.user.firstName : userInfo.username} ${certificateData.user.firstName !== null && certificateData.user.lastName !== null ? certificateData.user.lastName : ""}`}</div>
                                                <div className={classes.genericMicroskillName}>{certificateData?.microskill?.name}</div>
                                            </div>
                                    }
                                </>
                            </div>
                        </div>
                        <div className={`${classes.downloadBtnContainer}`}>
                            {
                                loadingCertificate ? (
                                    <CircularProgress />
                                ) : (
                                    // <>
                                    //     <Button variant="contained" onClick={() => downloadPdf(false)}>
                                    //         {t("Download")}
                                    //     </Button>
                                    //     {
                                    //         !isIndiabulls && !isIndigo && <Button variant="contained"
                                    //             sx={{
                                    //                 padding: "0",
                                    //             }}
                                    //             onClick={() => downloadPdf(true)}
                                    //         // onClick={liknedInBtnClick}
                                    //         >
                                    //             <img variant="contained" src="/images/icons/add-to-linkedin.png" alt="Add to profile" />
                                    //         </Button>}
                                    // </>
                                    <>
                                        <Button
                                            variant="contained"

                                            fullWidth
                                            className={`fixed_ratio_20_3 ${classes.downloadBtn}`}
                                            // onClick={handleShare}
                                            onClick={() => downloadPdf(false)}
                                        >
                                            {/* <ShareIcon /> {t("Share PDF")} */}
                                            {t("Download")}
                                        </Button>
                                        {
                                            !isIndiabulls && !isIndigo &&
                                            // getCompanyName() !== 'isIndiabulls' && getCompanyName() !== 'isIndigo' &&
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                className={`fixed_ratio_20_3 ${classes.downloadBtn} ${classes.linkedInBtn}`}
                                                onClick={liknedInBtnClick}

                                            // onClick={() => downloadBtn(true)}
                                            >
                                                {/* <img variant="contained" src="/images/icons/add-to-linkedin.png" alt="Add to profile" /> */}
                                                <LinkedInIcon /> {t("Add to LinkedIn")}

                                            </Button>
                                        }
                                    </>
                                )
                            }
                        </div>
                    </>
                </div>
            )}
        </div>
    );
};

export default Certificate;
