import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from 'react-lottie-player';

// mui
import { Button, CircularProgress } from "@mui/material";
import { Fade, Slide, Zoom } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/scq-how-to-play";
import GbaWinScreen from "../common-screens/win-screen";
import GbaGameOverScreen from "../common-screens/game-over-screen";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// lottie
import MASTER_BLASTER_SIX_LOTTIE from "../../../lottie-assets/master_blaster_six.json";
import MASTER_BLASTER_FOUR_LOTTIE from '../../../lottie-assets/master_blaster_four.json';
import MASTER_BLASTER_TWO_LOTTIE from '../../../lottie-assets/master_blaster_two.json';
import MASTER_BLASTER_CATCH_OUT_LOTTIE from '../../../lottie-assets/master_blaster_catch_out.json';
import MASTER_BLASTER_OUT_LOTTIE from '../../../lottie-assets/master_blaster_out.json';
import MASTER_BLASTER_IDLE_LOTTIE from '../../../lottie-assets/master_blaster_idle_state.json';
import WRONG_LOTTIE from '../../../lottie-assets/common/Wrong-Answer.json';
import CORRECT_LOTTIE from '../../../lottie-assets/common/Correct-Answer.json';
import TIME_OUT from '../../../lottie-assets/common/Timeout.json';

// style
import useStyle from "./index.styles";
import palette from "../../../theme/palette";

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
const assestLoadingDuration = 3000;

let stopWatchImgPath = '/images/icons/stopwatch.png';
let redStopWatchImgPath = '/images/icons/stopwatch-red.png';
let heartImgPath = '/images/icons/heart.png';
let redHeartWatchImgPath = '/images/icons/heart-red.png';

let logCounter = 0;


const MasterBlaster = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const gameName = gameData?.name;
    const questionData = gameData.questions;
    const { microskillId, challengeId } = useParams();

    // const timePerQuestion = 180;
    const timePerQuestion = 18;
    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let questionsInGba = questionData.length;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });
    let mxmScore = 120;
    let marksPerQuestion = mxmScore / questionData.length;

    let headerBg = "#4f0060";
    // let bgGradientDefault = defaultGradient;
    let incorectBgGradient = "linear-gradient(#800e31b5, #f63b34b5)";

    const sessionStartTime = null;
    let contextStartTime = sessionStartTime;
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [activeindex, setActiveindex] = useState(0);
    const [gameEndType, setGameEndType] = useState();
    const [attemptData, setAttemptData] = useState('');
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [isOptionBoxExpanded, setIsOptionBoxExpanded] = useState(false);
    const [isOptionSetVisible, setIsOptionSetVisible] = useState(true);
    const [slideOptions, setSlideOptions] = useState(true);
    const [fadeInOptions, setFadeInOptions] = useState(true);
    const [animateQuestion, setAnimateQuestion] = useState(true);
    const [fadeInOptionsText, setFadeInOptionsText] = useState(true);
    const [log, setLog] = useState([]);
    const [fSize, setFSize] = useState(1);
    const [isAudioPlaying, setIsAudioPlaying] = useState(true);
    const [incorrectData, setIncorrectData] = useState();

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const intervalRef = useRef();
    const totalScoreRef = useRef();
    const progressBarRef = useRef();
    const questionRef = useRef([]);
    const qstnBoxRef = useRef(null);
    const lifeTextRef = useRef(null);
    const stopWatchRef = useRef();
    const divLifeRef = useRef();
    const divTimerRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const timerRef = useRef();
    const gameContainerRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);
    const questionNoRef = useRef();
    const finalChallengeDataRef = useRef();
    const optionBoxRef = useRef();
    const scoreAnimationTypeRef = useRef();
    const scoreAnimationTypeBoxRef = useRef();
    const isOptionClickAllowedRef = useRef(true);
    const sixAnimaitionLottieRef = useRef();
    const fourAnimaitionLottieRef = useRef();
    const twoAnimaitionLottieRef = useRef();
    const timeOutAnimaitionLottieRef = useRef();
    const wrongAnswerAnimaitionLottieRef = useRef();
    const sixAnimaitionLottieBoxRef = useRef();
    const fourAnimaitionLottieBoxRef = useRef();
    const twoAnimaitionLottieBoxRef = useRef();
    const timeOutAnimaitionLottieBoxRef = useRef();
    const wrongAnswerAnimaitionLottieBoxRef = useRef();
    const defaultAnimaitionLottieBoxRef = useRef();
    const defaultAnimaitionLottieRef = useRef();
    const optionFadeOutTimeRef = useRef(0.4 * 1000);

    const lottieLoaderRef = useRef();
    const assestsBoxRef = useRef();
    const tapToPlayRef = useRef();
    const gameplayContainerRef = useRef();
    const isCorrectAnswer = useRef(true);
    const gradientOverlayRef = useRef();
    const correctIncorrectTextRef = useRef();

    const crowdAudioRef = useRef();
    const isOptionClickedRef = useRef(false);
    const gameStartTimeRef = useRef(Date.now())

    // const totalGBATimespentIntervalRef = useRef();
    // const totalGBATimespent = useRef(0);

    // const correctTextRef = useRef();
    // const incorrectTextRef = useRef();
    // const timeoutTextRef = useRef();
    const correctTextLottieRef = useRef();
    const inCorrectTextLottieRef = useRef();
    const timeOutTextLottieRef = useRef();
    const correctTextLottieBoxRef = useRef();
    const inCorrectTextLottieBoxRef = useRef();
    const timeOutTextLottieBoxRef = useRef();

    // const salutedTextArray = [
    //     {
    //         id: 1,
    //         text: "CORRECT!",
    //         ref: correctTextRef,
    //     },
    //     {
    //         id: 2,
    //         text: "WRONG!",
    //         ref: incorrectTextRef,
    //     },
    //     {
    //         id: 3,
    //         text: "TIME OUT!",
    //         ref: timeoutTextRef
    //     }
    // ]

    let lottieRefList = [
        {
            id: '1',
            boxRef: sixAnimaitionLottieBoxRef,
            lottieFileRef: sixAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_SIX_LOTTIE,
        },
        {
            id: '2',
            boxRef: fourAnimaitionLottieBoxRef,
            lottieFileRef: fourAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_FOUR_LOTTIE,
        },
        {
            id: '3',
            boxRef: twoAnimaitionLottieBoxRef,
            lottieFileRef: twoAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_TWO_LOTTIE,
        },
        {
            id: '4',
            boxRef: wrongAnswerAnimaitionLottieBoxRef,
            lottieFileRef: wrongAnswerAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_CATCH_OUT_LOTTIE,
        },
        {
            id: '5',
            boxRef: timeOutAnimaitionLottieBoxRef,
            lottieFileRef: timeOutAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_OUT_LOTTIE,
        },
        {
            id: '6',
            boxRef: defaultAnimaitionLottieBoxRef,
            lottieFileRef: defaultAnimaitionLottieRef,
            lottieFile: MASTER_BLASTER_IDLE_LOTTIE,
        }
    ];

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    window.addEventListener("resize", resFont);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    useEffect(() => {
        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE.GAME);
            // clearInterval(totalGBATimespentIntervalRef.current);
            // totalGBATimespentIntervalRef.current = setInterval(() => {
            //     // setAddPause(true);
            //     totalGBATimespent.current += 1;
            // }
            //     , 1000);
            logCounter = 0;
            totalScoreRef.current = 0;
            if (activeindex === 0) {
                if (!isEmpty(questionRef.current)) questionRef.current.innerHTML = questionData[activeindex].question;
            }
            // crowdAudioRef.current.addEventListener("loadeddata", handleAudioLoad);
        }
    }, [isGamePage]);

    useEffect(() => {
        if (logCounter >= lottieRefList.length && log.length > 0) {
            // const stadiumImage = document.getElementById('stadiumImage');
            // stadiumImage.onload = () => {
            lottieLoaderRef.current.style.display = 'none';
            assestsBoxRef.current.style.opacity = '1';
            // };
        }
    }, [log]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            // clearInterval(totalGBATimespentIntervalRef.current);
            // if (!isEmpty(crowdAudioRef.current) && typeof crowdAudioRef.current.stop === 'function') {
            //     crowdAudioRef.current.stop();
            //     crowdAudioRef.current.removeEventListener("loadeddata", handleAudioLoad);
            // }
        }
    }, []);

    const handleAudioLoad = (e) => {
        crowdAudioRef.current.play();
        // console.log("crowd audio file loaded")
    }

    const addLog = (line) => {
        setLog((existing) => [{ text: line, n: logCounter }, ...existing]);
        logCounter += 1;
    };

    const handleBackButtonClick = () => {
        handleTimerPause();

        isGamePausedRef.current = true;
        let currentTime = Date.now();

        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            // timespent: totalGBATimespent.current,
        }));
    }

    const handleResumeClick = () => {
        // setAddPause(true);
        handleTimerResume();
    }

    const handleTimerPause = () => {
        if (isEmpty(timerRef.current)) return
        clearInterval(intervalRef.current);
        // clearInterval(totalGBATimespentIntervalRef.current);
        if (!isEmpty(scoreAnimationTypeRef?.current)) scoreAnimationTypeRef.current.pause();
        if (!isEmpty(crowdAudioRef?.current)) crowdAudioRef.current.pause();
    }

    const handleTimerResume = () => {
        if (isEmpty(timerRef.current)) return
        clearInterval(intervalRef.current);
        // clearInterval(totalGBATimespentIntervalRef.current);
        if (isOptionBoxExpanded) startTimer(parseInt(timerRef.current.innerHTML) - 1);
        // totalGBATimespentIntervalRef.current = setInterval(() => {
        //     // setAddPause(true);
        //     totalGBATimespent.current += 1;
        // }, 1000);
        // setAddPause(true);
        setFadeInOptions(true);
        if (!isEmpty(scoreAnimationTypeRef?.current) && isOptionClickedRef.current) scoreAnimationTypeRef.current.play();
        if (!isEmpty(crowdAudioRef?.current)) crowdAudioRef.current.play();
    }

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const startTimer = (reset = true) => {
        clearInterval(intervalRef.current);

        let timer = new Date();
        contextStartTime = timer.getTime() / 1000;

        intervalRef.current = setInterval(() => {
            let tempTimeValue = parseInt(timerRef.current.innerHTML);
            isOptionClickAllowedRef.current = true;

            // for OUT
            if (tempTimeValue === 0) {
                clearInterval(intervalRef.current);
                scoreAnimationTypeRef.current = twoAnimaitionLottieRef.current;
                scoreAnimationTypeBoxRef.current = twoAnimaitionLottieBoxRef.current;
                isOptionClickAllowedRef.current = false;

                handleTimeOutPerQuestion();
                return
            }
            timerRef.current.innerHTML = tempTimeValue - 1;
            let runningTime = timerRef.current.innerHTML;

            // for SIX
            if (runningTime < 20 && runningTime >= 15) {
                scoreAnimationTypeRef.current = sixAnimaitionLottieRef.current;
                scoreAnimationTypeBoxRef.current = sixAnimaitionLottieBoxRef.current;
            }

            // for FOUR
            else if (runningTime < 15 && runningTime >= 10) {
                scoreAnimationTypeRef.current = fourAnimaitionLottieRef.current;
                scoreAnimationTypeBoxRef.current = fourAnimaitionLottieBoxRef.current;
            }

            // for TWO
            else if (runningTime < 10 && runningTime > 0) {
                optionFadeOutTimeRef.current = (10 * 1000);
                setFadeInOptions(false);
                scoreAnimationTypeRef.current = twoAnimaitionLottieRef.current;
                scoreAnimationTypeBoxRef.current = twoAnimaitionLottieBoxRef.current;
                if (!isEmpty(divTimerRef.current)) divTimerRef.current.style.color = '#FF5757';
                if (!isEmpty(stopWatchRef.current)) {
                    stopWatchRef.current.src = redStopWatchImgPath;
                    stopWatchRef.current.addEventListener('load', () => {
                        // console.log("stopwatch img loaded")
                    })
                }
            }
        }, 1000);
    }

    useEffect(() => {
        (async () => {
            if (isEmpty(optionBoxRef.current)) return
            if (isOptionBoxExpanded) {
                // set default animation
                scoreAnimationTypeRef.current = sixAnimaitionLottieRef.current;
                scoreAnimationTypeBoxRef.current = sixAnimaitionLottieBoxRef.current;
                await sleep(1000)
                startTimer();
            }
        })()
    }, [isOptionBoxExpanded]);

    const handleOptionExpansion = () => {
        scoreAnimationTypeRef.current = sixAnimaitionLottieRef.current;
        if (!isOptionClickAllowedRef.current) return
        setIsOptionBoxExpanded(true); // set tap to play to FALSE: Show options
        setSlideOptions(true); // slide to appear options
        setFadeInOptions(true); // slide to appear options
        setFadeInOptionsText(true);
    }

    const handleOptionClick = async (item, e, elId) => {
        isOptionClickedRef.current = true;
        let btnElem = document.getElementById(elId);
        btnElem.style.boxShadow = `0px 0px 10px 5px ${palette.greyText4}`;
        if (!isOptionBoxExpanded || !isOptionClickAllowedRef.current) return
        isCorrectAnswer.current = item.isCorrect;
        setFadeInOptions(true)
        await setAllAnimationVariable();
        // await setAllAnimationVariable(item.isCorrect, elId);
        await sleep(500) // wait till blur overlay is hidden
        btnElem.style.boxShadow = 'none';
        let tempReq = {
            qId: questionData[activeindex].id,
            aId: item.id,
            isCorrect: false,
            t: timePerQuestion - parseInt(timerRef.current.innerHTML),
            skillId: questionData[activeindex].skillId,
            contextId: questionData[activeindex].contextId,
        };

        if (item.isCorrect) {
            // do the correct animation
            totalScoreRef.current += marksPerQuestion;
            handleLottieAnimation()

            // analytics
            tempReq.isCorrect = true;
            handleChallengeAttemptData(tempReq);
        } else {
            // do the incorrect animation
            scoreAnimationTypeRef.current = wrongAnswerAnimaitionLottieRef.current;
            scoreAnimationTypeBoxRef.current = wrongAnswerAnimaitionLottieBoxRef.current;
            handleLottieAnimation();

            // analytics
            tempReq.isCorrect = false;
            handleChallengeAttemptData(tempReq);
        }
    }

    const setAllAnimationVariable = async (isCorrect, elId, isTimeOut) => {
        clearInterval(intervalRef.current);
        isOptionClickAllowedRef.current = false; // disable option click

        if (isTimeOut) {
            correctIncorrectTextRef.current.style.opacity = '1'; // showing text for correct and incorrect
            inCorrectTextLottieRef.current.pause();
            correctTextLottieRef.current.pause();
            timeOutTextLottieBoxRef.current.style.opacity = '1';
            timeOutTextLottieRef.current.play();
        } else if (isCorrectAnswer.current) {
            // console.log("if");
            correctIncorrectTextRef.current.style.opacity = '1'; // showing text for correct and incorrect
            inCorrectTextLottieRef.current.pause();
            timeOutTextLottieRef.current.pause();
            correctTextLottieBoxRef.current.style.opacity = '1';
            correctTextLottieRef.current.play();
        } else {
            // console.log("else");
            correctIncorrectTextRef.current.style.opacity = '1'; // showing text for correct and incorrect
            correctTextLottieRef.current.pause();
            timeOutTextLottieRef.current.pause();
            inCorrectTextLottieBoxRef.current.style.opacity = '1';
            inCorrectTextLottieRef.current.play();
        }

        // let targetEl = document.getElementById(elId);
        // if (!isEmpty(targetEl)) targetEl.classList.add(isCorrect ? classes.greenBtn : classes.redBtn)
        await sleep(500)

        setFadeInOptions(false);
        setFadeInOptionsText(false);
        await sleep(800); // animation time for options to fade out
        setSlideOptions(false); // slide to hide options
        // await sleep(500); // animation time for options to hide
        setIsOptionBoxExpanded(false); // set tap to play to TRUE: Hide options
        setIsOptionSetVisible(false);
        setAnimateQuestion(true);
        // if (!isEmpty(targetEl)) targetEl.classList.remove(isCorrect ? classes.greenBtn : classes.redBtn);
        correctIncorrectTextRef.current.style.opacity = '0'; // hide text for correct and incorrect
        correctTextLottieBoxRef.current.style.opacity = '0';
        inCorrectTextLottieBoxRef.current.style.opacity = '0';
        timeOutTextLottieBoxRef.current.style.opacity = '0';
    }

    const handleTimeOutPerQuestion = async () => {
        // console.log("here");
        isCorrectAnswer.current = false;
        await setAllAnimationVariable(undefined, undefined, true);

        await sleep(1000); // wait before showing out animation
        scoreAnimationTypeRef.current = timeOutAnimaitionLottieRef.current;
        scoreAnimationTypeBoxRef.current = timeOutAnimaitionLottieBoxRef.current;
        // handleIncorrectAnimation();
        handleLottieAnimation();
    }

    const toggleLottie = (animation = 'hide', lottieRefBox, lottieRef) => {
        lottieRefBox.style.position = animation === 'hide' ? null : 'relative';
        lottieRefBox.style.top = animation === 'hide' ? null : '5px';
        if (animation === 'hide') lottieRef.stop();
        else lottieRef.play();
    }

    const handleLottieAnimation = async () => {
        // hide default lottie
        toggleLottie('hide', defaultAnimaitionLottieBoxRef.current, defaultAnimaitionLottieRef.current);

        // show scoring lottie
        toggleLottie('show', scoreAnimationTypeBoxRef.current, scoreAnimationTypeRef.current);
    }

    const onLottieComplete = async (index) => {
        // toggleLottie('hide', scoreAnimationTypeBoxRef.current, scoreAnimationTypeRef.current);
        // toggleLottie('hide', defaultAnimaitionLottieBoxRef.current, defaultAnimaitionLottieRef.current);

        // hide all lottie


        if (!isCorrectAnswer.current) {
            // gradientOverlayRef.current.classList.add(classes.wrongAnswerAnimation);
            handleLifeLoss();
        } else {
            // gradientOverlayRef.current.classList.add(classes.rightAnswerAnimation);
        }
        // await sleep(3000);
        // let lifeValue = parseInt(lifeRef.current.innerHTML);
        // if (lifeValue !== 1) {
        //     gradientOverlayRef.current.classList.remove(classes.wrongAnswerAnimation);
        // }
        // gradientOverlayRef.current.classList.remove(classes.rightAnswerAnimation);

        // hide all scoring lottie
        lottieRefList.forEach(item => {
            // console.log("hide all scoring lottie : ", item.lottieFileRef.current);
            toggleLottie('hide', item.boxRef.current, item.lottieFileRef.current);
        });
        // toggleLottie('hide', scoreAnimationTypeBoxRef.current, scoreAnimationTypeRef.current);

        // show default lottie
        toggleLottie('show', defaultAnimaitionLottieBoxRef.current, defaultAnimaitionLottieRef.current)

        setAnimateQuestion(false) // hide question
        await sleep(1000);
        handleNextQuestion();
        isOptionClickedRef.current = false;
    }

    const handleNextQuestion = () => {
        if (parseInt(lifeRef.current.innerHTML) === 0) {
            handleEndOfGamesDueToLifeLoss();
            return
        } else if (activeindex === questionData.length - 1) {
            handleEndOfQuestions();
            return
        }
        setActiveindex(prev => prev + 1);
    }

    const handleLifeLoss = () => {
        let lifeValue = parseInt(lifeRef.current.innerHTML) - 1;
        lifeRef.current.innerHTML = lifeValue;
        if (lifeValue === 1) {
            // make the life red
            lifeTextRef.current.style.color = '#FF5757';
            heartRef.current.src = redHeartWatchImgPath;
            heartRef.current.addEventListener('load', () => {
                // console.log("refd heart image loaded")
            })
            lifeTextRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            gradientOverlayRef.current.classList.add(classes.wrongAnswerAnimation);
        }
        return lifeValue;
    }

    const handleEndOfGamesDueToLifeLoss = async () => {
        totalScoreRef.current = Math.ceil(totalScoreRef.current)
        handleGbaState(GBA_SCREEN_STATE.LOOSE);
        setGameEndType('loose');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        // clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        const submitEndChallengeAttemptData = await submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: totalScoreRef.current, completion: false, status: "LIFELOSE" })
        setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);

    }

    const handleEndOfQuestions = () => {
        totalScoreRef.current = Math.ceil(totalScoreRef.current)
        handleGbaState(GBA_SCREEN_STATE.WIN);
        setGameEndType('win');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        // clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: totalScoreRef.current, completion: true, status: "COMPLETED", })
    }

    useEffect(() => {
        handleProgressBar();
        if (activeindex === 0) return
        isOptionClickAllowedRef.current = true; // enable option click
        // setIsOptionBoxExpanded(false);
        setIsOptionSetVisible(true);
        setAnimateQuestion(true) // show question
        timerRef.current.innerHTML = timePerQuestion;

        if (!isEmpty(divTimerRef.current)) divTimerRef.current.style.color = null;
        if (!isEmpty(stopWatchRef.current)) {
            stopWatchRef.current.src = stopWatchImgPath;
            stopWatchRef.current.addEventListener('load', () => {
                // console.log("stopwatch image loaded")
            })

        }
    }, [activeindex]);

    useEffect(() => {
        handleProgressBar();
    }, [progressBarRef.current]);

    const handleProgressBar = () => {
        let progressBarWidth = ((activeindex + 0) / questionData.length) * 100;
        if (!isEmpty(progressBarRef.current)) {
            progressBarRef.current.style.width = progressBarWidth + "%";
        }
    }

    const restartGame = async () => {
        clearInterval(intervalRef.current);
        // clearInterval(totalGBATimespentIntervalRef.current);
        setGameEndType();
        handleGbaState(GBA_SCREEN_STATE.GAME);
        setActiveindex(0);
        totalScoreRef.current = 0;
        // totalGBATimespent.current = 0;
        setIsGameComplete(false);
        logCounter = 0;
        getChallengeDataAsync();
        setLog([]);
        setIsOptionBoxExpanded(false);
        setIsOptionSetVisible(true);
        setSlideOptions(true);
        setAnimateQuestion(true);
        isOptionClickAllowedRef.current = true;
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        let tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;

        if (isEmpty(finalChallengeDataRef.current)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            };
        } else {
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                questions: [...finalChallengeDataRef.current.questions, tempQuestionObj],
                timespent: totalTimeSpent,
            };
        }
    };


    const handleAudioMutelick = () => {
        // crowdAudioRef.current.volume = 0;
        // crowdAudioRef.current.muted = true;
        setIsAudioPlaying(prev => {
            // console.log(crowdAudioRef.current.muted)
            if (prev && !isEmpty(crowdAudioRef.current)) crowdAudioRef.current.muted = true;
            else crowdAudioRef.current.muted = false;
            return !prev
        })

    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? {
            // backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault,
            fontSize: `${fSize}rem`
        } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={classes.cricketRoot} key={componentKey}>
            {
                !isGamePage ? (
                    !isHowToPlayScreen ? (
                        <>
                            <StartScreen
                                setIsGamePage={setIsGamePage}
                                setIsHowToPlayScreen={setIsHowToPlayScreen}
                                // gradientBg={bgGradientDefault}
                                bg={"/images/gba/master-blaster/master_blaster_bg.jpg"}
                                logoWidth={'80%'}
                                gameLogo={"/images/gba/master-blaster/master_blaster_logo.svg"}
                                handleGbaState={handleGbaState}

                                startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                startScreenGameBgLoaded={startScreenGameBgLoaded}
                                setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                            />
                        </>
                    ) : (
                        <HowToPlay
                            title={gameName}
                            headerBg={headerBg}
                            qstnBoxBg={'#000000'}
                            asset1Img={'/spaceRocks/rock.png'}
                            asset2Img={'/spaceRocks/rock.png'}
                            headerColor='#a34735'
                            setIsGamePage={setIsGamePage}
                            gameType='cricket'
                        />
                    )
                ) : (
                    !isGameComplete && isEmpty(gameEndType) ? (
                        <>
                            <div className={classes.incorrectGradientOverlay} ref={gradientOverlayRef} />
                            <div className={classes.gameContainer} ref={gameContainerRef} >
                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} style={{ width: 0 }} />
                                </div>

                                {/* QUESTION BOX */}
                                <div className={classes.qstnContainer}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeTextRef}>
                                                <div className={classes.life} ref={divLifeRef}><span ref={lifeRef}>{maxLife}</span> x </div>
                                                <img src={heartImgPath} className={`disableSelection disablePointerEvents ${classes.icon}`} ref={heartRef} alt="heart" />
                                            </div>
                                            <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeindex + 1}</span>/{questionData.length}</div>
                                            <div className={classes.timerText}>
                                                <img src={stopWatchImgPath} ref={stopWatchRef} className={`disableSelection disablePointerEvents ${classes.timerIcon}`} alt="timer" />
                                                <div ref={divTimerRef} className={classes.time}><b><span ref={timerRef}>{timePerQuestion}</span>s</b></div>
                                            </div>
                                        </div>

                                        <Zoom
                                            direction={'right'}
                                            in={animateQuestion}
                                            timeout={{ enter: 800, exit: 400 }}
                                        >
                                            <div className={classes.qstn} ref={questionRef}>
                                                {questionData[activeindex]?.question}
                                            </div>
                                        </Zoom>
                                    </div>
                                </div>
                                {/* QUESTION BOX */}

                                <div className={`${classes.gameplayContainer} ${isOptionBoxExpanded ? classes.gameplayContainerPseudo : classes.gameplayContainerPseudoHide}`}
                                    ref={gameplayContainerRef}>

                                    <div className={classes.gameContainerBox}>
                                        <div className={`${classes.lottieLoader} ${classes.centerFlex}`} ref={lottieLoaderRef} >
                                            <CircularProgress />
                                        </div>

                                        <div className={classes.correctIncorrectText} ref={correctIncorrectTextRef}>
                                            <div ref={correctTextLottieBoxRef} className={classes.correctIncorrectText}>
                                                <Lottie
                                                    loop={false}
                                                    animationData={CORRECT_LOTTIE}
                                                    play={false}
                                                    ref={correctTextLottieRef}
                                                />
                                            </div>
                                            <div ref={inCorrectTextLottieBoxRef} className={classes.correctIncorrectText}>
                                                <Lottie
                                                    loop={false}
                                                    animationData={WRONG_LOTTIE}
                                                    play={false}
                                                    ref={inCorrectTextLottieRef}
                                                />
                                            </div>
                                            <div ref={timeOutTextLottieBoxRef} className={classes.correctIncorrectText}>
                                                <Lottie
                                                    loop={false}
                                                    animationData={TIME_OUT}
                                                    play={false}
                                                    ref={timeOutTextLottieRef}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.assestsBox} ref={assestsBoxRef}>
                                            <Fade in={true}
                                                timeout={{ enter: assestLoadingDuration, exit: 90 }}>
                                                <div className={classes.assestsLayoutBox}>
                                                    <img src="/images/gba/master-blaster/master_blaster_stadium_new.png" id="stadiumImage" alt={'image'} className={`disableSelection disablePointerEvents`} />
                                                </div>
                                            </Fade>

                                            {/* Lotties */}
                                            <div className={classes.animationTypeLottieBox} >
                                                {
                                                    lottieRefList.map((item, index) => (
                                                        <Fade in={true} timeout={{ enter: assestLoadingDuration, exit: 80 }} key={`lottie-${index}`}>
                                                            <div key={item.id} className={classes.lottieBox} ref={item.boxRef}
                                                                style={{
                                                                    // opacity: index === lottieRefList.length - 1 ? '1' : '0',
                                                                    position: index === lottieRefList.length - 1 ? 'relative' : null,
                                                                    top: index === lottieRefList.length - 1 ? '5px' : null,
                                                                }}
                                                            >
                                                                <Lottie
                                                                    loop={index === lottieRefList.length - 1}
                                                                    animationData={item.lottieFile}
                                                                    play={index === lottieRefList.length - 1}
                                                                    ref={item.lottieFileRef}
                                                                    onComplete={() => (index === lottieRefList.length - 1) ? () => { } : onLottieComplete(index)}
                                                                    onLoad={() => {
                                                                        addLog('load')
                                                                    }}
                                                                />
                                                            </div>
                                                        </Fade>
                                                    ))
                                                }
                                            </div>
                                            {/* Lotties */}

                                            {
                                                isOptionSetVisible && (
                                                    <>
                                                        <Slide
                                                            direction="right"
                                                            in={!isOptionBoxExpanded}
                                                            mountOnEnter
                                                            unmountOnExit
                                                            timeout={{ enter: 900, exit: 90 }}
                                                        >
                                                            <div className={`disableSelection ${classes.tapToPlayBox}`} onClick={handleOptionExpansion} ref={tapToPlayRef}>
                                                                <div className={`${classes.tapToPlayIconBox} ${classes.centerFlex}`}>
                                                                    <ChevronRightIcon sx={{ color: '#FFF' }} />
                                                                </div>
                                                                <span className={`${classes.tapToPlayText} ${classes.blinkAnimation}`}>TAP TO PLAY!</span>
                                                            </div>
                                                        </Slide>
                                                        {
                                                            isOptionBoxExpanded && (
                                                                <div className={classes.optionBox} ref={optionBoxRef}
                                                                    style={{ width: isOptionBoxExpanded ? '100%' : '10%' }}
                                                                >
                                                                    {
                                                                        questionData[activeindex].answers.map((item, index) => (
                                                                            <Slide
                                                                                key={index}
                                                                                direction="right"
                                                                                in={slideOptions}
                                                                                // mountOnEnter
                                                                                // unmountOnExit
                                                                                timeout={{ enter: (index + 0.7) * 400, exit: 0 }}
                                                                            >
                                                                                <div className={classes.btnMainContainer} onClick={async (e) => {
                                                                                    handleOptionClick(item, e, `btn-${item.id}`)
                                                                                }
                                                                                }>
                                                                                    <Fade in={fadeInOptions}
                                                                                        timeout={{ enter: (index + 0.7) * 400, exit: optionFadeOutTimeRef.current }}>
                                                                                        <Button className={classes.optionBtn} id={`btn-${item.id}`}
                                                                                            style={{ position: 'relative' }}>
                                                                                            <img src="/images/gba/master-blaster/cricket_ball_3.svg" alt="ball" className={`disableSelection disablePointerEvents ${classes.ballImage}`} />
                                                                                        </Button>
                                                                                    </Fade>
                                                                                    <Fade in={fadeInOptionsText}
                                                                                        timeout={{ enter: (index + 0.7) * 400, exit: optionFadeOutTimeRef.current }}>
                                                                                        <div className={classes.optionText}>{item.answer}</div>
                                                                                    </Fade>
                                                                                </div>
                                                                            </Slide>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className={`disableSelection ${classes.muteSwitch}`} onClick={handleAudioMutelick}>
                                        {isAudioPlaying ? (
                                            <>
                                                <img src="/images/gba/learno_casino_sound.png" alt="icon" width={30} className={`disableSelection disablePointerEvents`} />
                                            </>
                                        ) : (
                                            <>
                                                <img src="/images/gba/learno_casino_mute.png" alt="icon" width={30} className={`disableSelection disablePointerEvents`} />
                                            </>
                                        )}
                                    </div>
                                    <audio
                                        ref={crowdAudioRef}
                                        src="/audio/master_blaster_crowd_mixdown_min.mp3"
                                        preload="metadata"
                                        onLoadedMetadata={handleAudioLoad}
                                        onEnded={(e) => {
                                            e.target.play();
                                        }}
                                    ></audio>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={mxmScore}
                                        bg={""}
                                        obtainedScore={totalScoreRef.current} />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        incorrectAttempt={incorrectData}
                                        bg={""}
                                        bgGradient={incorectBgGradient}
                                        restartGame={restartGame}
                                    />
                                )
                            }
                        </>
                    )
                )
            }
        </div>
    )
}

export default MasterBlaster;
