import { makeStyles } from "@mui/styles";
import constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentWrapper: {
        padding: '2rem 0.8rem',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'scroll',
        [theme.breakpoints.up("md")]: {
            overflowY: 'visible',
            display: 'grid',
            gridTemplateRows: '80% 20%',
            height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        }

    },

    contentWrapperFullScreen: {
        padding: '2rem 0.8rem',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'scroll',
        [theme.breakpoints.up("md")]: {
            overflowY: 'visible',
            display: 'grid',
            gridTemplateRows: '90% 10%',
            // height: `100vh`,
            height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        }
    },


    mediaWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.up("md")]: {
            height: "100%",
            width: "100%",
            // justifyContent: 'space-around',
        },
    },
    contentTextWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: '2rem',
        [theme.breakpoints.up("md")]: {
            paddingLeft: '1.5rem',
            marginTop: '0',
            alignItems: 'center',
            justifyContent: 'center',
            // width: '100%',
        }
    },
    title: {
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }
    },
    description: {
        fontSize: '1.2rem',
        fontWeight: '400',
        whiteSpace: 'pre-wrap',
    },
    mediaContentWrapper: {
        [theme.breakpoints.up("md")]: {
            // display: 'grid',
            height: `100%`,
            display: 'grid',
            gridTemplateRows: '20% 80%',
            gap: '1rem',
            width: '100%',
        }
    },
    mediaContent: {
        display: 'flex',
        height: '70%',
        [theme.breakpoints.up("md")]: {
            width: '100%',
            height: '100%',
        }
    },
    mediaBodyWrapper: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateRows: '100%',
    },
    bottomNavigationBar: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        // height: '30%',
    },
    bottomNavigationBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
    },
    navigationContent: {
        display: 'flex',
        gap: '0.2rem',
    },
    fullScrrenIcon: {
        backgroundColor: `${theme.palette.lightVioletToGrey} !important`,
    }
}));

export default useStyles;
