import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  viewChristmasGiftAnimation: false, // make it false to not show the christmat animation
  isDarkMode: false,
  isSplashScreenViewed: false,
  deviceDetails: {},
  privacyPolicyDetails: {
    lastDateToAcceptPolicy: null,
    policyAccepted: true,
    policyAcceptedOn: null,
    remainingDaysToAccept: 0,
    isDeclined: false,
  },
};

const commonPersistSlice = createSlice({
  name: 'commonPersist',
  initialState,
  reducers: {
    setChristmasGiftAnimation: (state, action) => {
      state.viewChristmasGiftAnimation = action.payload;
    },
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
    },
    setSplashScreenViewFlag: (state, action) => {
      state.isSplashScreenViewed = action.payload;
    },
    setDeviceDetails: (state, action) => {
      state.deviceDetails = action.payload
    },
    setPrivacyPolicyDetails: (state, action) => {
      state.privacyPolicyDetails = action.payload;
    },
  },
});

export const {
  setChristmasGiftAnimation,
  setIsDarkMode,
  setSplashScreenViewFlag,
  setDeviceDetails,
  setPrivacyPolicyDetails,
} = commonPersistSlice.actions;

export default commonPersistSlice.reducer;