import React, { useState, useRef, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "../../../../utils/isEmpty";
import ImageMagnifier from "../../../image-magnifier";

// styles
import useStyles from "./index.styles";

const ImageContent = (props) => {

    const { imageUrl } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });

    return (
        <div className={classes.imageContentWrapper} >

            <div className={`${classes.imageCardRoot} `}>
                <ImageMagnifier imageUrl={imageUrl} />
            </div>
        </div>
    );
};

export default ImageContent;
