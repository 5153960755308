import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    langListUi: {
        background: '#fff',
        [theme.breakpoints.down('md')]: {
            height: '100vh',
            width: '100vw',
        }
    },
    listBox: {
        width: '100%',
    },
    langListBox: {
        justifyContent: 'space-between !important',
        [theme.breakpoints.down('md')]: {
            minHeight: '3rem !important',
            padding: '0 1.5rem 0 1.5rem !important'
        }
    },
    menuText: {
        color: '#000 !important',
        fontSize: '1rem !important',
        // paddingLeft: '0.8rem',
        // fontFamily: 'Open sans !important'
    },
    selectAppLanguage: {
        paddingLeft: '0.5rem',
        marginBottom: '0rem',
        fontSize: '1.2rem',
        fontWeight: '600'
    }
}
))


export default useStyles;