import { makeStyles } from "@mui/styles";

import palette from "../../../../theme/palette";
import constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    imageContentWrapper: {
        [theme.breakpoints.up("md")]: {
            width: '100%',
            height: '100%',
        }

    },

    imageCardRoot: {
        maxWidth: 'max-content',
        height: '100%',
        padding: '1rem',
        margin: 'auto',
        borderRadius: '0.5rem',
        position: 'relative',

        [theme.breakpoints.down("sm")]: {
            padding: '0rem',
            borderRadius: '0rem',
        },

        [theme.breakpoints.up("md")]: {
            padding: '0',
            width: '100%',
        }
    },
}));

export default useStyles;
