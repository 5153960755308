import { useState } from "react";
import { useSelector } from "react-redux";

// styles 
import useStyles from "./index.styles";

// mui
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import HeaderBackButton from "../../layout-elements/header-back-button";
import { useNavigate, useParams } from "react-router";
import { postBulletinEndAttempt } from "../../../redux/microskill/microskill.api";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const BulletinHeader = (props) => {
    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });
    const navigate = useNavigate();

    const { bulletinId } = useParams();

    const { isMobile, isTablet } = useSelector(state => state?.common);

    const [selectedMonth, setSelectedMonth] = useState(""); // Declare state for selected month

    const displayDrawerStyleMobile = {
        sm: 'block',
        md: 'none'
    };

    const handleBackButtonClick = () => {
        // postBulletinEndAttempt({ bulletinId: bulletinId })
        navigate("/learn");
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event?.target?.value);
    };

    return (
        <>
            {(isMobile || isTablet) &&
                (<div className={classes.bulletinHeader}>

                    <div className={classes.headerContent}>

                        <Box sx={{ display: displayDrawerStyleMobile, justifyContent: 'flex-start' }} className={classes.headerBackButton} onClick={handleBackButtonClick}>
                            <HeaderBackButton />
                        </Box>

                        <div className={classes.headerText}>
                            Bulletin Board
                        </div>

                    </div>

                    {/* <div className={classes.monthSelector}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Month</InputLabel>
                <Select
                    style={{ border: '1px solid #f4511e' }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedMonth}
                    label="Month"
                    onChange={handleMonthChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {months.map((month, i) => (
                        <MenuItem key={i} value={month}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div> */}
                </div>)
            }
        </>



    );
};

export default BulletinHeader;
