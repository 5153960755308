import { List, MenuItem, Typography } from "@mui/material";
import useStyles from './index.styles';
import HeaderBackButton from "../header-back-button";
import { useTranslation } from "react-i18next";
import constants from "../../../constants";
import { useDispatch } from "react-redux";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import palette from "../../../theme/palette";
import { postUserLanguage } from "../../../redux/user-specific/userSpecific.api";
import { Height } from "@mui/icons-material";
import { useSelector } from "react-redux";


const LanguagesListUi = ({
    handleTranslateBackBtnClick,
    setSelectedLanguage,
    setLanguageChanging,
    selectedLanguage,
}) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { isMobile, isTablet } = useSelector((state) => state.common);

    return (
        <List sx={{ paddingTop: '0rem' }} className={classes.langListUi}>

            <MenuItem className={`${classes.listBox}`} style={{ paddingLeft: '0' }}>
                {
                    (isTablet || isMobile) &&
                    <div onClick={handleTranslateBackBtnClick}>
                        <HeaderBackButton />
                    </div>
                }

                <div className={classes.selectAppLanguage}>
                    {t("Select App Language")}
                </div>
            </MenuItem>

            {
                constants.LANGUAGES_LIST?.map((elem => (
                    <MenuItem
                        className={classes.langListBox}
                        key={`${elem.id}-${elem.key}`}
                        onClick={async () => {
                            dispatch(setSelectedLanguage(elem.name))
                            setLanguageChanging(false)
                            i18n.changeLanguage(elem.key);
                            await postUserLanguage({ "language": elem.name })
                        }}
                    >
                        <Typography textAlign="center" style={{ fontWeight: '500' }} className={classes.menuText}>{elem.name}</Typography>
                        {
                            selectedLanguage === elem.name &&
                            <CheckRoundedIcon sx={{ color: palette.orange, fontSize: 22 }} />

                        }
                    </MenuItem>
                )))
            }
        </List>
    )
}

export default LanguagesListUi;