import { useRef } from 'react';

let globalAbortController;

const useGlobalAbortController = () => {

    // const globalAbortController = useRef();
    // A function that sets a new AbortController and cancels the previous one
    const setNewAbortSignal = () => {
        if (globalAbortController) {
            globalAbortController.abort();  // Cancel the previous request
        }
        globalAbortController = new AbortController();  // Create a new controller
        return globalAbortController.signal;  // Return the new signal
    };

    return { setNewAbortSignal };
};

export default useGlobalAbortController;
