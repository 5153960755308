import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    bulletinHeader: {
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 1rem',
    },
    headerBackButton: {
        cursor: 'pointer',
    },
    headerText: {
        fontSize: '1.2rem',
        fontWeight: '700',
        color: theme.palette.fontColor,
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
    }
}));

export default useStyles;
