import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formId: null,
  isFormOpen: false,
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormIdData: (state, action) => {
      state.formId = action.payload;
    },
    setIsFormOpen: (state, action) => {
      state.isFormOpen = action.payload;
    },
    // Add more reducer functions here
  },
});

export const {
  setFormIdData,
  setIsFormOpen,
  // Add more action creators here
} = formSlice.actions;

export default formSlice.reducer;